import React, { useState, useEffect, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  FILTER,
  INFO,
  INFONULL,
  INFO2022,
  RESPONSEFORM,
  SEASONS_MOCK,
} from '../../helpers/Mockups';
import MapIcon from '@mui/icons-material/Map';
import {
  DataTableWidget,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget,
  SelectMultipleWidget2,
  TextFieldWidgetSelectController,
} from '../../components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // Avatar,
  Card,
  CardContent,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { BsRow, BsCol, BsContainer } from '../../layouts/components';
import { Context } from '../../context/Context';
import { AxiosContext } from '../../context/AxiosContext';
// import viewsTexts from '../../common/viewsTexts.json';
import { makeStyles } from '@mui/styles';
import { useErrorHandler } from 'react-error-boundary';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { SimpleTabs, TabPanel } from 'components/TabPanel';
// import theme from 'theme';
import { tabItems } from './tabList';
import {
  SixthTab,
  MobileFilters,
  FirstTab2025,
  SecondTab2025,
  ThirdTab2025,
  FourthTab2025,
  FifthTab2025,
  SixthTab2025,
} from 'containers/Library';
import { FiltersContext } from '../../context';
import _, { result } from 'lodash';
import clsx from 'clsx';
import { Map } from 'containers/Orchard';
import { useLocation } from 'react-router-dom';
import './styles.css';

const drawerWidth = '50vw';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  rootHelper: {
    fontSize: 17,
  },
  inputText: {
    fontSize: 26,
  },
  // drawer
  rootContent: {
    // display: 'flex',
  },
  rightMarginWide: {
    // [theme.breakpoints.up('lg')]: {
    // 	marginRight: drawerWidth,
    // },
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 56,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  openMapContainer: {
    width: 200,
    textAlign: 'center',
    marginBottom: 20,
  },
  menuButton: {
    boxShadow: theme.shadows[2],
  },
}));

const Library = (props) => {
  const { userId, userHuerto, userName, userOrchard, moduleUser } =
    useContext(Context);

  const { window } = props;

  const { state: IntelligenceState } = useLocation();

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const theme = useTheme();
  // console.log(theme, '<<<<---context');

  const {
    orchard_filter,
    cc_filter,
    quarter_filter,
    variety_filter,
    rootstock_filter,
    season_filter,
    userOrchard_filter,
    specie_filter,
  } = filterState;

  const classes = useStyles();

  const [valueTabState, setValueTabState] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTabState(newValue);
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const [infoFilterTab, setInfoFilterTab] = useState(null);
  const [tabsArrayState, setTabsArrayState] = useState([]);

  // carga mis orchards

  const moduleOrchardFilter = 'statusLibrary';

  // endpoints variety - rootstock
  useEffect(() => {}, []);

  // --------NUEVO

  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };

  // const clickMapHandler = () => {
  //   if (cc_filter) {
  //     const foundCcMap = ccDataState.find(
  //       (element) => element.value === cc_filter
  //     );

  //     setValue('cc', foundCcMap);
  //     if (quarter_filter) {
  //       const foundQuarterMap = quarterDataState.find(
  //         (element) => element.value === quarter_filter
  //       );

  //       setValue('quarter', foundQuarterMap);

  //       // variety
  //       // if (variety_filter) {
  //       //   console.log(variety_filter, '----variety_filter');
  //       //   // setValue('variety', dashAdd(variety_filter).trim());
  //       //   setValue('variety', ['Regina'], { shouldDirty: true });
  //       // } else {
  //       //   setValue('variety', '');
  //       // }
  //     } else {
  //       setValue('quarter', '');
  //     }
  //   } else {
  //     setValue('cc', '');
  //   }
  //   handleDrawerToggle();
  //   // ejecuta el submit tras cerrar el drawer
  //   setTimeout(() => {
  //     handleSubmit(onSubmit)();
  //   }, 100);
  // };

  // const drawer = (
  //   <div>
  //     <Map
  //       buttonClick={false}
  //       drawerAction={() => {}}
  //       quarterSelected={null}
  //       library={true}
  //       libraryFilter={clickMapHandler}
  //     />
  //   </div>
  // );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  const noInfoTabs = null;

  useEffect(() => {
    const moduleCounter = moduleUser.filter(
      (ele) => ele.id === '6422e4d13f15d6fddadfcb8a'
    );

    // console.log(moduleCounter, '---', moduleUser);

    let menuTabs;
    if (moduleCounter[0].processBackend) {
      menuTabs = tabItems.map((tab) => {
        const enabledTab =
          _.findIndex(moduleCounter[0].processBackend, function (p) {
            return p.id === tab.id;
          }) >= 0;

        return {
          ...tab,
          disabled: !enabledTab,
        };
      });
    } else {
      menuTabs = tabItems;
    }

    setTabsArrayState(menuTabs);
  }, [moduleUser]);

  return (
    <div className={classes.rootContent}>
      <>
        {/* <Hidden lgUp implementation="css"> */}
        {/* <Drawer
          container={container}
          variant='temporary'
          anchor='right'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          // ModalProps={{
          //   keepMounted: true, // Better open performance on mobile.
          // }}
        >
          {drawer}
        </Drawer> */}
        {/* </Hidden> */}
        {/* <Hidden mdDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						anchor="right"
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden> */}
      </>
      <div className={clsx('form-style', classes.rightMarginWide)}>
        <BsContainer fluid class='px-0 px-md-3'>
          {/* <BsRow class='no-gutters mb-md-3'>
            <BsCol class='col-12 col-md-12'>
              <Card
                elevation={0}
                variant='outlined'
                // classes={{
                // 	root: 'MainCardRoot',
                // }}
              >
                <CardContent>
                  <BsRow class=''>
                    <BsCol class='col-md-12'>
                      <Typography gutterBottom variant='h4' component='h2'>
                        Biblioteca
                      </Typography>
                    </BsCol>
                  </BsRow>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow> */}
          <BsRow class='no-gutters'>
            <BsCol class='col-md-12'>
              <Card elevation={0}>
                <CardContent>
                  <SimpleTabs
                    tabitems={tabsArrayState}
                    value={valueTabState}
                    toggler={handleChangeTab}
                    tabEnabled={true}
                  >
                    <TabPanel value={valueTabState} index={0}>
                      <FirstTab2025
                        filter={infoFilterTab}
                        fromPrune={IntelligenceState?.fromPrune}
                        scenarySaved={IntelligenceState?.IntelligenceState}
                      />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={1}>
                      <SecondTab2025 filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={2}>
                      <ThirdTab2025 filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={3}>
                      <FourthTab2025 filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={4}>
                      <FifthTab2025 filter={infoFilterTab} />
                    </TabPanel>
                    <TabPanel value={valueTabState} index={5}>
                      <SixthTab2025 filter={infoFilterTab} />
                    </TabPanel>
                  </SimpleTabs>
                </CardContent>
              </Card>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};
export default Library;
