// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  RadioGroup,
  Skeleton,
  Tooltip,
  Radio,
  useTheme,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

// AG GRID
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

import { BsRow, BsCol } from '../../../layouts/components';
import { FiltersContext, OptionsContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { setOrderForApi, setOrderForNewApi } from '../../../helpers';
import { useErrorHandler } from 'react-error-boundary';
import {
  colsAgBdDormancy,
  colsNoVisBbDormancy,
} from '../../../helpers/tableDefinitions';
import { LoadingAgTable } from 'components';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  MenuModule,
  MultiFilterModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  RowGroupingModule,
]);

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 27,
    // },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCol: {
    display: 'flex',
  },
  // backButton: {
  //   position: 'absolute',
  //   top: 10,
  //   zIndex: 100,
  // },
}));

const endPointName = 'v1/library/break-bud-dormancy';

const SecondTab2025 = ({
  filter: filterProp,
  fromPrune = false,
  scenarySaved = false,
}) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const history = useHistory();

  const { userOrchard, userClient } = useContext(Context);

  const {
    state: { specie_filter },
  } = useContext(FiltersContext);

  const {
    state: { library_table_break_bud_dormancy },
  } = useContext(OptionsContext);

  // console.log(scenarySaved, '<---scenarySaved');

  const axiosContext = useContext(AxiosContext);

  // modal
  const [openModalState, setOpenModalState] = useState(false);
  const [dataModalState, setDataModalState] = useState(null);

  // modal Print
  const [openModalPrintState, setOpenModalPrintState] = useState(false);
  const [dataModalPrintState, setDataModalPrintState] = useState(null);

  // modal Edit
  const [openModalEditState, setOpenModalEditState] = useState(false);
  const [dataModalEditState, setDataModalEditState] = useState(null);

  const customHeadStyles = {
    backgroundColor: theme.palette.primary.main,
    borderBottom: '4px solid',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  };

  // const colsYieldFilter =
  //   userClient?.id === '63ff5c7343f82fe93544ec64'
  //     ? colsYieldCalculator
  //     : colsYieldCalculator.filter((col) => col.accessorKey !== 'budDartReal');

  // const columns = useMemo(
  //   () => colsYieldCalculatorWinterAndFruit(specie_filter),
  //   [specie_filter]
  // );

  // const [visibleColumnsState, setVisibleColumnsState] = useState(
  //   colsNoVisYieldCalculatorWinter
  // );
  const [visibleColumnsState, setVisibleColumnsState] = useState([]);
  // const [activeColumnsTypeState, setActiveColumnsTypeState] = useState('poda');
  const [activeColumnsTypeState, setActiveColumnsTypeState] =
    useState('conteo');

  // AG GRID

  const [styleState, setStyleState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
    // marginBottom: 45,
  });

  const [customGroupColsState, setCustomGroupColsState] = useState('0');
  const [fullScreenState, setFullScreenState] = useState(false);
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const gridApiRef = useRef(null);
  const customGroupColsRef = useRef('0');

  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState(
    scenarySaved ? [{ id: 'season', desc: false }] : []
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100000,
  });

  const [isEditedState, setIsEditedState] = useState(false);

  const processDataColumns = (cols) => {
    const colsFixed = cols;

    // recorrer las columnas y agregar la propiedad pinned
    const colsWithPinned = colsFixed.map((col) => {
      if (
        typeof pinnedAgBdDormancy !== 'undefined' &&
        Array.isArray(pinnedAgBdDormancy) &&
        pinnedAgBdDormancy.includes(col.field)
      ) {
        return {
          ...col,
          pinned: 'left',
        };
      }
      if (
        typeof colorColsAgBdDormancy !== 'undefined' &&
        Array.isArray(colorColsAgBdDormancy) &&
        colorColsAgBdDormancy.includes(col.field)
      ) {
        return {
          ...col,
          headerClass: 'bg-column-header',
        };
      }
      return col;
    });

    return colsWithPinned;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      setIsLoading(true);

      // const {
      //   huerto,
      //   centro_costo,
      //   cuartel,
      //   variedad,
      //   porta_injerto,
      //   temporada,
      //   specieValue,
      // } = filterProp;

      let objQuery = {
        // orchardValue: huerto,
        // huerto_user: userOrchard, //orchard del login
        orchardValue: filterProp?.huerto || '',
        huerto_user: filterProp?.huerto ? null : userOrchard, //orchard del login
        ccValue: filterProp?.centro_costo || '',
        quarterValue: filterProp?.cuartel || '',
        varietyValue: filterProp?.variedad || '',
        rootStockValue: filterProp?.porta_injerto || '',
        season: filterProp?.temporada || '',
        specieValue: specie_filter,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        console.log(data, '----asi llega el Second tab');

        setData(data.data);
        setRowCount(data.count);
        setVisibleColumnsState(processDataColumns(colsAgBdDormancy));
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filterProp,
    isEditedState,
  ]);

  // modal
  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleCloseModalPrint = () => {
    setOpenModalPrintState(false);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEditState(false);
  };

  const handleOpenModal = (row) => {
    setDataModalState(row);
    setOpenModalState(true);
  };

  const handleOpenModalPrint = (row) => {
    setDataModalPrintState(row);
    setOpenModalPrintState(true);
  };

  const handleOpenModalEdit = (row) => {
    setDataModalEditState(row);
    setOpenModalEditState(true);
  };

  // console.log(new Date().getFullYear().toString(), '------actual year');
  const actualYear = new Date().getFullYear().toString();
  // TODO:
  // const actualYear = '2024';

  const handleBack = () => {
    history.push({
      pathname: '/intelligence',
    });
  };

  const handleIsEdited = useCallback(() => {
    setIsEditedState((prev) => !prev);
  }, []);

  // Funcionamiento tabla

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      document.body.style.overflow = 'hidden';
      setStyleState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  // const handleChangeCols = (event) => {
  //   if (gridApiRef.current) {
  //     let colsToHide = [];

  //     const allColumns = gridApiRef.current.getColumns();
  //     allColumns.forEach((column) => {
  //       column.setVisible(true);
  //     });

  //     console.log(allColumns, '<---allColumns');

  //     if (event.target.value === '0') {
  //       // console.log('entra en 0, todas visibles');
  //     }

  //     if (event.target.value === '1') {
  //       // console.log('entra en 1 - Caracter + resumen');
  //       let colsToHide1 = [];
  //       colsToHide1 = gridApiRef.current
  //         .getColumns()
  //         .filter((col) => colsNoVisYieldCalculatorWinter.includes(col.colId));

  //       console.log(colsToHide1, '------colsToHide1');

  //       gridApiRef.current.setColumnsVisible(colsToHide1, false);
  //     }

  //     if (event.target.value === '2') {
  //       // console.log('entra en 1 - Caracter + resumen');
  //       let colsToHide1 = [];
  //       colsToHide1 = gridApiRef.current
  //         .getColumns()
  //         .filter((col) => colsNoVisYieldCalculatorRaleo.includes(col.colId));

  //       console.log(colsToHide1, '------colsToHide1');

  //       gridApiRef.current.setColumnsVisible(colsToHide1, false);
  //     }

  //     setCustomGroupColsState(event.target.value);

  //     customGroupColsRef.current = event.target.value;
  //   }
  // };

  // genera el panel custom

  // const customPanel = () => {
  //   return (
  //     <div className='p-2'>
  //       <FormControl>
  //         <RadioGroup value={customGroupColsState} onChange={handleChangeCols}>
  //           <FormControlLabel
  //             control={<Radio />}
  //             name='caracter'
  //             value='0'
  //             label='Todas'
  //           />
  //           <FormControlLabel
  //             control={<Radio />}
  //             name='caracter'
  //             value='1'
  //             label='Poda'
  //           />
  //           <FormControlLabel
  //             control={<Radio />}
  //             name='caracter'
  //             value='2'
  //             label='Conteo Frutos'
  //           />
  //         </RadioGroup>
  //       </FormControl>
  //     </div>
  //   );
  // };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      // {
      //   id: 'custom',
      //   labelDefault: 'Grupo Columnas',
      //   labelKey: 'customStats',
      //   iconKey: 'columns',
      //   toolPanel: customPanel,
      //   toolPanelParams: {},
      // },
    ],
    position: 'right',
    defaultToolPanel: 'customPanel',
  };

  // const pinUnit = useCallback(() => {
  //   if (gridApiRef.current) {
  //     console.log('ENTRO');
  //     gridApiRef.current.applyColumnState({
  //       state: [{ colId: 'variety', pinned: 'left' }],
  //       defaultState: { pinned: null },
  //     });
  //   }
  // }, [gridApiRef]);

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        <div className={classes.tableContainerStyle}>
          <div className='p-2' style={{ backgroundColor: 'white' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <IconButton
                color='primary'
                aria-label='fullscreen-toggle'
                onClick={() => setFullScreen(!fullScreenState)}
                disabled={isLoading}
              >
                {fullScreenState ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
            </Box>
          </div>
          {!isLoading ? (
            <div className='ag-theme-quartz' style={styleState}>
              {fullScreenState && (
                <div className='p-1' style={{ backgroundColor: 'white' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      color='primary'
                      aria-label='fullscreen-toggle'
                      onClick={() => setFullScreen(!fullScreenState)}
                      disabled={isLoading}
                    >
                      <FullscreenExitIcon />
                    </IconButton>
                  </Box>
                </div>
              )}
              <AgGridReact
                rowData={data}
                columnDefs={visibleColumnsState}
                pagination
                paginationPageSize={50}
                suppressMovableColumns={true}
                suppressDragLeaveHidesColumns={true}
                paginationPageSizeSelector={paginationPageSizeSelector}
                groupDisplayType={'singleColumn'}
                rowGroupPanelShow={'always'}
                localeText={localeText}
                defaultColDef={{
                  flex: 1,
                  minWidth: 150,
                  filter: false,
                  floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                }}
                // quickFilterText={quickFilterText}
                onGridReady={onGridReady}
                sideBar={sideBar}
                embedFullWidthRows={true}
                // getRowId={(params) => params.data.id}
                grandTotalRow={'bottom'}
              />
            </div>
          ) : (
            <LoadingAgTable />
          )}
        </div>
      </BsCol>
    </BsRow>
  );
};

export default SecondTab2025;
