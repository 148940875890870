import { Avatar, Typography, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import iconJson from '../../common/iconSVG.json';
import { renderMaterialIcon } from 'layouts';
import { color } from 'highcharts';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    // width: '210px',
    height: '100%',
    // backgroundColor: theme.palette.mapbutton.main,
    // backgroundColor: 'tomato',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    cursor: 'pointer',
    color: theme.palette.grey[600],
    '&.active': {
      borderBottom: '3px solid' + theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& h6': {
        color: theme.palette.primary.main,
        fontWeight: '600 !important',
      },
    },

    '&.notAssigned': {
      cursor: 'not-allowed',
      color: theme.palette.grey[200],
      '& h6': {
        color: theme.palette.grey[200],
        fontWeight: '600 !important',
      },
    },
  },
  nameArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // flexGrow: 1,
    height: '100%',
    // minWidth: '200px',
  },
  name: {
    fontWeight: '500 !important',
    textAlign: 'center',
    fontSize: 15,
    color: theme.palette.grey[600],
  },
  avatar: {
    width: theme.spacing(7) + '!important',
    height: theme.spacing(7) + '!important',
    marginRight: 10,
  },
  buttonBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 995,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    '&&.active': {
      opacity: 1,
    },
  },
}));

const MenuCarouselButton = (props) => {
  const {
    handleClick,
    selectedId,
    idOrchard,
    name,
    icon,
    iconType,
    selected,
    activeButton,
  } = props;
  const classes = useStyles();

  // console.log(selectedId, '<---selectedId--', idOrchard);

  return (
    <div
      className={clsx(
        classes.buttonWrapper,
        selected ? 'active' : '',
        activeButton ? '' : 'notAssigned'
      )}
    >
      {iconType === 'material' ? (
        renderMaterialIcon(icon)
      ) : (
        <SvgIcon width='33' height='32' viewBox='0 0 33 32'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            // stroke="#66788E"
            // stroke-width="2"
            d={iconJson.icon[icon]}
          />
        </SvgIcon>
      )}
      <Typography variant='subtitle1' className={classes.name}>
        {name}
      </Typography>
    </div>
  );
};

export default MenuCarouselButton;
