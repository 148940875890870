import React from 'react';
import { Avatar, Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import {
  orchard,
  difference,
  fruitSet,
} from '../../assets/icons/industry/svg_icons';
import { numberFormatDecimals } from 'helpers';

const IndustryIndicator = ({ data }) => {
  const theme = useTheme();

  let bgColor = 'white';
  let avatarColor = 'white';

  // console.log(data, '<-----------------data EN BOX---------------');
  let IconIndicator = createSvgIcon(orchard, 'icon');

  switch (data.key) {
    case 'fruitSetRealTotal':
      bgColor = '#FFF9EB';
      avatarColor = '#FFC24A';
      IconIndicator = createSvgIcon(fruitSet, 'icon');
      break;

    case 'fruitSetEstimateTotal':
      bgColor = '#F1FCFA';
      avatarColor = '#4ACFC0';
      IconIndicator = createSvgIcon(fruitSet, 'icon');
      break;

    case 'difference':
      bgColor = '#F4F5F5';
      avatarColor = '#A6AEB4';
      IconIndicator = createSvgIcon(difference, 'icon');
      break;

    default:
      bgColor = '#F4F5F5';
      avatarColor = '#A6AEB4';
      IconIndicator = createSvgIcon(orchard, 'icon');
      break;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: bgColor,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'left',
        borderRadius: `${theme.shape.borderRadius}px`,
        padding: 3,
      }}
    >
      <Avatar sx={{ bgcolor: avatarColor, mb: 1 }}>
        <IconIndicator sx={{ width: 24, height: 24 }} />
      </Avatar>
      <Typography
        variant='h3'
        component='h3'
        gutterBottom
        sx={{
          fontWeight: 600,
          color: theme.palette.grey[500],
          fontSize: {
            xs: '20px', // smaller font size for extra small screens
            lg: '24px', // medium screens
          },
        }}
      >
        {numberFormatDecimals(data.data)}
      </Typography>
      <Typography variant='h5' component='h4' gutterBottom>
        {data.title}
      </Typography>
    </Box>
  );
};

export default IndustryIndicator;
