import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';

const JoyrideCard = (props) => {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
    size,
  } = props;

  console.log(props, '<<<<<---props------------');

  return (
    <>
      <Card sx={{ maxWidth: 345 }} {...tooltipProps} className='tooltip__body'>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom variant='h5' component='div'>
              {step.title && <h4 className='tooltip__title'>{step.title}</h4>}{' '}
            </Typography>
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              {...closeProps}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          </Box>
          <Typography variant='body2' sx={{ mb: 2 }} color='text.secondary'>
            {step.content}
          </Typography>
          <Typography variant='h5'>
            Paso {index + 1} de {size}
          </Typography>
        </CardContent>
        <CardActions
          className='tooltip__spacer'
          sx={{ justifyContent: 'space-between' }}
        >
          <Button size='small' className='tooltip__button' {...skipProps}>
            {skipProps.title}
          </Button>
          <div>
            {index > 0 && (
              <Button size='small' variant='outlined' {...backProps}>
                {backProps.title}
              </Button>
            )}
            {continuous && (
              <Button
                size='small'
                variant='contained'
                sx={{ ml: 2 }}
                {...primaryProps}
              >
                {primaryProps.title}
              </Button>
            )}
          </div>
        </CardActions>
      </Card>
    </>
  );
};

export default JoyrideCard;
