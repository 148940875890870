import React, { memo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

/**
 * El componente LineChart renderiza un gráfico de líneas personalizable usando Highcharts.
 *
 * @component
 * @param {Object} props - El objeto de propiedades.
 * @param {string} [props.title=''] - El título del gráfico.
 * @param {string} [props.subtitle=''] - El subtítulo del gráfico.
 * @param {Array} [props.data=[]] - Los datos a mostrar en el gráfico.
 * @param {Array} [props.colors=[]] - Los colores de las líneas en el gráfico.
 * @param {string} [props.type='line'] - El tipo de gráfico, por defecto es 'line'.
 * @param {string} [props.tooltipFormat='{series.name}: {point.y}'] - El formato del tooltip.
 * @param {boolean} [props.exportOptions=true] - Si se muestran las opciones de exportación.
 * @param {boolean} [props.enableDataLabels=true] - Si se muestran las etiquetas de datos.
 * @param {boolean} [props.showLegend=true] - Si se muestra la leyenda.
 * @param {string} [props.height=''] - La altura del gráfico.
 * @param {Array} [props.spacing=[0, 0, 0, 0]] - El espaciado entre los bordes del gráfico y el contenido.
 * @param {boolean} [props.shadow=false] - Si se muestra sombra en las líneas.
 * @param {string} [props.yAxisTitle=''] - El título del eje Y.
 * @param {string} [props.xAxisCategories=''] - Las categorías del eje X.
 * @param {number} [props.gridLineWidth=0] - El ancho de las líneas de la cuadrícula.
 * @param {string} [props.valueSuffix=''] - El sufijo para los valores.
 * @param {string} [props.dashStyle='Solid'] - El estilo de línea discontinua.
 *
 * @returns {JSX.Element} El componente LineChart renderizado.
 */
const LineChart = memo(
  ({
    title = '', // Título del gráfico
    subtitle = '', // Subtítulo del gráfico
    data = [], // Datos a mostrar
    colors = [], // Colores de las líneas
    type = 'line', // Tipo de gráfico, por defecto 'line'
    tooltipFormat = '{series.name}: {point.y}', // Formato del tooltip
    exportOptions = true, // Muestra las opciones de exportación
    enableDataLabels = true, // Muestra los valores de cada punto
    showLegend = true, // Muestra la leyenda
    height = '', // Altura del gráfico
    spacing = [0, 0, 0, 0], // Espaciado entre los bordes del gráfico y el contenido
    shadow = false, // Sombra de las líneas
    yAxisTitle = '', // Título del eje Y
    xAxisCategories = '', // Categorías del eje X
    gridLineWidth = 0, // Ancho de las líneas de la grilla
    valueSuffix = '', // Sufijo de los valores
    dashStyle = 'Solid', // Tipo de línea
    plotlines = [],
    chartRef = null, // Referencia al gráfico
  }) => {
    const options = {
      ...generalOptionsHighCharts,
      exporting: {
        enabled: exportOptions,
        filename: title ? title : 'downloaded-chart',
        buttons: {
          contextButton: {
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            symbolFill: '#A8BF77',
            symbolStroke: '#666',
            symbolStrokeWidth: 1,
            // theme: {
            //   fill: '#f7f7f7',
            //   stroke: '#666',
            //   states: {
            //     hover: {
            //       fill: '#e6e6e6',
            //       stroke: '#666',
            //     },
            //     select: {
            //       fill: '#e6e6e6',
            //       stroke: '#666',
            //     },
            //   },
            // },
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadJPEG',
              'downloadSVG',
              'downloadPDF',
              'separator',
              ...(exportOptions ? ['downloadCSV'] : []),
            ],
          },
          // filename: 'DATOS',
        },
        menuItemDefinitions: {
          viewFullscreen: {
            text: 'Ver en pantalla completa',
          },
          printChart: {
            text: 'Imprimir',
          },
          downloadPDF: {
            text: 'Descargar PDF',
          },
          downloadCSV: {
            text: 'Descargar CSV',
          },
          downloadJPEG: {
            text: 'Descargar JPEG',
          },
          downloadSVG: {
            text: 'Descargar SVG',
          },
        },
        csv: {
          dateFormat: '%Y-%m-%d',
          itemDelimiter: ';',
          lineDelimiter: '\n',
          decimalPoint: ',',
        },
      },
      chart: {
        type: type,
        height: height,
        spacingBottom: spacing[2],
        spacingLeft: spacing[3],
        spacingRight: spacing[1],
        spacingTop: spacing[0],
      },
      title: {
        text: title,
        useHTML: true,
      },
      subtitle: {
        text: subtitle,
      },
      legend: {
        enabled: showLegend, // Controla si se muestran o no las leyendas
        reversed: false, // Invierte el orden de las leyendas
      },
      xAxis: {
        categories: data.length > 0 ? xAxisCategories : xAxisCategories,
        crosshair: true, // Muestra una línea vertical en el punto seleccionado
        dataLabels: {
          enabled: enableDataLabels,
        },
        // accessibility: {
        //     rangeDescription: rangeDescription,
        // },
      },
      yAxis: {
        allowDecimals: false, // No permite decimales en el eje Y
        min: 0,
        title: {
          text: yAxisTitle,
        },
        gridLineWidth: gridLineWidth,
        plotLines: plotlines,
      },
      series: data,

      plotOptions: {
        line: {
          shadow: shadow,
          // enableMouseTracking: false, // Deshabilita el seguimiento del mouse
          dataLabels: {
            enabled: enableDataLabels,
          },
        },
        series: {
          dataLabels: {
            enabled: enableDataLabels,
            // allowOverlap: true,
          },
          label: {
            // connectorAllowed: true // No permite conectar los puntos
          },
          // pointStart: '2021',
          dashStyle: dashStyle, // Tipo de línea
        },
        spline: {
          marker: {
            // radius: 8,
            symbol: 'square',
          },
        },
      },
      colors: colors.length > 0 ? colors : undefined,
      tooltip: {
        pointFormat: tooltipFormat,
        valueSuffix: valueSuffix,
        shared: false, // Muestra todos los valores en el tooltip
        stickOnContact: true, //
      },
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    );
  }
);

export default LineChart;
