import React, { useState, useRef, useContext, useEffect } from 'react';
import { subBusinessDays } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Icon,
  IconButton,
  Skeleton,
  SvgIcon,
  Table,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  HighchartsWrapper,
  SelectMultipleWidgetFilter,
  TextFieldWidgetDateController2,
  TextFieldWidgetDateRangeController2,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { MobileFilters } from '../../../containers/Library';
import clsx from 'clsx';
import { ColumnChart } from 'components/Charts';
import CardOrchardGeneralBalance from 'containers/Irrigation/CardOrchardGeneralBalance';
import {
  alterArrayDashDelete,
  alterArrayGetValues,
  dashDelete,
  dateFormatFromCalendar,
  numberFormatDecimals,
} from 'helpers';
import { pad } from 'lodash';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  tableGreyHeader: {
    '& thead': {
      backgroundColor: theme.palette.grey[30],
      '& tr th:not(:last-child)': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '20%', // Empieza al 20% desde arriba
          height: '60%', // Ocupa 60% de la altura
          width: 1,
          backgroundColor: theme.palette.grey[250],
        },
      },
    },
    '& tbody tr td': {
      fontSize: 14,
      padding: '.65rem',
    },
  },
  tableIndicators: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
    },
    '& td': {
      color: theme.palette.text.primary,
    },
  },
}));

const indicatorsValues = [
  {
    label: 'Volumen Aplicado',
    value: 'appliedVolume',
  },
  {
    label: 'Volumen Programado',
    value: 'programmedVolume',
  },
  {
    label: 'Suma de Pérdida',
    value: 'lossVolume',
  },
  {
    label: 'Porcentaje de Fuga',
    value: 'lossPercentage',
  },
];

const colors = [
  '#75AEE5',
  '#345EB5',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',
  '#BBDEFB',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
];

const endpointName = 'v1/irrigation/orchard';

const ByOrchard = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const axiosContext = useContext(AxiosContext);

  const { userOrchard, userClient } = useContext(Context);

  const chartRef = useRef(null);

  const chartMapRef = useRef(null);

  const errorHandler = useErrorHandler();

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [loadingState, setLoadingState] = useState(false);

  const [irrigationDataState, setIrrigationDataState] = useState(null);

  const theme = useTheme();

  const [minDateState, setminDateState] = useState(null);

  const actualYear = new Date().getFullYear();

  // definir start date evaluando si el día de hoy es mayor a la fecha mínima
  const startIrrigationDate = subBusinessDays(new Date(), 1);
  // definir end date evaluando si el día de hoy es mayor a la fecha máxima
  const endIrrigationDate = new Date();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusWeather';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      const bodyObj = {
        // clientValue: userClient?.value,
        orchard: dataQuery?.orchards?.length
          ? dataQuery?.orchards
          : userOrchard,
        dateBegin: dateFormatFromCalendar(dataQuery.dateBegin),
        dateEnd: dateFormatFromCalendar(dataQuery.dateEnd),
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(endpointName, bodyObj);

      // let data = true;

      const { data, status } = response;

      if (status === 200) {
        console.log(data, 'data--------------endpoint');

        setIrrigationDataState({
          indicatorsData: {
            appliedVolume: data?.appliedVolume,
            programmedVolume: data?.programmedVolume,
            lossVolume: data?.lossVolume,
            lossPercentage: data?.lossPercentage,
          },
          chart: data?.chartOrchard,
          lossChart: data?.chartLoss,
          orchardTable: data?.table,
        });
      }
    } catch (err) {
      setIrrigationDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = new Date(values.since);
    const dateEnd = new Date(values.until);

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      orchards: values.orchard ? alterArrayGetValues(values.orchard) : null,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
    };

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const dateSetterHandler = (type) => {
    const date = new Date();
    let dateSince = new Date();
    let dateUntil = new Date();

    if (type === 'week') {
      dateSince = new Date(date.setDate(date.getDate() - 7));
    } else if (type === 'month') {
      dateSince = new Date(date.setMonth(date.getMonth() - 1));
    }

    setValue('since', dateSince);
    setValue('until', dateUntil);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='d-flex flex-md-row  align-items-center'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=''>
            <SelectMultipleWidgetFilter
              errors={errors.orchard}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Huerto'
              minheight={false}
              // req
              name='orchard'
              display='vertical'
              variant='outlined'
              isMulti={true}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={filterDataResponseState}
              placeholder='Huerto'
            />
          </div>
          <div className='ml-md-2'>
            <TextFieldWidgetDateController2
              name='since'
              labeltext='Desde'
              placeholder='Desde'
              display='vertical'
              variant='outlined'
              format='dd-MM-yyyy'
              style={{}}
              minheight={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '150px',
              }}
              // value={sinceSelectedState}
              // onChange={date => setSinceSelectedState(date)}
              control={control}
              errors={errors.since}
              defaultValue={startIrrigationDate}
            />
          </div>
          <div className='ml-md-2'>
            <TextFieldWidgetDateController2
              name='until'
              labeltext='Hasta'
              placeholder='Hasta'
              display='vertical'
              variant='outlined'
              format='dd-MM-yyyy'
              style={{}}
              minheight={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '150px',
              }}
              // value={sinceSelectedState}
              // onChange={date => setSinceSelectedState(date)}
              minDate={minDateState}
              control={control}
              errors={errors.until}
              defaultValue={endIrrigationDate}
            />
          </div>
          <div className='ml-md-2'>
            <LoadingButton
              variant='contained'
              type='submit'
              color='primary'
              aria-label='filter'
              size='medium'
              loading={false}
            >
              <ArrowForwardIosIcon />
            </LoadingButton>
          </div>
        </form>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='text'
            size='small'
            sx={{ mr: 1 }}
            onClick={() => dateSetterHandler('month')}
          >
            Último mes
          </Button>
          <Button
            variant='text'
            size='small'
            onClick={() => dateSetterHandler('week')}
          >
            Última semana
          </Button>
        </Box>
        {errors.incorrectDates && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.incorrectDates.message}
          </p>
        )}
      </MobileFilters>
    );
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  // console.log(selectedValueDetailState, '----selectedValueDetailState');

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <CardPaper
              elevation={0}
              sx={{
                borderTop: 0,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              <CardContent className='p-0'>
                <BsRow class=' align-items-center'>
                  <BsCol class='col-md-6'>
                    <Typography variant='h4' component='h2'>
                      Riego por Huerto
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-6 d-flex justify-content-end'>
                    <Box
                      sx={{
                        // height: '20px',
                        // width: '40px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderFilters()}
                    </Box>
                  </BsCol>
                </BsRow>
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        {loadingState && (
          <div className='form-style'>
            <BsContainer fluid class='px-3 px-md-3'>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-8'>
                  <Skeleton variant='rounded' height={320} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Skeleton variant='rounded' height={320} />
                </BsCol>
              </BsRow>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-12'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
              </BsRow>
            </BsContainer>
          </div>
        )}

        {!loadingState && irrigationDataState && (
          <>
            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-8'>
                <CardOrchardGeneralBalance
                  indicators={indicatorsValues}
                  indicatorsData={irrigationDataState?.indicatorsData}
                  title='Balance General de Riego por Huerto'
                  description='Medido en m3'
                />
                <CardPaper elevation={0} variant='outlined' sx={{ mt: 2 }}>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Riego Aplicado y Volumen de Fuga
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Riego aplicado y el volumen de fuga por huerto
                    </Typography>
                    <HighchartsWrapper
                      fullScreenHandler={openFullscreen}
                      title=''
                    >
                      <ColumnChart
                        data={irrigationDataState.chart?.data}
                        // xAxisCategories={
                        //   (yieldChartData && yieldChartData?.categories) || []
                        // }
                        showLegend={true}
                        xAxisCategories={
                          alterArrayDashDelete(
                            irrigationDataState.chart?.categories
                          ) || []
                        }
                        tooltipFormat='{series.name}: {point.y:,.0f} m3'
                        chartRef={chartRef}
                        colors={colors}
                        height={270}
                        gridLineWidth={0}
                        spacing={[10, 0, 0, 0]}
                        yAxisTitle='Volumen m3' // Titulo del eje Y
                        type='column' // column o bar
                        legendLayout='vertical'
                        legendAlign='right'
                        legendVerticalAlign='middle'
                        enableDataLabels={false}
                      />
                    </HighchartsWrapper>
                  </CardContent>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-4'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Porcentaje de Fuga por Huerto
                    </Typography>
                    <HighchartsWrapper
                      fullScreenHandler={openFullscreen}
                      title=''
                    >
                      <ColumnChart
                        data={irrigationDataState.lossChart?.data}
                        // xAxisCategories={
                        //   (yieldChartData && yieldChartData?.categories) || []
                        // }
                        tooltipFormat='{series.name}: {point.y:,.1f} %'
                        maxY={100}
                        height={550}
                        showLegend={true}
                        xAxisCategories={
                          alterArrayDashDelete(
                            irrigationDataState.lossChart?.categories
                          ) || []
                        }
                        chartRef={chartRef}
                        colors={colors}
                        gridLineWidth={0}
                        spacing={[10, 0, 0, 0]}
                        yAxisTitle='' // Titulo del eje Y
                        type='bar' // column o bar
                        valueSuffix='%' // Sufijo de los valores
                      />
                    </HighchartsWrapper>
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Detalle en tabla
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Detalle del riego aplicado y volumen de fuga en formato
                      tabla
                    </Typography>
                    <div className='table-responsive'>
                      <table
                        className={clsx(
                          'table table-borderless',
                          classes.tableGreyHeader
                        )}
                      >
                        <thead>
                          <tr>
                            <th>Huerto</th>
                            <th>Riego Aplicado</th>
                            <th>Riego Programado</th>
                            <th>Volumen de Fuga</th>
                            <th>% de Fuga</th>
                          </tr>
                        </thead>
                        <tbody>
                          {irrigationDataState.orchardTable ? (
                            irrigationDataState.orchardTable.map(
                              (row, index) => (
                                <tr key={index}>
                                  <td>{dashDelete(row.orchardValue)}</td>
                                  <td>
                                    {numberFormatDecimals(row.appliedVolume, 0)}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(
                                      row.programmedVolume,
                                      0
                                    )}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(row.lossVolume, 0)}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(
                                      row.lossPercentage,
                                      1
                                    )}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={5}>No hay datos</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>
          </>
        )}
      </BsContainer>
    </div>
  );
};
export default ByOrchard;
