import { Grid, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { HighchartsWrapper } from 'components';
import { LineChart } from 'components/Charts';

const processDataChart = (data, type) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return { categories: [], data: { name: '', data: [] } };
  }

  // Extraer los años únicos y ordenarlos de mayor a menor
  const categories = Array.from(
    new Set(data.flatMap((item) => item.data.map((entry) => entry.year)))
  ).sort((a, b) => b - a);

  // Filtrar el objeto correspondiente al tipo especificado
  const selectedItem = data.find((item) => item.value === type);

  // Formatear el objeto 'data' con los valores correspondientes a los años
  const dataObject = {
    name: selectedItem ? selectedItem.label : '',
    data: selectedItem
      ? categories.map(
          (year) =>
            selectedItem.data.find((entry) => entry.year === year)?.value || 0
        )
      : [],
  };

  return { categories, data: dataObject };
};

const calculateAverage = (inputData) => {
  if (
    !inputData ||
    !inputData.data ||
    !Array.isArray(inputData.data) ||
    inputData.data.length === 0
  ) {
    return 0;
  }

  // Calcular la media
  const average =
    inputData.data.reduce((sum, value) => sum + value, 0) /
    inputData.data.length;
  return average.toFixed(1);
};

const WeatherCharts = ({ chartData, typeSelected = 'coldPortion', colors }) => {
  const theme = useTheme();

  const chartRef = useRef(null);

  const [dataChartState, setDataChartState] = useState(null);

  const openFullscreen = () => {
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  useEffect(() => {
    setDataChartState(processDataChart(chartData, `${typeSelected}`));
  }, [chartData, typeSelected]);

  console.log(
    dataChartState,
    '<-----------------dataChartState---------------'
  );

  return (
    <HighchartsWrapper fullScreenHandler={openFullscreen} title=''>
      <LineChart
        title={dataChartState?.data.name || ''}
        subtitle='A la fecha'
        data={dataChartState?.data || []}
        colors={colors}
        type='line'
        exportOptions={true}
        allowPointSelect={false}
        enableDataLabels={false}
        borderRadius={2}
        borderWidth={0}
        showLegend={false}
        spacing={[10, 10, 0, 5]}
        shadow={false}
        xAxisCategories={dataChartState?.categories || []} // Categorias del eje X para graficos simples
        yAxisTitle='' // Titulo del eje Y
        gridLineWidth={1} // Ancho de la linea de la cuadricula
        valueSuffix='' // Sufijo de los valores
        dashStyle='Solid' // Tipo de linea 'ShortDashDot' , 'ShortDot', 'Dash', 'Solid', 'ShortDash'
        plotlines={[
          {
            value: calculateAverage(dataChartState?.data), // Valor de la media
            color: 'red', // Color de la línea
            dashStyle: 'Dash', // Estilo de línea
            width: 2, // Grosor de la línea
            label: {
              text: `Media (${calculateAverage(dataChartState?.data)})`, // Etiqueta con el valor de la media
              align: 'right',
              style: {
                color: 'red',
              },
            },
          },
        ]} // Lineas de referencia
        chartRef={chartRef}
      />
    </HighchartsWrapper>
  );
};

export default WeatherCharts;
