import React, { useEffect, useMemo, useState, useContext } from 'react';
import {
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { useErrorHandler } from 'react-error-boundary';
import clsx from 'clsx';
import { alphabeticalSort, dashDelete, numberFormatDecimals } from 'helpers';

const agroclimaticEndpoint = 'v1/weather/weather-agroclimatic-dashboard';

const DetailOrchardsTable = ({
  orchards: orchardsProp,
  classTable,
  type = 'coldHour',
}) => {
  const theme = useTheme();

  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const errorHandler = useErrorHandler();

  const [loadingState, setLoadingState] = useState(false);
  const [tableDataState, setTableDataState] = useState(null);

  const getMonthName = (monthNumber) => {
    const monthNames = {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    };
    return monthNames[monthNumber] || `Mes ${monthNumber}`;
  };

  const tableConfig = useMemo(() => {
    // Valor por defecto si tableDataState no es válido
    if (
      !tableDataState ||
      !Array.isArray(tableDataState[type]) ||
      tableDataState[type].length === 0
    ) {
      return { years: [], monthOrder: [] };
    }

    // Extraer años únicos, ordenados
    const years = [
      ...new Set(
        tableDataState[type].flatMap((orchard) =>
          orchard.data.map((yearData) => yearData.year)
        )
      ),
    ].sort((a, b) => b - a);

    const allMonths = new Set();
    tableDataState[type].forEach((orchard) => {
      orchard.data.forEach((yearData) => {
        yearData.months.forEach((month) => {
          allMonths.add(month.month);
        });
      });
    });

    const monthOrder = [...allMonths].sort((a, b) => parseInt(a) - parseInt(b));

    return { years, monthOrder };
  }, [tableDataState, type]);

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      const objQuery = {
        orchard: dataQuery && dataQuery?.length ? dataQuery : userOrchard,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        agroclimaticEndpoint,
        objQuery
      );

      // // // let data = true;

      const { data, status } = response;

      // console.log(objQuery, '-----objQuery');

      // setCountState(data.totalPassengers);
      if (status === 200) {
        console.log('pasa----------', data);

        setTableDataState(data);
      }
    } catch (err) {
      setTableDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    queryData(orchardsProp);
  }, [orchardsProp]);

  return (
    <div className='table-responsive'>
      <table className={clsx('table table-borderless', classTable)}>
        <thead>
          <tr>
            <th rowSpan='2'>Huerto</th>
            {tableConfig.years.map((year) => (
              <th key={year} colSpan={tableConfig.monthOrder.length + 1}>
                {year === new Date().getFullYear().toString()
                  ? 'Temporada Actual'
                  : `Año ${year}`}
              </th>
            ))}
          </tr>
          <tr>
            {tableConfig.years.map((year) => (
              <React.Fragment key={year}>
                {tableConfig.monthOrder.map((month) => (
                  <th key={`${year}-${month}`}>{getMonthName(month)}</th>
                ))}
                <th>Total</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableDataState &&
            tableDataState[type]?.length > 0 &&
            alphabeticalSort(tableDataState[type], 'orchardValue').map(
              (orchard) => {
                // Crear un objeto para mapear fácilmente los datos
                const yearData = {};
                orchard.data.forEach((yearEntry) => {
                  // Añadir total explícitamente
                  yearData[yearEntry.year] = {
                    total: yearEntry.total,
                    months: {},
                  };

                  // Mapear los meses
                  yearEntry.months.forEach((monthEntry) => {
                    yearData[yearEntry.year].months[monthEntry.month] =
                      monthEntry.value;
                  });
                });

                return (
                  <tr key={orchard.orchardValue}>
                    <td>{dashDelete(orchard.orchardValue)}</td>

                    {tableConfig.years.map((year) => (
                      <React.Fragment key={year}>
                        {tableConfig.monthOrder.map((month) => (
                          <td key={`${year}-${month}`} className=''>
                            {yearData[year]?.months?.[month] || 0}
                          </td>
                        ))}
                        <td
                          className='font-weight-bold'
                          style={{ color: theme.palette.primary.main }}
                        >
                          {numberFormatDecimals(yearData[year]?.total, 1)}
                        </td>
                      </React.Fragment>
                    ))}
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </div>
  );
};

export default DetailOrchardsTable;
