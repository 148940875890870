// import _ from "lodash";
import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useErrorHandler } from 'react-error-boundary';
import { BsRow, BsCol } from '../../../layouts/components';
import { ReactDataTable, ButtonAction } from '../../../components';
import { FiltersContext, OptionsContext } from '../../../context';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  dateFormatFromCalendar,
  dateFormatIso,
  // alterArrayGetIds,
  // numberFormat,
  // dashDelete,
  // numberFormatDecimals,
  numberFormatDecimalsCounting,
  setOrderForApi,
  setOrderForNewApi,
} from '../../../helpers';

import {
  colsNoVisCounterPlant,
  colsCounterPlant,
  colsCounterPlant_Ciruelo,
  colsNoVisPlantPrePoda,
  colsNoVisPlantPrePoda_Ciruelo,
  colsNoVisPlantPostPoda,
  colsNoVisPlantPostPoda_Ciruelo,
  colsNoVisPlantPreRaleo,
  colsNoVisPlantPostRaleo,
  initialSorting,
  colsCounterQuarterV2Fixed,
} from '../../../helpers/tableDefinitions';
import clsx from 'clsx';
import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
    },
  },
  dialogTitleStyle: {
    fontWeight: '500',
    fontSize: 18,
  },
  dialogTextStyle: {
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
}));

const endPointName = 'v1/counter/plant';

const endpointDelete = 'v1/counter/plant/delete';

const COLS_COUNTER_QUARTER = [
  {
    specie: 'Ciruelo',
    columns: colsCounterPlant_Ciruelo,
    noVisiblePrePoda: colsNoVisPlantPrePoda_Ciruelo,
    noVisiblePostPoda: colsNoVisPlantPostPoda_Ciruelo,
  },
  {
    specie: 'Cerezo',
    columns: colsCounterPlant,
    noVisiblePrePoda: colsNoVisPlantPrePoda,
    noVisiblePostPoda: colsNoVisPlantPostPoda,
  },
  {
    specie: 'Nectarines',
    columns: colsCounterPlant,
    noVisiblePrePoda: colsNoVisPlantPrePoda,
    noVisiblePostPoda: colsNoVisPlantPostPoda,
  },
];

const colsCounterPlantsFixed = ['cc', 'quarter', 'variety'];

const getColumnBySpecie = (specieFilter) => {
  const columnObject = COLS_COUNTER_QUARTER.find(
    (col) => col.specie === specieFilter
  );
  return columnObject;
};

const Plant = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const errorHandler = useErrorHandler();

  const { userOrchard, moduleUser } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  const {
    state: { specie_filter },
  } = useContext(FiltersContext);

  const columns = useMemo(
    () => getColumnBySpecie(specie_filter).columns,
    [specie_filter]
  );

  const customHeadStyles = {
    paddingTop: 1,
  };

  const initSort = [
    {
      id: 'quarter',
      desc: true,
    },
  ];

  let userModulesArray = moduleUser;
  // console.log(moduleUser, '----userModules');
  const resultCounterModule =
    userModulesArray && userModulesArray.length
      ? userModulesArray.filter(
          (ele) => ele.id === '64234ac2f7b53d43704e067b' && ele.assigned
        )
      : null;
  // console.log(resultCounterModule[0], '----resultCounterModule');
  const removeProcess =
    resultCounterModule && resultCounterModule.length
      ? resultCounterModule[0].processBackend.filter(
          (ele) => ele.id === '66c3937765558ace6006e1e4'
        )
      : [];

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);

  // console.log(removeProcess, '-------removeProcess');

  const activeDelete = removeProcess.length
    ? (row) => row.original.counter !== 'Conteo_Frutal_Dardo_Ramilla'
    : false;

  // table
  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(initSort);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [refetchState, setRefetchState] = useState(false);
  const [mustResetSelectedRows, setMustResetSelectedRows] = useState(false);

  const [openPromptState, setOpenPromptState] = useState(false);
  const [rowSelectedState, setRowSelectedState] = useState({});
  const [plantToDeleteState, setPlantToDeleteState] = useState([]);
  const [loadingDeleteState, setLoadingDeleteState] = useState(false);
  const [deleteSuccessState, setDeleteSuccessState] = useState(false);

  const fetchData = async () => {
    setIsError(null);

    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const {
      orchard,
      cc,
      quarter,
      variety,
      dateBegin,
      dateEnd,
      specieValue,
      counterType,
      // porta_injerto,ww
      // temporada,
    } = filterProp;
    console.log(filterProp, '----asi llega el filter');

    const varieties = variety.length
      ? variety.map((variety) => variety.value)
      : null;

    const counterTypes = counterType.length
      ? counterType.map((counterType) => counterType.value)
      : null;

    const objQuery = {
      orchardValue: orchard?.value,
      huerto_user: orchard?.value ? null : userOrchard, //orchard del login
      ccValue: cc?.value,
      quarterValue: quarter?.value,
      varietyValue: varieties,
      specieValue: specieValue,
      // counterType: counterType?.value,
      counterType: counterTypes,
      dateBegin: dateFormatFromCalendar(dateBegin),
      dateUntil: dateFormatFromCalendar(dateEnd),
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      // sort: setOrderForApi(sorting),
      sortColumn: setOrderForNewApi(sorting)?.key,
      sortOrder: setOrderForNewApi(sorting)?.option,
    };

    console.log(objQuery, '<-----asi está yendo', sorting);

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        objQuery
      );

      const { data, status } = response;

      console.log(data, '----asi llega el first tab');

      setData(data.data);
      setRowCount(data.count);
    } catch (error) {
      console.error(error);
      setIsError(error);
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setRefetchState(false);
    }
  };

  useEffect(() => {
    if (filterProp) {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  useEffect(() => {
    if (refetchState) {
      fetchData();
    }
  }, [refetchState]);

  useEffect(() => {
    if (filterProp && filterProp.counterType && filterProp.counterType.length) {
      const colsToHide = [];
      const selectedTypes = filterProp.counterType.map((type) => type.value);
      if (!selectedTypes.includes('Pre_Poda')) {
        colsToHide.push(...colsNoVisPlantPrePoda);
      }
      if (!selectedTypes.includes('Post_Poda')) {
        colsToHide.push(...colsNoVisPlantPostPoda);
      }
      if (!selectedTypes.includes('Pre_Raleo')) {
        colsToHide.push(...colsNoVisPlantPreRaleo);
      }
      if (!selectedTypes.includes('Post_Raleo')) {
        colsToHide.push(...colsNoVisPlantPostRaleo);
      }
      setVisibleColumnsState(colsToHide);
    } else {
      setVisibleColumnsState([]);
    }
  }, [filterProp]);

  const resetSelectedRows = () => {
    setMustResetSelectedRows(true);
  };

  const handleDeleteCounts = async () => {
    setLoadingDeleteState(true);

    let objDelete = {
      id: plantToDeleteState,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endpointDelete,
        objDelete
      );

      // console.log(response, '------response');
      const { status, data } = response;
      if (status === 200) {
        resetSelectedRows();
        setDeleteSuccessState(true);
        setRefetchState(true);
      }
    } catch (error) {
      // console.log(error, '---error post');
      errorHandler(error);
    } finally {
      setLoadingDeleteState(false);

      // setOpenPromptState(false);
    }
  };
  // const onDeletePlant = async (plant) => {
  //   setDeleteSuccessState(false);
  //   setOpenPromptState(true);
  //   setPlantToDeleteState(plant);
  //   // console.log(plant);
  // };
  const handleDeleteRows = async (rows) => {
    const convertedRows = [];

    rows.forEach((row) => {
      const { original } = row;
      convertedRows.push(original._id);
    });

    console.log('delete IDS', convertedRows);
    setOpenPromptState(true);

    setPlantToDeleteState(convertedRows);
  };

  const handleClosePrompt = () => {
    setOpenPromptState(false);
  };

  const onCloseSnack = () => {
    console.log('------close');

    setMustResetSelectedRows(false);

    setPlantToDeleteState(null);

    setDeleteSuccessState(false);
  };

  // si está isFetching no cambia de página
  const handleChangePagination = (params) => {
    if (!isRefetching) {
      setPagination(params);
    }
  };

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        {/* <p>tab3</p> */}
        <div
          className={clsx(
            classes.tableContainerStyle,
            'counting-plant-2 counting-plant-3'
          )}
        >
          <ReactDataTable
            columns={columns}
            data={data}
            hideColumns={visibleColumnsState}
            //fixedColumns={colsFixedYieldCalculator}
            fixedColumns={colsCounterPlantsFixed}
            // positionActionsColumn={'first'}
            // enableRowSelection={true}
            enableRowSelection={activeDelete}
            resetRowSelection={mustResetSelectedRows}
            // onRowSelectionChange={handleRowSelection}
            // rowSelection={rowSelectedState}
            handleDeleteRows={handleDeleteRows}
            isError={isError}
            isLoading={isLoading}
            isRefetching={isRefetching}
            module='Conteos'
            onSortingChange={setSorting}
            sorting={sorting}
            onPaginationChange={(params) => handleChangePagination(params)}
            pagination={pagination}
            rowCount={rowCount}
            onColumnFiltersChange={setColumnFilters}
            columnFilters={columnFilters}
            onGlobalFilterChange={setGlobalFilter}
            globalFilter={globalFilter}
            enableRowActions={false}
            // renderRowActions={actionsColumn}
            headStyles={customHeadStyles}
            enableStickyHeader={true}
            positionPagination='top'
          />

          {plantToDeleteState ? (
            <Dialog
              open={openPromptState}
              onClose={handleClosePrompt}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle
                id='alert-dialog-title'
                className={clsx('delete-prompt', classes.dialogTitleStyle)}
              >
                Eliminar Conteos
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id='alert-dialog-description'
                  className={classes.dialogTextStyle}
                >
                  Seguro desea eliminar el/los {plantToDeleteState.length}{' '}
                  Conteo/s Seleccionado/s
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={handleClosePrompt}
                  disabled={loadingDeleteState || deleteSuccessState}
                >
                  Cancelar
                </Button>
                {/* <Button
              color='error'
              onClick={handleDeletePlant}
              autoFocus
              disabled={loadingDeleteState}
            > 
              Eliminar
            </Button>  */}
                <ButtonAction
                  labelButton='Eliminar'
                  color='error'
                  // size='large'
                  actionClose={onCloseSnack}
                  variant='text'
                  fullWidth={true}
                  actionOnClick={handleDeleteCounts}
                  disabled={loadingDeleteState || deleteSuccessState}
                  messageType='edit'
                  mutationLoading={loadingDeleteState}
                  success={deleteSuccessState}
                />
              </DialogActions>
            </Dialog>
          ) : null}
        </div>
      </BsCol>
    </BsRow>
  );
};

export default Plant;
