import { id } from 'date-fns/locale';

export const modulesPlatform = [
  //------------------------------------ MENU INICIO
  {
    id: '000000000000000000000000',
    name: 'Home',
    namePrint: 'Inicio',
    assigned: true,
    href: 'home',
    subMenu: false,
    menuOrder: 1,
    // iconType: 'material',
    icon: 'homeModule',
    process: [
      {
        id: '000000000000000000000001',
        name: 'Inicio',
        assigned: true,
        href: 'home',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  // {
  //   id: '64e62ced5c228de333333333',
  //   name: 'Inicio',
  //   assigned: true,
  //   href: 'desktop-dashboard',
  //   subMenu: false,
  //   menuOrder: 1,
  //   iconType: 'material',
  //   icon: 'HomeOutlinedIcon',
  //   process: [
  //     {
  //       id: '64e62ced5c228de333333334',
  //       name: 'Inicio',
  //       assigned: true,
  //       href: 'desktop-dashboard',
  //       __typename: 'UserProcessAssigned',
  //     },
  //   ],
  //   __typename: 'UserModuleAssigned',
  // },
  //------------------------------------ MAPA
  {
    id: '6423302fa603384a5039a60f',
    name: 'Orchards',
    assigned: true,
    href: 'orchard',
    subMenu: false,
    menuOrder: 5,
    namePrint: 'Mapa',
    // iconType: 'material',
    icon: 'mapModule',
    process: [
      {
        id: '6423306412000fe8c0f50c96',
        name: 'Huertos',
        assigned: true,
        href: 'orchard',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ BIBLIOTECA
  {
    id: '6422e4d13f15d6fddadfcb8a',
    name: 'Library',
    namePrint: 'Biblioteca',
    assigned: true,
    href: 'library',
    subMenu: false,
    menuOrder: 1,
    icon: 'libraryModule',
    process: [
      {
        id: '6422e8ed3b513fe22b595a8b',
        name: 'Biblioteca',
        assigned: true,
        href: 'library',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ RECO PODA
  {
    id: '642333cb241198d7ea424c03',
    name: 'Pruning',
    namePrint: 'Poda',
    assigned: true,
    href: 'intelligence',
    subMenu: false,
    menuOrder: 12,
    // icon: 'intelligence',
    // iconType: 'material',
    icon: 'intelligence',
    // iconType: 'material',
    icon: 'prunningModule',
    process: [
      {
        id: '642333d7c8eb24d43acb793d',
        name: 'Ingresar',
        assigned: true,
        href: 'intelligence',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  // {
  //   id: '6423353846d708f7e48aacc7',
  //   name: 'Proyección Cosecha',
  //   assigned: true,
  //   href: 'projection',
  //   subMenu: false,
  //   menuOrder: 4,
  //   iconType: 'material',
  //   icon: 'EventIcon',
  //   process: [
  //     {
  //       id: '66733ff2a662e4cab76c27b3',
  //       name: 'Ingresar',
  //       assigned: true,
  //       href: 'projection',
  //       __typename: 'UserProcessAssigned',
  //     },
  //   ],
  //   __typename: 'UserModuleAssigned',
  // },
  // {
  //   id: '642337ecd39504aaa4d311b5',
  //   name: 'Dashboard',
  //   assigned: true,
  //   href: '/dashboard',
  //   subMenu: false,
  //   menuOrder: 5,
  //   // iconType: 'material',
  //   icon: 'dashboard',
  //   process: [
  //     {
  //       id: 55,
  //       name: 'Dashboard',
  //       assigned: true,
  //       href: '/dashboard',
  //       __typename: 'UserProcessAssigned',
  //     },
  //   ],
  //   __typename: 'UserModuleAssigned',
  // },
  //------------------------------------ CONTEO
  {
    id: '64234ac2f7b53d43704e067b',
    name: 'Counter',
    namePrint: 'Conteo',
    assigned: true,
    href: 'counting',
    subMenu: true,
    menuOrder: 3,
    // iconType: 'material',
    icon: 'countingModule',
    process: [
      {
        id: '660c51db1598609eedf816e4',
        name: 'Resumen huerto',
        assigned: true,
        href: 'counting-dashboard',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '64234c9a7cd3413ad1c73c6e',
        name: 'Promedio Cuarteles',
        assigned: true,
        href: 'counting-quarters',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '64234e0abce53eefcb15fe72',
        name: 'Conteos Por Planta',
        assigned: true,
        href: 'counting-plants',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '650af9fc905e5a1129142034',
        name: 'Mapa Calor',
        assigned: true,
        href: 'counting-heatmap',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '669acad5de0f38208d6c07f5',
        name: 'Mapa Calor Huerto',
        assigned: true,
        href: 'counting-orchard-heatmap',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '64234f561db0ec3470f4e44e',
        name: 'Muestreo',
        assigned: true,
        href: 'sampling',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      // {
      //   id: '66c3937765558ace6006e1e4',
      //   name: 'Eliminar Conteo',
      //   assigned: true,
      //   href: '#',
      //   iconType: '-',
      //   icon: '-',
      //   __typename: 'UserProcessAssigned',
      // },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ CLIMA
  {
    id: '6422f05b89487f570c713901',
    name: 'Weather',
    namePrint: 'Clima',
    assigned: true,
    href: 'weather',
    subMenu: true,
    menuOrder: 8,
    // iconType: 'material',
    icon: 'weatherModule',
    process: [
      {
        id: '6422f73bdb22839ba692e0b6',
        name: 'Resumen',
        assigned: true,
        href: 'weather-resume',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '64232c4af14becbc9d2b1060',
        name: 'Detalle',
        assigned: true,
        href: 'weather-detail',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67cf36aa6d6f66ab68043c23',
        name: 'Estaciones',
        assigned: true,
        href: 'weather-stations',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ RECO MUESTRAL
  // {
  //   id: '64234ed70ba2eaeaeafc3496',
  //   name: 'Sampling',
  //   namePrint: 'Muestreo',
  //   assigned: true,
  //   href: 'sampling',
  //   subMenu: false,
  //   menuOrder: 11,
  //   // iconType: 'material',
  //   icon: 'samplingModule',
  //   process: [
  //     {
  //       id: '64234f561db0ec3470f4e44e',
  //       name: 'Muestreo',
  //       assigned: true,
  //       href: 'sampling',
  //       __typename: 'UserProcessAssigned',
  //     },
  //   ],
  //   __typename: 'UserModuleAssigned',
  // },
  //------------------------------------ ANÁLISIS
  {
    id: '6478c8a3b722d8c83ac83b65',
    name: 'LaboratoryAnalysis',
    namePrint: 'Analisis',
    assigned: true,
    href: 'dashboard',
    subMenu: true,
    menuOrder: 15,
    // iconType: 'material',
    icon: 'analysisModule',
    process: [
      {
        id: '660c49f41598609eedf816df',
        name: 'Yema',
        assigned: true,
        href: 'analysis-bud',
        iconType: 'material',
        icon: 'PageviewIcon',
        __typename: 'AnalyisisProcessAssigned',
      },
      {
        id: '660c4a361598609eedf816e0',
        name: 'Rompedores',
        assigned: true,
        href: 'analysis-dormancy',
        iconType: 'material',
        icon: 'AcUnitIcon',
        __typename: 'AnalyisisProcessAssigned',
      },
    ],
    __typename: 'AnalyisisModuleAssigned',
  },
  //------------------------------------ ESTIMACIÓN
  {
    id: '64e62ced5c228dec08aaf0e9',
    name: 'DashboardManagement',
    namePrint: 'Estimación',
    assigned: true,
    href: 'dashboard-management',
    subMenu: true,
    menuOrder: 2,
    // iconType: 'material',
    icon: 'estimateModule',
    process: [
      {
        id: '64e6469a0dc28882f389ee91',
        name: 'Gráficos',
        assigned: true,
        href: 'dashboard-management',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '66d870bd0d68a1e3899b132b',
        name: 'Tablas',
        assigned: true,
        href: 'table-management',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ RENDIMIENTO
  {
    id: '64ef4f50e252668d3c000048',
    name: 'DashboardPerformance',
    namePrint: 'Rendimiento',
    assigned: true,
    href: 'yield-dashboard',
    subMenu: false,
    menuOrder: 4,
    // iconType: 'material',
    icon: 'yieldModule',
    process: [
      {
        id: '64ef5040e252668d3c00004b',
        name: 'Rendimiento',
        assigned: true,
        href: 'yield-dashboard',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  // {
  //   id: '642337ecd39504aaa4d31000',
  //   name: 'Gráficos',
  //   assigned: true,
  //   href: 'dashboard',
  //   subMenu: true,
  //   menuOrder: 8,
  //   // iconType: 'material',
  //   icon: 'dashboard',
  //   process: [
  //     {
  //       id: '642337ecd39504aaa4d311b5',
  //       name: 'Res. Huerto',
  //       assigned: true,
  //       href: 'dashboard',
  //       // iconType: 'material',
  //       icon: 'dashboardGeneral',
  //       __typename: 'DashboardProcessAssigned',
  //     },
  //     {
  //       id: '64e62ced5c228dec08aaf0e9',
  //       name: 'Gerencia',
  //       assigned: true,
  //       href: 'dashboard-management',
  //       // iconType: 'material',
  //       icon: 'dashboardManagement',
  //       __typename: 'DashboardProcessAssigned',
  //     },
  //   ],
  //   __typename: 'DashboardModuleAssigned',
  // },
  //------------------------------------ PLANIF COSECHA
  {
    id: '651eab668dd2652ad27fc094',
    name: 'HarvestPlanning',
    namePrint: 'Pla. Cosecha',
    assigned: true,
    href: 'schedule-dashboard',
    subMenu: true,
    menuOrder: 10,
    // iconType: 'material',
    icon: 'scheduleModule',
    process: [
      // {
      //   id: '651ead0e8dd2652ad27fc099',
      //   name: 'Gráficos',
      //   assigned: true,
      //   href: 'schedule-dashboard',
      //   iconType: 'material',
      //   icon: 'DashboardIcon',
      //   __typename: 'UserProcessAssigned',
      // },
      {
        id: '651eacc98dd2652ad27fc098',
        name: 'Bi Semanal',
        assigned: true,
        href: 'schedule-form',
        iconType: 'material',
        icon: 'ChecklistRtlIcon',
        __typename: 'UserProcessAssigned',
      },
      // {
      //   id: '651eac548dd2652ad27fc097',
      //   name: 'Listado',
      //   assigned: true,
      //   href: 'schedule-list',
      //   iconType: 'material',
      //   icon: 'ListIcon',
      //   __typename: 'UserProcessAssigned',
      // },
      {
        id: '66cf719fb8426ad52cfcba4f',
        name: 'Histórico',
        assigned: true,
        href: 'schedule-history',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '66cf71cbb8426ad52cfcba50',
        name: 'Histórico por dia',
        assigned: true,
        href: 'schedule-historyday',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '66fbdb67fb4c9e01ef415b44',
        name: 'Chequeo Diario',
        assigned: true,
        href: 'check-day',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '671f9dc044c9bd6cb1003c9a',
        name: 'Chequeo Bi Semanal',
        assigned: true,
        href: 'check-biweekly',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67a35643f1815570945b493e',
        name: 'Chequeo Penta Semanal',
        assigned: true,
        href: 'check-pentaweekly',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '6740cffda0b54807a29312b6',
        name: 'Formulario Chequeo Diario',
        assigned: true,
        href: 'check-day-form',
        iconType: 'material',
        icon: 'ListIcon',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ USABILIDAD
  {
    id: '64f87e1ada19593a715a6292',
    name: 'Usability',
    namePrint: 'Usabilidad',
    assigned: true,
    href: 'monthly-report',
    subMenu: true,
    menuOrder: 10,
    // iconType: 'material',
    icon: 'usabilityModule',
    process: [
      {
        id: '642335e60e526ac1ffd07e1c',
        name: 'Reporte Usabilidad',
        assigned: true,
        href: 'monthly-report',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '66a001146f836de991c19d0f',
        name: 'Seguimiento Conteo',
        assigned: true,
        href: 'counting-tracking',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  // {
  //   id: '653181b3265e2086ad5688a4',
  //   name: 'Dashboard Ext. Garces',
  //   assigned: true,
  //   href: 'dashboard-external',
  //   subMenu: false,
  //   menuOrder: 13,
  //   iconType: 'material',
  //   icon: 'AssessmentIcon',
  //   process: [
  //     {
  //       id: 111,
  //       name: 'Dashboard Ext',
  //       assigned: true,
  //       href: 'dashboard-external',
  //       __typename: 'UserProcessAssigned',
  //     },
  //   ],
  //   __typename: 'UserModuleAssigned',
  // },
  //------------------------------------ EXTERNOS
  {
    id: '653181b3265e2086ad5688a4',
    name: 'ExternoGarces',
    assigned: true,
    href: 'external',
    subMenu: true,
    menuOrder: 10,
    iconType: 'material',
    icon: 'RecentActorsIcon',
    process: [
      {
        id: '65318214265e2086ad5688a7',
        name: 'Dashboard Externos',
        assigned: true,
        href: 'external-dashboard',
        iconType: 'material',
        icon: 'AssessmentIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '6543f2636c3cc449efe591bf',
        name: 'Formulario Externos',
        assigned: true,
        href: 'external-form',
        iconType: 'material',
        icon: 'ChecklistRtlIcon',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  // harvest
  // {
  //   id: '6581eae55fee8e0236a4dcdc',
  //   name: 'Trazabilidad Cosecha',
  //   assigned: true,
  //   href: 'harvest',
  //   subMenu: true,
  //   menuOrder: 11,
  //   iconType: 'material',
  //   icon: 'dashboard',
  //   process: [
  //     {
  //       id: '6581f3b9ecf3c213c881d1be',
  //       name: 'Listar Labores',
  //       assigned: true,
  //       href: 'harvest-labor-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f41a6b442c72a41370aa',
  //       name: 'Crear Labor',
  //       assigned: true,
  //       href: 'harvest-labor-create',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f427d7a119d724014051',
  //       name: 'Listar Contratistas',
  //       assigned: true,
  //       href: 'contractor-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f43297719dbd9ad1003e',
  //       name: 'Crear Contratista',
  //       assigned: true,
  //       href: 'contractor-create',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f43cf06808f20db2a219',
  //       name: 'Listar Supervisor',
  //       assigned: true,
  //       href: 'supervisor-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f449d74c876ade577334',
  //       name: 'Crear Supervisor',
  //       assigned: true,
  //       href: 'supervisor-create',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f44e3edfc476a522e24a',
  //       name: 'Listar Cosecheros',
  //       assigned: true,
  //       href: 'worker-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f4529f91f9df4fcddf60',
  //       name: 'Crear Cosechero',
  //       assigned: true,
  //       href: 'worker-create',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f458c549fbf6c553fa3e',
  //       name: 'Dashboard Huerto',
  //       assigned: true,
  //       href: 'harvest-dashboard-orchard',
  //       iconType: 'material',
  //       icon: 'SummarizeIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f45f61af07d9311bd0c9',
  //       name: 'Dashboard Labor',
  //       assigned: true,
  //       href: 'harvest-dashboard-labor',
  //       iconType: 'material',
  //       icon: 'SummarizeIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f462a199a34155978d94',
  //       name: 'Dashboard Gerencial',
  //       assigned: true,
  //       href: 'harvest-dashboard-management',
  //       iconType: 'material',
  //       icon: 'SummarizeIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f7b174bb483c2fefb506',
  //       name: 'Entregas p/Contratista',
  //       assigned: true,
  //       href: 'harvest-delivery-contractor',
  //       iconType: 'material',
  //       icon: 'SummarizeIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f46b49dfacab17a89358',
  //       name: 'Registrar Pago',
  //       assigned: true,
  //       href: 'harvest-payment-add',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f6102f4389eafb4dcf71',
  //       name: 'Listado Pagos',
  //       assigned: true,
  //       href: 'harvest-payment-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '6581f620878736f5479fffeb',
  //       name: 'Generar QR',
  //       assigned: true,
  //       href: 'harvest-qr-generate',
  //       iconType: 'material',
  //       icon: 'AddIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //     {
  //       id: '642337ecd39504aaa4d10014',
  //       name: 'Listado QR',
  //       assigned: true,
  //       href: 'harvest-qr-list',
  //       iconType: 'material',
  //       icon: 'ListIcon',
  //       __typename: 'HarvestProcessAssigned',
  //     },
  //   ],
  //   __typename: 'HarvestModuleAssigned',
  // },
  //------------------------------------ COMP INDUSTRIA
  {
    id: '668407849f5946eca6727733',
    name: 'Industry',
    namePrint: 'Comp. Industria',
    assigned: true,
    href: 'industry',
    subMenu: true,
    menuOrder: 3,
    // iconType: 'material',
    icon: 'industryModule',
    process: [
      {
        id: '6684083a9f5946eca6727734',
        name: 'Estimaciones',
        assigned: true,
        href: 'industry-estimates',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '668408b99f5946eca6727735',
        name: 'Conteos',
        assigned: true,
        href: 'industry-counting',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '668409069f5946eca6727736',
        name: 'Clima',
        assigned: true,
        href: 'industry-weather',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '668597b1178e7683db8c6dc5',
        name: 'Estimación Flores',
        assigned: true,
        href: 'industry-flowers',
        iconType: 'material',
        icon: 'CenterFocusWeakIcon',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ POWERBI GERENCIA
  {
    id: '66d0ca968244d5872f356a03',
    name: 'Dashboard Gerencia',
    namePrint: 'Dashboard Gerencia',
    assigned: true,
    href: 'dashboard-direction',
    subMenu: false,
    menuOrder: 15,
    // iconType: 'material',
    icon: 'estimateModule',
    process: [
      {
        id: '66d0cadf8244d5872f356a06',
        name: 'Dashboard Gerencia',
        assigned: true,
        href: 'dashboard-direction',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ RIEGO
  {
    id: '67475b98ae573778c932b250',
    name: 'Irrigation',
    namePrint: 'Riego',
    assigned: true,
    href: 'irrigation',
    subMenu: true,
    menuOrder: 8,
    // iconType: 'material',
    icon: 'irrigationModule',
    process: [
      {
        id: '67475bc4ae573778c932b252',
        name: 'Riego',
        assigned: true,
        href: 'irrigation',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '6761dccd4db843947836254a',
        name: 'Sector/Centro de Costo',
        assigned: true,
        href: 'irrigation-cost',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67cb04cbfc5fd702961b6e46',
        name: 'Balance por Huerto',
        assigned: true,
        href: 'irrigation-orchard',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67cb052afc5fd702961b6e47',
        name: 'Balance por Equipos',
        assigned: true,
        href: 'irrigation-equipment',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67cb0574fc5fd702961b6e48',
        name: 'Error Funcionamiento',
        assigned: true,
        href: 'irrigation-error',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '67cb05c6fc5fd702961b6e49',
        name: 'Funcionamiento Sector',
        assigned: true,
        href: 'irrigation-sector',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
      {
        id: '679b8e13d93a53b7b8f65f58',
        name: 'Balance Hídrico',
        assigned: true,
        href: 'irrigation-station',
        iconType: 'material',
        icon: 'InsertChartIcon',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
  //------------------------------------ NUTRICIÓN
  {
    id: '679a3e5ad93a53b7b8f65f31',
    name: 'Nutrition',
    namePrint: 'Nutrición',
    assigned: true,
    href: 'nutrition',
    subMenu: false,
    menuOrder: 9,
    // iconType: 'material',
    icon: 'nutritionModule',
    process: [
      {
        id: '679a3e86d93a53b7b8f65f34',
        name: 'Nutrición',
        assigned: true,
        href: 'nutrition',
        __typename: 'UserProcessAssigned',
      },
    ],
    __typename: 'UserModuleAssigned',
  },
];

export const arrayIdSchedule = [
  '651eab668dd2652ad27fc022',
  '651eacc98dd2652ad27fc022',
  '651eac548dd2652ad27fc022',
];

export const arrayIdPowerBi = [
  '668407849f5946eca6727733',
  '6684083a9f5946eca6727734',
  '668408b99f5946eca6727735',
  '668409069f5946eca6727736',
  '668597b1178e7683db8c6dc5',
];

export const arrayIdDashboards = [
  '642337ecd39504aaa4d311b5',
  '64e62ced5c228dec08aaf0e9',
  '64ef4f50e252668d3c000048',
  '64f87e1ada19593a715a6292',
];

export const arrayIdsResume = [
  '6422f16d96ec727bd72c0df8',
  '6422f73bdb22839ba692e0b6',
  // '6422f4dfb9ae9c79768caba6',
  // '6422f5d90f82631c4852d97a',
];

export const arrayIdsDetail = [
  '64232c37e948ea51537502b4',
  '64232c4af14becbc9d2b1060',
  // '64232d73c3299642ae133df5',
  // '64232dafe83ace26a69d1bbb',
  // '64232dc4dcf3b7753c763744',
  // '64232ea9335af8ba76c212bd',
  // '64232ee313ff390e206074e0',
  // '64232fa9dc26fb142e012ff4',
];

export const arrayIdsAnalysis = [
  '6478c8a3b722d8c83ac83b65',
  '660c49f41598609eedf816df',
  '660c4a361598609eedf816e0',
];

// harvest
export const arrayIdWorkers = [
  '6581f427d7a119d724014051',
  '6581f43297719dbd9ad1003e',
  '6581f43cf06808f20db2a219',
  '6581f449d74c876ade577334',
  '6581f44e3edfc476a522e24a',
  '6581f4529f91f9df4fcddf60',
];

export const arrayIdHarvestReports = [
  '6581f458c549fbf6c553fa3e',
  '6581f45f61af07d9311bd0c9',
  '6581f462a199a34155978d94',
  '6581f7b174bb483c2fefb506',
];

export const arrayIdHarvestPayment = [
  '6581f46b49dfacab17a89358',
  '6581f6102f4389eafb4dcf71',
];

export const arrayIdHarvestQr = [
  '6581f620878736f5479fffeb',
  '642337ecd39504aaa4d10014',
];

export const arrayIdHarvestLabor = [
  '6581f3b9ecf3c213c881d1be',
  '6581f41a6b442c72a41370aa',
];

// array de subs de cosecha
export const arraySubsHarvest = [
  {
    id: '642337ecd39504aaa4d100005',
    title: 'Labores',
    subsub: arrayIdHarvestLabor,
    icon: 'EventIcon',
  },
  {
    id: '642337ecd39504aaa4d100001',
    title: 'Trabajadores',
    subsub: arrayIdWorkers,
    icon: 'ManageAccountsIcon',
  },
  {
    id: '642337ecd39504aaa4d100002',
    title: 'Reportes',
    subsub: arrayIdHarvestReports,
    icon: 'SummarizeIcon',
  },
  {
    id: '642337ecd39504aaa4d100003',
    title: 'Pagos',
    subsub: arrayIdHarvestPayment,
    icon: 'PaymentIcon',
  },
  {
    id: '642337ecd39504aaa4d100004',
    title: 'Qr',
    subsub: arrayIdHarvestQr,
    icon: 'QrCodeScannerIcon',
  },
];
