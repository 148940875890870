import { Box, Paper, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { numberFormatDecimals } from 'helpers';

const TodayWeather = (props) => {
  const { todayData, ...others } = props;

  console.log(todayData, 'todayData');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
      {todayData?.weather[0] && (
        <img
          src={todayData?.weather[0]?.icon}
          alt='weather'
          className='img-fluid'
        />
      )}
      <Typography variant='h3' sx={{ textAlign: 'left' }}>
        {numberFormatDecimals(todayData?.tempMean, 0)}°C
      </Typography>
      <Typography variant='h5' sx={{ textAlign: 'left', fontSize: 18 }}>
        {todayData?.weather[0]?.description
          ? todayData.weather[0].description.charAt(0).toUpperCase() +
            todayData.weather[0].description.slice(1)
          : 'No data'}
      </Typography>
    </Box>
  );
};

export default TodayWeather;
