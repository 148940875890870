import React, { memo, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
import regionsGeoJSON from '../data/simp_reg.json'; // Archivo _reg
import provincesGeoJSON from '../data/simp_prov.json'; // Archivo _prov
require('highcharts/modules/exporting')(Highcharts);

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const regionColors = [
  { key: '14', color: 'red', value: 10 }, // Ñuble
  { key: '10', color: 'green', value: 20 }, // Los Lagos
  { key: '07', color: 'blue', value: 15 }, // Maule
  { key: '092', color: 'blue', value: 46 }, // Maule
  // Agrega más regiones según corresponda
];

const MapChile = () => {
  const [mapData, setMapData] = useState(regionsGeoJSON); // Carga inicial de regiones
  const [isRegionView, setIsRegionView] = useState(true); // Vista actual (regiones o provincias)
  const [regionName, setRegionName] = useState(null); // Nombre de la región seleccionada

  const mapRef = useRef(null);

  const handleRegionClick = (regionKey, regionName) => {
    // Filtrar provincias de la región seleccionada
    const filteredProvinces =
      provincesGeoJSON.objects.simp_prov.geometries.filter(
        (province) => province.properties.CUT_REG === regionKey
      );

    console.log('click func', regionKey, regionName, filteredProvinces);

    if (filteredProvinces.length > 0) {
      setMapData({
        ...provincesGeoJSON,
        objects: {
          simp_prov: {
            type: 'GeometryCollection',
            geometries: filteredProvinces,
          },
        },
      });
      setIsRegionView(false);
      setRegionName(regionName); // Guardar nombre de la región
    } else {
      console.log('No hay provincias para esta región.');
    }
  };

  // console.log(
  // 	JSON.stringify(mapData, 0, 2),
  // 	'mapData------------',
  // 	isRegionView
  // );

  const handleBackToRegions = () => {
    setMapData(regionsGeoJSON); // Regresar al mapa de regiones
    setIsRegionView(true);
    setRegionName(null);
  };

  const handleSelectRegion = (regionKey) => {
    const chart = mapRef.current.chart;

    console.log(chart.series[0].data, '--------------data');
    const point = chart.series[0].data.find((p) => p.key === regionKey);
    if (point) {
      console.log(point, '--------------point');
      point.select();
      point.update({ color: '#FF0000' });
      handleRegionClick(regionKey, 'Metropolitana de Santiago');
    }
  };

  console.log(
    '----------------------DATA',
    mapData.objects[isRegionView ? 'simp_reg' : 'simp_prov'].geometries.map(
      (feature) => {
        // Encuentra el color y valor para la región actual
        const regionData = isRegionView
          ? regionColors.find(
              (region) => region.key === feature.properties.CUT_REG
            )
          : regionColors.find(
              (region) => region.key === feature.properties.CUT_PROV
            );

        return {
          key: isRegionView
            ? feature.properties.CUT_REG
            : feature.properties.CUT_PROV, // ID único de la región
          name: isRegionView
            ? feature.properties.REGION
            : feature.properties.PROVINCIA, // Nombre de la región/provincia
          color: regionData ? regionData.color : 'gray', // Color asociado
          value: regionData ? regionData.value : 0, // Valor asociado
        };
      }
    )
  );

  const options = {
    ...generalOptionsHighCharts,
    chart: {
      map: mapData,
    },
    title: {
      text: isRegionView
        ? 'Mapa de Regiones de Chile'
        : `Provincias de la Región: ${regionName}`,
    },
    series: [
      {
        data: mapData.objects[
          isRegionView ? 'simp_reg' : 'simp_prov'
        ].geometries.map((feature) => {
          // Encuentra el color y valor para la región actual
          const regionData = isRegionView
            ? regionColors.find(
                (region) => region.key === feature.properties.CUT_REG
              )
            : regionColors.find(
                (region) => region.key === feature.properties.CUT_PROV
              );

          return {
            key: isRegionView
              ? feature.properties.CUT_REG
              : feature.properties.CUT_PROV, // ID único de la región
            name: isRegionView
              ? feature.properties.REGION
              : feature.properties.PROVINCIA, // Nombre de la región/provincia
            color: regionData ? regionData.color : 'gray', // Color asociado
            value: regionData ? regionData.value : 0, // Valor asociado
          };
        }),
        mapData: mapData,
        joinBy: isRegionView ? ['CUT_REG', 'key'] : ['CUT_PROV', 'key'], // Relación entre el GeoJSON y la data
        name: isRegionView ? 'Regiones de Chile' : 'Provincias',
        borderColor: '#000000', // Color de los bordes
        borderWidth: 1, // Grosor de los bordes
        states: {
          hover: {
            color: '#a4edba', // Color al pasar el mouse
          },
        },
        point: {
          events: {
            click: function () {
              if (isRegionView) {
                console.log(
                  `Región seleccionada: ${this.name} (ID: ${this.key})`,
                  '-------->this:',
                  this
                );
                handleRegionClick(this.key, this.name);
              }
            },
          },
        },
      },
    ],
  };

  // console.log(JSON.stringify(options, 0, 2), 'options---------------');

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'mapChart'}
        options={options}
        ref={mapRef}
        key={isRegionView ? 'region' : 'provincia'}
      />
      {!isRegionView && (
        <button onClick={handleBackToRegions} style={{ marginTop: '10px' }}>
          Volver a las Regiones
        </button>
      )}
      <button onClick={() => handleSelectRegion('13')}>Seleccionar 13</button>
    </div>
  );
};

export default MapChile;
