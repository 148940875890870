import { colors, createTheme } from '@mui/material';
import { getContrastRatio } from '@mui/material/styles';
import { chart } from 'highcharts';

const white = '#FFFFFE';
const black = '#000000';

const { palette } = createTheme();

const getPrimaryColor = () => {
  // Lógica para obtener el color primario según el entorno
  const environment = process.env.REACT_APP_ENV;

  // console.log('en theme', environment);

  if (environment === 'prototype') {
    return '#5C469C'; // Cambia este color al color deseado para el prototipo
  } else if (environment === 'staging') {
    return '#3C6255'; // Color para el entorno de preparación
  } else if (environment === 'production') {
    return '#203543'; // Color para el entorno de producción
  }
  return '#203543'; // Color por defecto
};

const getPrimaryDarkColor = () => {
  // Lógica para obtener el color primario según el entorno
  const environment = process.env.REACT_APP_ENV;
  if (environment === 'prototype') {
    return '#493684'; // Cambia este color al color deseado para el prototipo
  } else if (environment === 'staging') {
    return '#2e4f44'; // Color para el entorno de preparación
  } else if (environment === 'production') {
    return '#1A2831'; // Color para el entorno de producción
  }
  return '#1A2831'; // Color por defecto
};

export default {
  black,
  white,
  grey: {
    30: '#F4F5F5',
    50: '#F3F6FA',
    100: '#EBEEF1',
    200: '#DCE0E4',
    250: '#A6AEB4',
    300: '#7F92A7',
    400: '#464646',
    500: '#203543',
    600: '#66788E',
  },
  primary: {
    contrastText: white,
    dark: getPrimaryDarkColor(),
    main: getPrimaryColor(),
    light: '#66788E',
  },
  secondary: {
    contrastText: white,
    // dark: '#137ab4',
    main: '#345EB5',
    // light: '#0db1ff',
  },
  tertiary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: 'rgb(0, 112, 165)',
    light: 'rgb(51, 179, 239)',
    main: '#00A0EC',
  },
  boxes: {
    contrastText: white,
    dark: '#A84448',
    main: '#FF7000',
    light: '#81B214',
    darker: '#93329E',
    lighter: '#00ADB5',
  },
  countingStages: {
    prePoda: '#4EAA00',
    postPoda: '#2D580F',
    preRaleo: '#CC3333',
    postRaleo: '#8E2626',
  },
  speciesGradient: {
    Cerezo: 'linear-gradient(#B2001D, #55001B)',
    Ciruelo: 'linear-gradient(#B56B8D, #8B2B5A)',
    Nectarines: 'linear-gradient(#FF9A80 , #D40306)',
    Duraznero: 'linear-gradient(#FFB12D, #BC201A)',
    Carozo: 'linear-gradient(#FFB12D, #705922)',
    Damasco: 'linear-gradient(#fec63a, #944709)',
    Manzano: 'linear-gradient(#F80000, #7A0000)',
    Uva: 'linear-gradient(#8C467C, #660954)',
    Kiwi: 'linear-gradient(#D3F148, #7D9F12)',
  },
  gradient: {
    main: 'linear-gradient(0deg, rgba(42,65,79,1) 0%, rgba(82,101,112,1) 100%)',
  },
  weather: {
    forecast: {
      background: '#E0EDF9',
      modalBackground: '#D5EFFF',
      modalBorder: '#0D469B',
    },
    agroindicators: {
      background1: '#F1F9FE',
      background2: '#FFF5ED',
      background3: '#F1FCFA',
      avatar1: '#4CBFEB',
      avatar2: '#FFA767',
      avatar3: '#4ACFC0',
    },
    charts: {
      barColors: {
        cold: {
          primary: '#F1F9FE',
          secondary: '#ceebfc',
        },
        degree: {
          primary: '#FFF5ED',
          secondary: '#ffdfc6',
        },
        precipitation: {
          primary: '#F1FCFA',
          secondary: '#c8f2ea',
        },
      },
      splineColors: ['#4CBFEB', '#FFA767', '#4ACFC0'],
      daily: {
        cold: ['#86D3F3', '#1085B9', '#A6AEB4'],
        degree: ['#FFCFA9', '#C52F09', '#A6AEB4'],
        precipitation: ['#4ACFC0', '#165956', '#A6AEB4'],
        evapotranspiration: ['#A6AEB4', '#345EB5', '#A6AEB4'],
        temperature: ['#A6AEB4', '#345EB5', '#A6AEB4'],
        humidity: ['#A6AEB4', '#345EB5', '#A6AEB4'],
        radiation: ['#A6AEB4', '#345EB5', '#A6AEB4'],
      },
      detail: {
        cold: ['#10597C', '#108589', '#4CBFEB', '#86D3F3'],
        degree: ['#C52F09', '#FC5D13', '#FFA767', '#FFCFA9'],
        precipitation: ['#165956', '#158c83', '#4ACFC0', '#9FF0E2'],
      },
    },
  },
  irrigation: {
    balanceIndicators: {
      background: '#E0EDF9',
    },
  },
  mapbutton: {
    main: '#EBEEF1',
  },
  success: {
    contrastText: black,
    dark: '#00A46A',
    main: '#25E299',
    light: '#CDFEE4',
  },
  info: {
    contrastText: white,
    dark: '#0D469B',
    main: '#084EC5',
    light: '#C1E5FF',
  },
  warning: {
    contrastText: black,
    dark: '#E6A832',
    main: '#FFBB38',
    light: '#FFF8EB',
  },
  error: {
    contrastText: white,
    dark: '#772525',
    main: '#A12626',
    light: '#FFD8D8',
  },
  text: {
    primary: '#1A2831',
    secondary: '#203543',
    tertiary: '#66788E',
    link: colors.blue[600],
  },
  background: {
    default: white,
    paper: white,
  },
  icon: colors.grey[500],
  divider: colors.grey[200],
};

export const yearGraphColors = ['#00ADB5', '#81B214', '#93329E', '#FF5722'];

export const varietyGraphColors = [
  '#2A4C60',
  '#084EC5',
  '#81B214',
  '#00ADB5',
  '#A84448',
  '#FF7000',
  '#93329E',
  '#E6A832',
];

export const countStageColors = [
  '#a7a7a7',
  '#4EAA00',
  '#2D580F',
  '#CC3333',
  '#8E2626',
];
