import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Modal,
  Skeleton,
  SvgIcon,
  Table,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createSvgIcon } from '@mui/material/utils';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  CustomToggleButton,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  SelectMultipleWidgetFilter,
  WeatherAgroIndicator,
  WeatherDayForecast,
  WeatherTableIndicator,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { SimpleTabs, TabPanel } from '../../../components/TabPanel';
import {
  alterArrayGetFields,
  alterArrayGetValues,
  arrayGetStationIds,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import { MobileFilters } from '../../../containers/Library';
import {
  CardAgroindicators,
  MainDailyCharts,
  ResumeOrchard,
  TableDetailSeasons,
  TodayWeather,
} from '../../../containers/Weather';
import { weatherStation } from '../../../assets/icons/weather/svg_icons';
import ActualColdHour from '../../../containers/Weather/DetailGraphs/ActualColdHour';
import ActualColdPortion from '../../../containers/Weather/DetailGraphs/ActualColdPortion';
import ActualDegreeDays from '../../../containers/Weather/DetailGraphs/ActualDegreeDays';
import ActualPrecipitation from '../../../containers/Weather/DetailGraphs/ActualPrecipitation';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  tableGreyHeader: {
    '& thead': {
      backgroundColor: theme.palette.grey[30],
      '& tr th:not(:last-child)': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '20%', // Empieza al 20% desde arriba
          height: '60%', // Ocupa 60% de la altura
          width: 1,
          backgroundColor: theme.palette.grey[250],
        },
      },
    },
  },
  tableIndicators: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
    },
    '& td': {
      color: theme.palette.text.primary,
    },
  },
  paperModal: {
    backgroundColor: theme.palette.weather.forecast.modalBackground,
    border: '1px solid' + theme.palette.weather.forecast.modalBorder,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1, 3, 3),
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const options = [
  { label: 'Horas Frío', value: 'coldHour' },
  { label: 'Porciones Frío', value: 'coldPortion' },
  { label: 'Grados Día', value: 'degreeDays' },
  { label: 'Precipitaciones', value: 'precipitation' },
];

const optionsDailyButtons = [
  { label: 'Horas Frío', value: 'coldHour' },
  { label: 'Porciones Frío', value: 'coldPortion' },
  { label: 'Grados Día', value: 'degreeDays' },
  { label: 'Precipitaciones', value: 'precipitation' },
  { label: 'Evapotranspitación', value: 'evapotranspiration' },
  { label: 'Temperatura', value: 'temperature' },
  { label: 'Humedad', value: 'humidity' },
  { label: 'Radiación', value: 'radiation' },
];

const getTodayData = (data) => {
  const actualDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
  );

  // Formateamos la fecha como YYYY-MM-DD
  const year = actualDate.getFullYear();
  const month = String(actualDate.getMonth() + 1).padStart(2, '0');
  const day = String(actualDate.getDate()).padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  // Buscamos el objeto que coincide con la fecha de hoy
  return data.find((item) => item.dateTime === today);
};

const getAbvDayName = (dateTime) => {
  // Array con los nombres de los días en español
  const dayName = [
    'DOM', // Domingo - 0
    'LUN', // Lunes - 1
    'MAR', // Martes - 2
    'MIÉ', // Miércoles - 3
    'JUE', // Jueves - 4
    'VIE', // Viernes - 5
    'SÁB', // Sábado - 6
  ];

  const [year, month, day] = dateTime.split('-').map(Number);
  const fecha = new Date(year, month - 1, day);
  const numeroDia = fecha.getDay();
  return dayName[numeroDia];
};

const getThreeDaysData = (data) => {
  // Get current date in Chile timezone
  const chileDate = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
  );

  // Create dates for yesterday, today and tomorrow
  const yesterdayDate = new Date(chileDate);
  yesterdayDate.setDate(chileDate.getDate() - 1);

  const tomorrowDate = new Date(chileDate);
  tomorrowDate.setDate(chileDate.getDate() + 1);

  // Format dates as YYYY-MM-DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const yesterdayString = formatDate(yesterdayDate);
  const todayString = formatDate(chileDate);
  const tomorrowString = formatDate(tomorrowDate);

  // Create array of target dates
  const targetDates = [yesterdayString, todayString, tomorrowString];

  // Filter data to get only the three days we need
  const filteredData = data.filter((item) =>
    targetDates.includes(item.dateTime)
  );

  // Add dayType property to each item
  return filteredData.map((item) => {
    let dayType = '';

    if (item.dateTime === yesterdayString) {
      dayType = 'yesterday';
    } else if (item.dateTime === todayString) {
      dayType = 'today';
    } else if (item.dateTime === tomorrowString) {
      dayType = 'tomorrow';
    }

    return {
      ...item,
      dayType,
    };
  });
};

const WeatherResume = (props) => {
  console.log(props, '<<<<---props en módulo');

  const { moduleId } = props;

  const { moduleUser } = useContext(Context);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const axiosContext = useContext(AxiosContext);

  // find in userModulesArray the module with the id equal to moduleId
  let userModuleWeather = moduleUser.find((module) => module.id === moduleId);

  // DETERMINA SI VE O NO STATIONS
  const hasStations =
    userModuleWeather && userModuleWeather.process
      ? userModuleWeather.process.some(
          (process) =>
            process.id === '67cf36aa6d6f66ab68043c23' &&
            process.assigned === true
        )
      : false;

  const errorHandler = useErrorHandler();

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [loadingState, setLoadingState] = useState(false);

  const [resumeDataState, setResumeDataState] = useState(null);

  const [orchardNameState, setOrchardNameState] = useState('');

  const theme = useTheme();

  // console.log(theme, 'theme');

  const classes = useStyles();

  const WeatherStationIcon = createSvgIcon(weatherStation, 'Station');

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusWeather';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  async function queryData(orchardValue) {
    setLoadingState(true);
    try {
      const params = {
        orchard: orchardValue,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.get('v1/weather/forecast', {
        params,
      });

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setResumeDataState(data);
      }
    } catch (err) {
      setResumeDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  const onSubmit = async (values) => {
    queryData(values.orchard.value);

    setOrchardNameState(values.orchard.label);

    setInfoFilterTab(values.orchard.value);
  };

  // useEffect(() => {
  //   if (orchard_filter) {
  //     // console.log(orchard_filter, '<----orchard_filter');
  //     setValue('orchard', [{ value: orchard_filter, label: orchard_filter }]);
  //   }
  // }, []);

  console.log(resumeDataState, '>----resumeDataState');

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='d-flex flex-column flex-md-row align-items-md-center'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='mb-3 mb-md-0'>
            <SelectMultipleWidgetFilter
              errors={errors.orchard}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Huerto'
              minheight={false}
              req
              name='orchard'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={filterDataResponseState}
              placeholder='Huerto'
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <LoadingButton
              variant='contained'
              type='submit'
              color='primary'
              aria-label='filter'
              size='medium'
              loading={false}
            >
              <ArrowForwardIosIcon />
            </LoadingButton>
          </div>
        </form>
      </MobileFilters>
    );
  };

  const [selectedValueAgroindicatorState, setSelectedValueAgroindicatorState] =
    useState('coldHour');

  const [selectedValueDetailState, setSelectedValueDetailState] =
    useState('coldHour');

  const [selectedValueDailyState, setSelectedValueDailyState] =
    useState('coldHour');

  const [openModalState, setOpenModalState] = useState(false);

  const handleChangeAgroindicator = (newValue) => {
    setSelectedValueAgroindicatorState(newValue);
  };

  const handleChangeDetail = (newValue) => {
    setSelectedValueDetailState(newValue);
  };

  const handleChangeDailyCharts = (newValue) => {
    setSelectedValueDailyState(newValue);
  };

  const handleOpenModal = () => setOpenModalState(true);
  const handleCloseModal = () => setOpenModalState(false);

  // console.log(selectedValueDetailState, '----selectedValueDetailState');

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <CardPaper
              elevation={0}
              sx={{
                borderTop: 0,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              <CardContent className='p-0'>
                <BsRow class=' align-items-center'>
                  <BsCol class='col-md-6'>
                    <Typography variant='h4' component='h2'>
                      Resumen de Huerto {orchardNameState}
                    </Typography>
                  </BsCol>
                  <BsCol class='col-lg-6 d-md-flex justify-content-md-end'>
                    <Box
                      sx={{
                        // height: '20px',
                        // width: '40px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderFilters()}
                      <Typography
                        variant='h6'
                        sx={{ textAlign: 'right', mt: 2 }}
                      >
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography>
                    </Box>
                  </BsCol>
                </BsRow>
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        {loadingState && (
          <div className='form-style'>
            <BsContainer fluid class='px-3 px-md-3'>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-6'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
                <BsCol class='col-md-6'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
              </BsRow>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-6'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
                <BsCol class='col-md-6'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
              </BsRow>
            </BsContainer>
          </div>
        )}

        {!loadingState && resumeDataState && (
          <>
            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-6'>
                <CardPaper
                  elevation={0}
                  variant='filled'
                  style={{
                    // borderTop: 0,
                    backgroundColor: theme.palette.weather.forecast.background,
                    height: '100%',
                  }}
                >
                  <CardContent className='p-3'>
                    <Typography
                      gutterBottom
                      variant='h4'
                      component='h2'
                      color='secondary'
                    >
                      Pronóstico del clima
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Información climática de los próximos días, alertas e
                      información
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                        mt: 4,
                      }}
                    >
                      <TodayWeather
                        todayData={getTodayData(
                          resumeDataState.weatherForecastData
                        )}
                      />
                      <Box
                        sx={{
                          border: '1px solid',
                          borderRadius: `${theme.shape.borderRadius}px`,
                          p: 1,
                          cursor: 'pointer',
                        }}
                        onClick={handleOpenModal}
                      >
                        <WeatherStationIcon sx={{ width: 24, height: 24 }} />
                      </Box>
                      <Modal
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        open={openModalState}
                        onClose={handleCloseModal}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <Box
                          className={classes.paperModal}
                          sx={{
                            width: {
                              xs: '90%',
                              sm: '50%',
                              md: '30%',
                            },
                          }}
                        >
                          <Typography
                            id='modal-modal-title'
                            variant='h4'
                            component='h3'
                          >
                            Nombre de la Estación
                          </Typography>
                          <Typography
                            id='modal-modal-description'
                            sx={{ mt: 2 }}
                          >
                            <span className='font-weight-bold'>Ubicación:</span>{' '}
                            {resumeDataState?.orchardStation?.stationId ||
                              'N/A'}
                          </Typography>
                          <Typography
                            id='modal-modal-description'
                            sx={{ mt: 2 }}
                          >
                            <span className='font-weight-bold'>Proveedor:</span>{' '}
                            {resumeDataState?.orchardStation?.dataSource ||
                              'N/A'}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mt: 2,
                            }}
                          >
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={handleCloseModal}
                            >
                              Cerrar
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={1}
                      className='my-4'
                      justifyContent={{
                        lg: 'space-evenly',
                        md: 'flex-start',
                      }}
                    >
                      {resumeDataState.weatherForecastData
                        .slice(1)
                        .map((item, index) => (
                          <Grid item>
                            <WeatherDayForecast
                              data={{
                                max: item?.tempMax,
                                min: item?.tempMin,
                                icon: item?.weather[0].icon,
                                dayName: getAbvDayName(item?.dateTime),
                              }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-6'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Agroindicadores Diarios y Proyectados
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Cálculo de la acumulación de los agoindicadores en los
                      siguientes 8 días
                    </Typography>
                    <CustomToggleButton
                      options={options}
                      value={selectedValueAgroindicatorState}
                      onChange={handleChangeAgroindicator}
                      ariaLabel='weather metrics'
                      size='sm'
                      buttonActiveColor={
                        theme.palette.weather.forecast.background
                      }
                      buttonActiveFontColor={theme.palette.secondary.main}
                    />
                    <Box
                      sx={{
                        p: 0,
                      }}
                    >
                      {selectedValueAgroindicatorState === 'coldHour' ? (
                        <ActualColdHour orchardValue={infoFilterTab} />
                      ) : null}
                      {selectedValueAgroindicatorState === 'coldPortion' ? (
                        <ActualColdPortion orchardValue={infoFilterTab} />
                      ) : null}
                      {selectedValueAgroindicatorState === 'degreeDays' ? (
                        <ActualDegreeDays orchardValue={infoFilterTab} />
                      ) : null}
                      {selectedValueAgroindicatorState === 'precipitation' ? (
                        <ActualPrecipitation orchardValue={infoFilterTab} />
                      ) : null}
                    </Box>
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>

            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-6'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Tabla de Indicadores
                    </Typography>
                    <div className='table-responsive'>
                      <table className={clsx('table', classes.tableIndicators)}>
                        <thead>
                          <tr>
                            <th scope='col'>INDICADOR</th>
                            <th scope='col'>AYER</th>
                            <th scope='col'>ACTUAL</th>
                            <th scope='col'>MAÑANA</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <WeatherTableIndicator noData />
                            </td>
                            {getThreeDaysData(
                              resumeDataState.weatherForecastData
                            ).map((item, index) => (
                              <td>
                                <WeatherTableIndicator
                                  active={index === 1}
                                  data={item}
                                />
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-6'>
                {resumeDataState?.weatherAgroClimaticData?.weatherBoxes ? (
                  <CardAgroindicators
                    agroIndicatorsData={
                      resumeDataState.weatherAgroClimaticData.weatherBoxes
                    }
                    agroIndicators={options}
                  />
                ) : null}
              </BsCol>
            </BsRow>

            <BsRow class='gutter_10 mb-3'>
              <BsCol class={hasStations ? 'col-md-6' : 'col-md-12'}>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Detalle Mensual
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Acumulado de los agroindicadores mensuales
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        my: 1,
                      }}
                    >
                      <CustomToggleButton
                        options={options}
                        value={selectedValueDetailState}
                        onChange={handleChangeDetail}
                        size='sm'
                        buttonActiveColor={
                          theme.palette.weather.forecast.background
                        }
                        buttonActiveFontColor={theme.palette.secondary.main}
                      />
                    </Box>
                    <div className='table-responsive'>
                      <table
                        className={clsx(
                          'table table-borderless',
                          classes.tableGreyHeader
                        )}
                      >
                        <TableDetailSeasons
                          seasonsData={
                            resumeDataState?.weatherAgroClimaticData
                              ?.agroClimaticData?.[
                              selectedValueDetailState
                            ]?.[0]?.data || []
                          }
                        />
                      </table>
                    </div>
                  </CardContent>
                </CardPaper>
              </BsCol>
              {hasStations ? (
                <BsCol class='col-md-6'>
                  <CardPaper elevation={0} variant='outlined'>
                    <CardContent className='p-3'>
                      <Typography gutterBottom variant='h4' component='h2'>
                        Comparativo Estación
                      </Typography>
                      {hasStations ? (
                        <>
                          <Typography gutterBottom variant='p' component='p'>
                            Comparativo entre estaciones climáticas
                          </Typography>
                          <Typography variant='body2' component='p'>
                            Estación: San Hernan
                          </Typography>
                          <Typography
                            gutterBottom
                            variant='body2'
                            component='p'
                          >
                            Ref 1: Los Encinos Ref 2: La Morera Ref 3: El Parque
                          </Typography>
                          <table
                            className={clsx(
                              'table table-borderless mt-3',
                              classes.tableGreyHeader
                            )}
                          >
                            <thead>
                              <tr>
                                <th scope='col'>Estación</th>
                                <th scope='col'>P. Frío</th>
                                <th scope='col'>H. Frío</th>
                                <th scope='col'>P.p</th>
                                <th scope='col'>G. Día</th>
                                <th scope='col'>Diferencia</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Estación</td>
                                <td>200</td>
                                <td>200</td>
                                <td>200</td>
                                <td>200</td>
                                <td>200</td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <Box
                          sx={{
                            height: 250,
                            pt: 2,
                          }}
                        >
                          <Typography variant='h5' component='p'>
                            Módulo no disponible.
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </CardPaper>
                </BsCol>
              ) : null}
            </BsRow>

            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-12'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Gráfico diario
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Acumulativo de los agroindicadores por día
                    </Typography>
                    <CustomToggleButton
                      options={optionsDailyButtons}
                      size='sm'
                      value={selectedValueDailyState}
                      onChange={handleChangeDailyCharts}
                      buttonActiveColor={
                        theme.palette.weather.forecast.background
                      }
                      buttonActiveFontColor={theme.palette.secondary.main}
                    />
                    <MainDailyCharts
                      filter={{
                        orchard: infoFilterTab,
                        graph: selectedValueDailyState,
                      }}
                    />
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>
          </>
        )}
        {/* 
        <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <ResumeOrchard filter={infoFilterTab} />
          </BsCol>
        </BsRow> */}
      </BsContainer>
    </div>
  );
};
export default WeatherResume;
