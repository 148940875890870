// import _ from "lodash";
import { useState, useEffect, useContext, useMemo, useRef } from 'react';
import {
  useTheme,
  Grid,
  Typography,
  Box,
  Skeleton,
  Button,
  FormControlLabel,
  FormGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BsContainer, BsRow, BsCol } from '../../../layouts/components';
import { CardPaper, ReactDataTable } from '../../../components';
import { FiltersContext, OptionsContext } from '../../../context';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  generalOptionsHighCharts,
  numberFormatDecimals,
  setOrderForApi,
  setOrderForNewApi,
} from '../../../helpers';
import {
  colsCounterOrchards,
  colsNoVisCounterOrchardsPrePoda,
  colsNoVisCounterOrchardsPostPoda,
  colsNoVisCounterOrchardsPreRaleo,
  colsNoVisCounterOrchardsPostRaleo,
  initialSorting,
} from '../../../helpers/tableDefinitions';
import { countStageColors } from '../../../theme/palette';
import { ColumnChart } from 'components/Charts';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 27,
    },
  },
  theadLightSmall: {
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
  tbodyStyle: {
    '& td': {
      verticalAlign: 'middle',
    },
  },
}));

const endPointName = 'v1/counter/resume-counter';
const endPointNameTable = 'v1/counter/resume-counter-table';

const processConteoChartData = (data, total) => {
  const { value = {} } = data || {};

  // Extraer etapas dinámicamente
  const stages = Object.keys(value);

  // Transformar los nombres de las claves para las columnas
  const columns = stages.map((stage) => {
    // Convierte camelCase a espacios entre palabras y capitaliza cada palabra
    return stage
      .replace(/([A-Z])/g, ' $1') // Inserta espacio antes de cada mayúscula
      .replace(/^./, (str) => str.toUpperCase()); // Capitaliza primera letra
  });

  // Calcular los valores "No Contado" para cada etapa
  const noContadoData = stages.map((stage) =>
    Math.max(0, total - (value[stage] || 0))
  );

  // Obtener los valores "Contado" para cada etapa
  const contadoData = stages.map((stage) => value[stage] || 0);

  // Formato de datos requerido
  return {
    columns: columns,
    rows: [
      {
        name: 'No Contado',
        data: noContadoData,
        stack: 'Conteo',
      },
      {
        name: 'Contado',
        data: contadoData,
        stack: 'Conteo',
      },
    ],
  };
};

const Dashboard = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);

  const classes = useStyles();

  const history = useHistory();

  const chartRef = useRef();

  const errorHandler = useErrorHandler();

  const { userOrchard } = useContext(Context);

  const axiosContext = useContext(AxiosContext);

  const { addFilter } = useContext(FiltersContext);

  const {
    state: { counting_dashboard },
    addOptions,
  } = useContext(OptionsContext);

  const [dashDataState, setDashDataState] = useState(null);
  const [loadingDashDataState, setLoadingDashDataState] = useState(false);

  const [tableSelectedTypeState, setTableSelectedTypeState] = useState(
    counting_dashboard || 'prePoda'
  );

  const redirectOrchard = (row) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir
    const link = '/counting-quarters';

    const { orchardValue } = row?.original;

    addFilter({
      orchard_filter: orchardValue,
    });

    // // a biblioteca
    history.push({
      pathname: link,
    });
  };

  // agrega en la columna orchard un boton para redirigir a la vista de cuarteles

  colsCounterOrchards.forEach((section) => {
    if (section.id === 'productiveUnit') {
      section.columns.forEach((column) => {
        if (column.accessorKey === 'orchard') {
          // eslint-disable-next-line react/display-name
          column.Cell = ({ renderedCellValue, row }) => (
            <Button variant='text' onClick={() => redirectOrchard(row)}>
              {renderedCellValue}
            </Button>
          );
        }
      });
    }
  });

  const columns = useMemo(() => colsCounterOrchards, []);

  const customHeadStyles = {};

  const headRowStyles = {
    sx: (theme) => ({
      // para fijar cabeceras de varios rows
      position: 'sticky',
      top: 0,
      zIndex: 1,
      '& tr:first-of-type': {
        'th:nth-of-type(odd)': {
          backgroundColor: theme.palette.grey[100],
        },
        'th > div': {
          justifyContent: 'center',
          py: 1,
          '& > div > div': {
            fontWeight: 'bold',
            fontSize: 15,
          },
        },
        '& th:nth-of-type(2)': {
          borderBottom:
            '3px solid ' + theme.palette.countingStages[tableSelectedTypeState],
        },
      },
      '& tr:nth-of-type(2)': {
        th: {
          borderLeft: '1px solid ' + theme.palette.grey[200],
        },

        'th:last-child': {
          borderRight: '1px solid ' + theme.palette.grey[200],
        },
      },
    }),
  };

  // table
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(initialSorting);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [visibleColumnsState, setVisibleColumnsState] = useState([]);

  async function queryDashboardData() {
    setLoadingDashDataState(true);
    try {
      const { orchards, specieValue } = filterProp || {};

      const orchardsValues =
        orchards && orchards.length
          ? orchards.map((orchard) => orchard.value)
          : userOrchard;

      const objQuery = {
        orchardValue: orchardsValues,
        specieValue: specieValue,
      };

      const response = await axiosContext.authAxios.post(
        endPointName,
        objQuery
      );
      const { data, status } = response;

      if (data) {
        const totalStage =
          data.stageCount.noCount[0] +
          data.stageCount.prePoda[0] +
          data.stageCount.postPoda[0] +
          data.stageCount.preRaleo[0] +
          data.stageCount.postRaleo[0];

        setDashDataState({
          ...data,
          totalStage,
        });
      }
    } catch (err) {
      setDashDataState([]);
      errorHandler(err);
    } finally {
      setLoadingDashDataState(false);
    }
  }

  useEffect(() => {
    if (filterProp) {
      queryDashboardData();
    }
  }, [filterProp]);

  useEffect(() => {
    const fetchDataTable = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const { orchards, specieValue } = filterProp || {};

      // map to get only the values

      const orchardsValues =
        orchards && orchards.length
          ? orchards.map((orchard) => orchard.value)
          : userOrchard;

      const objQuery = {
        orchardValue: orchardsValues,
        // huerto_user: userOrchard, // orchard del login
        // counterType: tableSelectedTypeState || 'prePoda',
        specieValue: specieValue,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };
      // console.log(specieValue, '<----specieValue');

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointNameTable,
          objQuery
        );

        const { data, status } = response;

        // console.log(data, '----asi llega el first tab');

        setData(data.data);
        setRowCount(data.count);

        // TODO: setear el total de plantas contadas
        // setData(MOCK_TABLE);
        // setRowCount(MOCK_TABLE.length);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchDataTable();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  const optionsHighChartsStacked = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'bar',
      height: 200,
      spacingBottom: 3,
      spacingLeft: 10,
      spacingRight: 10,
      spacingTop: 3,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.0f}</b> ({point.percentage:.0f}%)<br/>',
      shared: false,
    },
    xAxis: {
      categories: ['Etapa de Conteo'],

      crosshair: false,
    },
    yAxis: {
      min: 0,
      // gridLineWidth: 1,
      // tickPixelInterval: 20,
      reversedStacks: false,
      title: {
        text: '',
      },
      labels: {
        formatter: function () {
          return this.value + '%';
        },
      },
    },
    // legend: {
    //   reversed: true, // right here!
    // },
    colors: countStageColors,
    series: [
      {
        data: dashDataState?.stageCount?.noCount || [0],
        name: 'Sin Conteo',
      },
      {
        data: dashDataState?.stageCount?.prePoda || [0],
        name: 'Pre Poda',
      },
      {
        data: dashDataState?.stageCount?.postPoda || [0],
        name: 'Post Poda',
      },
      {
        data: dashDataState?.stageCount?.preRaleo || [0],
        name: 'Pre Raleo',
      },
      {
        data: dashDataState?.stageCount?.postRaleo || [0],
        name: 'Post Raleo',
      },
    ],
    plotOptions: {
      series: {
        stacking: 'percent',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.y.toFixed(0); // Mostrar dos decimales en los dataLabels
          },
        },
      },
    },
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Rendimiento por Etapas de Conteo',
    },
  };

  const stageChartData = dashDataState
    ? processConteoChartData(
        dashDataState.counterBoxes.find(
          (item) => item.label === 'Cuarteles Contados'
        ),
        dashDataState.totalStage
      )
    : { rows: [], columns: [] };

  const handleChangeType = (event) => {
    setTableSelectedTypeState(event.target.value);
  };

  useEffect(() => {
    switch (tableSelectedTypeState) {
      case 'prePoda':
        setVisibleColumnsState([
          ...colsNoVisCounterOrchardsPostPoda,
          ...colsNoVisCounterOrchardsPreRaleo,
          ...colsNoVisCounterOrchardsPostRaleo,
        ]);
        break;
      case 'postPoda':
        setVisibleColumnsState([
          ...colsNoVisCounterOrchardsPrePoda,
          ...colsNoVisCounterOrchardsPreRaleo,
          ...colsNoVisCounterOrchardsPostRaleo,
        ]);
        break;
      case 'preRaleo':
        setVisibleColumnsState([
          ...colsNoVisCounterOrchardsPrePoda,
          ...colsNoVisCounterOrchardsPostPoda,
          ...colsNoVisCounterOrchardsPostRaleo,
        ]);
        break;
      case 'postRaleo':
        setVisibleColumnsState([
          ...colsNoVisCounterOrchardsPrePoda,
          ...colsNoVisCounterOrchardsPostPoda,
          ...colsNoVisCounterOrchardsPreRaleo,
        ]);
        break;
      default:
        setVisibleColumnsState([
          ...colsNoVisCounterOrchardsPostPoda,
          ...colsNoVisCounterOrchardsPreRaleo,
          ...colsNoVisCounterOrchardsPostRaleo,
        ]);
        break;
    }
    addOptions({
      counting_dashboard: tableSelectedTypeState,
    });
  }, [tableSelectedTypeState]);

  return (
    <BsRow>
      <BsCol class='col-md-12'>
        <BsRow class='gutter_10'>
          {!loadingDashDataState ? (
            <>
              <BsCol class='col-md-12 counting-dashboard-2'>
                <Box>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Radio />}
                      value='prePoda'
                      checked={tableSelectedTypeState === 'prePoda'}
                      onChange={handleChangeType}
                      label='Pre Poda'
                      componentsProps={{
                        typography: { variant: 'body2' },
                      }}
                      sx={{
                        p: 0.5,
                        height: 35,
                      }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value='postPoda'
                      checked={tableSelectedTypeState === 'postPoda'}
                      onChange={handleChangeType}
                      componentsProps={{
                        typography: { variant: 'body2' },
                      }}
                      label='Post Poda'
                      sx={{
                        p: 0.5,
                        height: 35,
                      }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value='preRaleo'
                      checked={tableSelectedTypeState === 'preRaleo'}
                      onChange={handleChangeType}
                      label='Pre Raleo'
                      componentsProps={{
                        typography: { variant: 'body2' },
                      }}
                      sx={{
                        p: 0.5,
                        height: 35,
                      }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value='postRaleo'
                      checked={tableSelectedTypeState === 'postRaleo'}
                      onChange={handleChangeType}
                      label='Post Raleo'
                      componentsProps={{
                        typography: { variant: 'body2' },
                      }}
                      sx={{
                        p: 0.5,
                        height: 35,
                      }}
                    />
                  </FormGroup>
                </Box>
              </BsCol>

              <BsCol class='col-md-12 counting-dashboard-3'>
                <div style={{ display: 'flex' }}>
                  <Grid
                    container
                    columnSpacing={{ xs: 1.4 }}
                    // sx={{ mx: 0 + ' !important' }}
                    sx={{
                      minHeight: {
                        xs: 200,
                        lg: 102,
                      },
                    }}
                    columns={{ xs: 6, lg: 4 }}
                  >
                    <Grid
                      item
                      xs={3}
                      lg={1}
                      sx={{
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                      }}
                    >
                      <CardPaper
                        elevation={0}
                        topColor=''
                        borderTop={0}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          backgroundColor: theme.palette.boxes.dark,
                        }}
                      >
                        <Typography
                          variant='body2'
                          gutterBottom
                          sx={{ fontWeight: 500, color: 'white' }}
                        >
                          Plantas Contadas
                        </Typography>
                        <Typography
                          variant='h3'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          {dashDataState?.counterBoxes[0]?.value
                            ? numberFormatDecimals(
                                dashDataState.counterBoxes[0]?.value[
                                  tableSelectedTypeState
                                ],
                                0
                              )
                            : '-'}
                        </Typography>
                      </CardPaper>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      lg={1}
                      sx={{
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                      }}
                    >
                      <CardPaper
                        elevation={0}
                        topColor=''
                        borderTop={0}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          backgroundColor: theme.palette.boxes.main,
                        }}
                      >
                        <Typography
                          variant='body2'
                          gutterBottom
                          sx={{ fontWeight: 500, color: 'white' }}
                        >
                          % Plantas Contadas
                        </Typography>
                        <Typography
                          variant='h3'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          {dashDataState?.counterBoxes[1]?.value
                            ? numberFormatDecimals(
                                dashDataState.counterBoxes[1]?.value[
                                  tableSelectedTypeState
                                ],
                                2
                              )
                            : '-'}
                        </Typography>
                      </CardPaper>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      lg={1}
                      sx={{
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                      }}
                    >
                      <CardPaper
                        elevation={0}
                        topColor=''
                        borderTop={0}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          backgroundColor: theme.palette.boxes.light,
                        }}
                      >
                        <Typography
                          variant='body2'
                          gutterBottom
                          sx={{ fontWeight: 500, color: 'white' }}
                        >
                          Cuarteles Contados
                        </Typography>
                        <Typography
                          variant='h3'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          {dashDataState?.counterBoxes[2]?.value
                            ? numberFormatDecimals(
                                dashDataState.counterBoxes[2]?.value[
                                  tableSelectedTypeState
                                ],
                                0
                              )
                            : '-'}
                        </Typography>
                      </CardPaper>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      lg={1}
                      sx={{
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                      }}
                    >
                      <CardPaper
                        elevation={0}
                        topColor=''
                        borderTop={0}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          backgroundColor: theme.palette.boxes.lighter,
                        }}
                      >
                        <Typography
                          variant='body2'
                          gutterBottom
                          sx={{ fontWeight: 500, color: 'white' }}
                        >
                          Cuarteles No Contados
                        </Typography>
                        <Typography
                          variant='h3'
                          sx={{ fontWeight: 700, color: 'white' }}
                        >
                          {/* {dashDataState?.stageCount?.noCount
                          ? numberFormatDecimals(
                              dashDataState.stageCount?.noCount[0],
                              1
                            )
                          : '-'} */}
                          {dashDataState?.totalStage &&
                          dashDataState.counterBoxes[2]?.value
                            ? numberFormatDecimals(
                                dashDataState.totalStage -
                                  dashDataState.counterBoxes[2]?.value[
                                    tableSelectedTypeState
                                  ],
                                1
                              )
                            : '-'}
                        </Typography>
                      </CardPaper>
                    </Grid>
                  </Grid>
                </div>
              </BsCol>
            </>
          ) : (
            <>
              <BsCol class='col-6 col-lg mb-2'>
                <Skeleton variant='rectangular' width='100%' height={90} />
              </BsCol>
              <BsCol class='col-6 col-lg mb-2'>
                <Skeleton variant='rectangular' width='100%' height={90} />
              </BsCol>
              <BsCol class='col-6 col-lg mb-2'>
                <Skeleton variant='rectangular' width='100%' height={90} />
              </BsCol>
              <BsCol class='col-6 col-lg mb-2'>
                <Skeleton variant='rectangular' width='100%' height={90} />
              </BsCol>
              <BsCol class='col-6 col-lg'>
                <Skeleton variant='rectangular' width='100%' height={90} />
              </BsCol>
            </>
          )}
          <BsCol class='col-md-12 mt-3'>
            <div
              className='px-3 pt-4'
              style={{
                border: '1px solid',
                borderColor: theme.palette.grey[300],
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <div className='counting-dashboard-3'>
                <Typography
                  variant='h5'
                  gutterBottom
                  component='h2'
                  sx={{ fontWeight: 700 }}
                >
                  Detalle de Huertos
                </Typography>
              </div>
              <div className='counting-dashboard-4'>
                <ReactDataTable
                  columns={columns}
                  data={data}
                  hideColumns={visibleColumnsState}
                  isError={isError}
                  isLoading={isLoading}
                  isRefetching={isRefetching}
                  module='CountingOrchards'
                  onSortingChange={setSorting}
                  sorting={sorting}
                  defaultColumnSort={'orchard'}
                  onPaginationChange={setPagination}
                  pagination={pagination}
                  enableColumnPinning={false}
                  enableHiding={false}
                  rowCount={rowCount}
                  onColumnFiltersChange={setColumnFilters}
                  columnFilters={columnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  globalFilter={globalFilter}
                  enableRowActions={false}
                  // renderRowActions={actionsColumn}
                  headRowStyles={headRowStyles}
                  headStyles={customHeadStyles}
                  enableStickyHeader={true}
                  positionPagination='top'
                />
              </div>
            </div>
          </BsCol>
        </BsRow>
        <BsRow class='gutter_10 mt-5'>
          {!loadingDashDataState ? (
            <BsCol class='col-md-12 counting-dashboard-5'>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Typography variant='h5' gutterBottom className='mb-4'>
                  Avance Conteo de Unidades Productivas
                </Typography>
                <ColumnChart
                  // title='Índice DRIS Cerezo'
                  data={stageChartData.rows}
                  xAxisCategories={stageChartData.columns}
                  chartRef={chartRef}
                  colors={[countStageColors[0], theme.palette.boxes.light]}
                  yAxisTitle='Avance' // Titulo del eje Y
                  type='column' // column o bar
                  stacking='percent'
                  exportOptions={true}
                  shadow={false}
                  gridLineWidth={1}
                  spacing={[10, 0, 0, 0]}
                />
                {/* <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsHighChartsStacked}
                /> */}
              </Box>
            </BsCol>
          ) : (
            <BsCol class='col-md-12'>
              <Skeleton variant='rectangular' width='100%' height={220} />
            </BsCol>
          )}
        </BsRow>
      </BsCol>
    </BsRow>
  );
};

export default Dashboard;
