import React, { useState, useRef, useContext, useEffect } from 'react';
import _ from 'lodash';
import { subDays } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Skeleton,
  SvgIcon,
  Table,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createSvgIcon } from '@mui/material/utils';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  CustomToggleButton,
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  SelectMultipleWidget2,
  SelectMultipleWidgetFilter,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { FiltersContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { MobileFilters } from '../../../containers/Library';
import clsx from 'clsx';
import CardEstimateIndicators from 'containers/Industry/CardEstimateIndicators';
import { ColumnChart, MapChart } from 'components/Charts';
import regionsGeoJSON from '../data/simp_reg.json'; // Archivo _reg
import provincesGeoJSON from '../data/simp_prov.json'; // Archivo _prov
import { alphabeticalSort, alterArrayDashDelete } from 'helpers';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  tableGreyHeader: {
    '& thead': {
      backgroundColor: theme.palette.grey[30],
      '& tr th:not(:last-child)': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '20%', // Empieza al 20% desde arriba
          height: '60%', // Ocupa 60% de la altura
          width: 1,
          backgroundColor: theme.palette.grey[250],
        },
      },
    },
  },
  tableIndicators: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
    },
    '& td': {
      color: theme.palette.text.primary,
    },
  },
}));

const indicators = [
  {
    label: 'Cuaja Real',
    value: 'fruitSetRealTotal',
  },
  {
    label: 'Cuaja Estimado',
    value: 'fruitSetEstimateTotal',
  },
  {
    label: 'Diferencia',
    value: 'difference',
  },
  {
    label: 'Superficie',
    value: 'surfaceTotal',
  },
  {
    label: 'Cuarteles',
    value: 'unitProductiveQuantity',
  },
];

const indicatorsData = {
  fruitSetRealTotal: {
    value: 0,
  },
  fruitSetEstimateTotal: {
    value: 0,
  },
  difference: {
    value: 0,
  },
  surfaceTotal: {
    value: 0,
  },
};

const varietyData = {
  categories: ['Variedad 1', 'Variedad 2', 'Variedad 3', 'Variedad 4'],
  data: [
    {
      name: 'Cuaja Real',
      data: [43934, 48656, 65165, 81827],
    },
    {
      name: 'Cuaja Predicha',
      data: [24916, 37941, 29742, 29851],
    },
  ],
};

const colors = [
  '#FFC24A',
  '#4ACFC0',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',
  '#BBDEFB',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
];

// const regionColors = [
//   { key: '14', color: 'red', value: 10 }, // Ñuble
//   { key: '10', color: 'green', value: 20 }, // Los Lagos
//   { key: '07', color: 'blue', value: 15 }, // Maule
//   { key: '092', color: 'blue', value: 46 }, // Maule
//   // Agrega más regiones según corresponda
// ];

const regionColors = ['#cfebd1', '#c8e6c9', '#a5d6a7', '#81c784', '#66bb6a'];
const provincesColors = ['#388e3c', '#2e7d32', '#1b5e20', '#33691e', '#004d40'];

const endPointFilters = 'v1/industry/filter';

const fixedColors = (data, colors) => {
  const updatedData = data.map((item) => ({
    ...item,
    color: colors[Math.floor(Math.random() * colors.length)], // Selecciona un color aleatorio
  }));

  return updatedData;
};

const Estimates2025 = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const axiosContext = useContext(AxiosContext);

  const chartRef = useRef(null);

  const chartMapRef = useRef(null);

  const errorHandler = useErrorHandler();

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [loadingState, setLoadingState] = useState(false);

  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [fruitSetDataState, setFruitSetDataState] = useState([]);

  const [mapData, setMapData] = useState(regionsGeoJSON); // Carga inicial de regiones
  const [isRegionView, setIsRegionView] = useState(true); // Vista actual (regiones o provincias)
  const [regionName, setRegionName] = useState(null); // Nombre de la región seleccionada

  const theme = useTheme();

  // console.log(theme, 'theme');

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO

  const [filterDataResponseState, setFilterDataResponseState] = useState(null);
  const [regionOptionsState, setRegionOptionsState] = useState([]);
  const [provinceOptionsState, setProvinceOptionsState] = useState([]);
  const [varietyOptionsState, setVarietyOptionsState] = useState([]);

  const useRegion = useWatch({
    control,
    name: 'region',
  });

  const useProvince = useWatch({
    control,
    name: 'province',
  });

  const useVariety = useWatch({
    control,
    name: 'variety',
  });

  const handleRegionClick = (regionKey, regionName) => {
    console.log(regionKey, regionName, 'click func En módulo');

    // Filtrar provincias de la región seleccionada
    const filteredProvinces =
      provincesGeoJSON.objects.simp_prov.geometries.filter(
        (province) => province.properties.CUT_REG === regionKey
      );

    console.log('click func', regionKey, regionName, filteredProvinces);

    if (filteredProvinces.length > 0) {
      setMapData({
        ...provincesGeoJSON,
        objects: {
          simp_prov: {
            type: 'GeometryCollection',
            geometries: filteredProvinces,
          },
        },
      });
      setIsRegionView(false);
      setRegionName(regionName); // Guardar nombre de la región
    } else {
      console.log('No hay provincias para esta región.');
    }
  };

  const handleBackToRegions = () => {
    setMapData(regionsGeoJSON); // Regresar al mapa de regiones
    setIsRegionView(true);
    setRegionName(null);
  };

  const handleSelectRegion = (regionKey) => {
    const chart = chartMapRef.current.chart;

    console.log(chart.series[0].data, '--------------data');
    const point = chart.series[0].data.find((p) => p.key === regionKey);
    if (point) {
      console.log(point, '--------------point');
      point.select();
      point.update({ color: '#FF0000' });
      handleRegionClick(regionKey, point.name);
    }
  };

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      const params = {
        ...dataQuery,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.get('v1/industry/chart', {
        params,
      });

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        console.log(data, 'data-------------------------endpoint');

        const alteredColorsData = {
          ...data,
          regions: fixedColors(data.regions, regionColors),
          provinces: fixedColors(data.provinces, provincesColors),
        };

        setFruitSetDataState(alteredColorsData);
      }
    } catch (err) {
      setFruitSetDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  const onSubmit = async (values) => {
    handleBackToRegions();

    let regionSelected = values.region?.label;
    if (values.province?.label) {
      regionSelected = regionOptionsState[0].label;
    }

    const data = {
      region: regionSelected,
      provincia: values.province?.label,
      varietyValue: values.variety?.value,
    };

    queryData(data);
  };

  useEffect(() => {
    if (
      fruitSetDataState &&
      chartMapRef.current &&
      fruitSetDataState?.provinces?.length > 0
    ) {
      handleSelectRegion(fruitSetDataState.regions[0].key);
    }
  }, [fruitSetDataState, chartMapRef]);

  // useEffect(() => {
  //   if (orchard_filter) {
  //     // console.log(orchard_filter, '<----orchard_filter');
  //     setValue('orchard', [{ value: orchard_filter, label: orchard_filter }]);
  //   }
  // }, []);

  // console.log(errors, 'z----errors');

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='d-flex flex-md-row  align-items-center'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=''>
            <SelectMultipleWidgetFilter
              errors={errors.region}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Región'
              minheight={false}
              // req
              name='region'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={regionOptionsState}
              placeholder='Región'
            />
          </div>
          <div className='ml-md-2'>
            <SelectMultipleWidgetFilter
              errors={errors.province}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Provincia'
              minheight={false}
              // req
              name='province'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={provinceOptionsState}
              placeholder='Provincia'
            />
          </div>
          <div className='ml-md-2'>
            <SelectMultipleWidgetFilter
              errors={errors.variety}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Variedad'
              minheight={false}
              // req
              name='variety'
              display='vertical'
              variant='outlined'
              isMulti={false}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={varietyOptionsState}
              placeholder='Variedad'
            />
          </div>
          <div className='ml-md-2'>
            <LoadingButton
              variant='contained'
              type='submit'
              color='primary'
              aria-label='filter'
              size='medium'
              loading={false}
            >
              <ArrowForwardIosIcon />
            </LoadingButton>
          </div>
        </form>
      </MobileFilters>
    );
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  // FILTERS
  const getFilterAsync = async () => {
    setLoadingFiltersState(true);

    const params = {
      module: 'chart',
    };

    try {
      const response = await axiosContext.authAxios.get(endPointFilters, {
        params,
      });

      const { data, status } = response;

      if (status === 200) {
        console.log(data, 'data-------------------------endpoint filters');
        setFilterDataResponseState(data);
        setRegionOptionsState(alphabeticalSort(data.regions, 'label'));
        setProvinceOptionsState(alphabeticalSort(data.provinces, 'label'));
        setVarietyOptionsState(alphabeticalSort(data.varieties, 'label'));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
  };

  useEffect(() => {
    getFilterAsync();
  }, []);

  useEffect(() => {
    if (filterDataResponseState) {
      let filteredRegions = filterDataResponseState.regions;
      let filteredProvinces = filterDataResponseState.provinces;
      let filteredVarieties = filterDataResponseState.varieties;

      console.log(useRegion, useProvince, useVariety, '-------cambios');

      if (useRegion) {
        filteredProvinces = filterDataResponseState.provinces.filter(
          (province) =>
            province.regions.some((region) => region.value === useRegion.value)
        );
        filteredVarieties = filterDataResponseState.varieties.filter(
          (variety) =>
            variety.regions.some((region) => region.value === useRegion.value)
        );
      }

      if (useProvince) {
        filteredRegions = filterDataResponseState.regions.filter((region) =>
          region.provinces.some(
            (province) => province.value === useProvince.value
          )
        );

        filteredVarieties = filterDataResponseState.varieties.filter(
          (variety) =>
            variety.provinces.some(
              (province) => province.value === useProvince.value
            )
        );
      }

      if (useVariety) {
        filteredRegions = filterDataResponseState.regions.filter((region) =>
          region.varieties.some((variety) => variety.value === useVariety.value)
        );
        filteredProvinces = filterDataResponseState.provinces.filter(
          (province) =>
            province.varieties.some(
              (variety) => variety.value === useVariety.value
            )
        );
      }

      setRegionOptionsState(
        alphabeticalSort(
          filteredRegions.map((region) => ({
            label: region.label,
            value: region.value,
          })),
          'label'
        )
      );
      setProvinceOptionsState(
        alphabeticalSort(
          filteredProvinces.map((province) => ({
            label: province.label,
            value: province.value,
          })),
          'label'
        )
      );
      setVarietyOptionsState(
        alphabeticalSort(
          filteredVarieties.map((variety) => ({
            label: variety.label,
            value: variety.value,
          })),
          'label'
        )
      );
    }
  }, [useRegion, useProvince, useVariety, filterDataResponseState]);

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <CardPaper
              elevation={0}
              sx={{
                borderTop: 0,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              <CardContent className='p-0'>
                <BsRow class=' align-items-center'>
                  <BsCol class='col-md-6'>
                    <Typography variant='h4' component='h2'>
                      Cuajas
                    </Typography>
                  </BsCol>
                  <BsCol class='col-md-6 d-flex justify-content-end'>
                    <Box
                      sx={{
                        // height: '20px',
                        // width: '40px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderFilters()}
                      {/* <Typography
                        variant='h6'
                        sx={{ textAlign: 'right', mt: 2 }}
                      >
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography> */}
                    </Box>
                  </BsCol>
                </BsRow>
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        {loadingState && (
          <div className='form-style'>
            <BsContainer fluid class='px-3 px-md-3'>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-8'>
                  <Skeleton variant='rounded' height={480} />
                </BsCol>
                <BsCol class='col-md-4'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
              </BsRow>
            </BsContainer>
          </div>
        )}

        {!loadingState && fruitSetDataState && (
          <>
            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-8'>
                <CardEstimateIndicators
                  indicators={indicators}
                  indicatorsData={fruitSetDataState}
                />
                <CardPaper elevation={0} variant='outlined' sx={{ mt: 2 }}>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Cuaja real vs Cuaja Predicha
                    </Typography>
                    <HighchartsWrapper
                      fullScreenHandler={openFullscreen}
                      title=''
                    >
                      <ColumnChart
                        data={fruitSetDataState?.fruitSetChart?.data}
                        tooltipFormat='{series.name}: {point.y:,.0f}'
                        showLegend={false}
                        xAxisCategories={
                          fruitSetDataState?.fruitSetChart?.categories
                            ? alterArrayDashDelete(
                                fruitSetDataState?.fruitSetChart?.categories
                              )
                            : []
                        }
                        chartRef={chartRef}
                        enableDataLabels={false}
                        colors={colors}
                        gridLineWidth={0}
                        spacing={[10, 0, 0, 0]}
                        yAxisTitle='Kg Totales' // Titulo del eje Y
                        type='column' // column o bar
                      />
                    </HighchartsWrapper>
                  </CardContent>
                </CardPaper>
              </BsCol>
              <BsCol class='col-md-4'>
                <MapChart
                  height='550'
                  chartRef={chartMapRef}
                  isRegionView={isRegionView}
                  data={mapData}
                  inactiveColor={'#e9eaeb'}
                  borderColor={'#000'}
                  hoverColor={'#FF0000'}
                  spacing={[10, 0, 0, 0]}
                  colors={
                    isRegionView
                      ? fruitSetDataState.regions
                      : fruitSetDataState.provinces
                  }
                  regionName={regionName}
                  tooltipFormat='<b>{point.name}</b><br>Cuaja Real: {point.value:,.1f}<br>Cuaja Estimada: {point.value2:,.1f}'
                  handleClick={(key, name) => handleRegionClick(key, name)}
                />
                {!isRegionView && (
                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button color='secondary' onClick={handleBackToRegions}>
                      Vista Regiones
                    </Button>
                  </Box>
                )}
                {/* <button onClick={() => handleSelectRegion('13')}>
                  Seleccionar 13
                </button>{' '}
                */}
              </BsCol>
            </BsRow>
          </>
        )}
      </BsContainer>
    </div>
  );
};
export default Estimates2025;
