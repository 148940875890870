import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { subDays } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createSvgIcon } from '@mui/material/utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  CustomToggleButton,
  SelectMultipleWidgetFilter,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import {
  alterArrayGetFields,
  alterArrayGetValues,
  arrayGetStationIds,
} from '../../../helpers';
import { FiltersContext } from '../../../context';
import { MobileFilters } from '../../../containers/Library';
import { Context } from '../../../context/Context';
import { weatherStation } from '../../../assets/icons/weather/svg_icons';
import clsx from 'clsx';
import MainAccumulatedCharts from 'containers/Weather/MainAccumulatedCharts/MainAccumulatedCharts';
import DetailOrchardsTable from 'containers/Weather/DetailOrchardsTable';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  tableGreyHeader: {
    '& thead': {
      '& tr:last-child': {
        backgroundColor: theme.palette.grey[30],
      },
      '& tr th:not(:last-child)': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '20%', // Empieza al 20% desde arriba
          height: '60%', // Ocupa 60% de la altura
          width: 1,
          backgroundColor: theme.palette.grey[250],
        },
      },
    },
  },
}));

const alterArrayGetValue = (array) => {
  const newArray = array.map((item) => {
    return item.value;
  });
  return newArray;
};

const options = [
  { label: 'Horas Frío', value: 'coldHour' },
  { label: 'Porciones Frío', value: 'coldPortion' },
  { label: 'Grados Día', value: 'degreeDays' },
  { label: 'Precipitaciones', value: 'precipitations' },
];

const optionsTable = [
  { label: 'Horas Frío', value: 'coldHour' },
  { label: 'Porciones Frío', value: 'coldPortion' },
  { label: 'Grados Día', value: 'degreeDays' },
  { label: 'Precipitaciones', value: 'precipitation' },
];

const WeatherDetail = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")

  const { moduleId } = props;

  const { moduleUser } = useContext(Context);

  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { userOrchard_filter } = filterState;

  // find in userModulesArray the module with the id equal to moduleId
  let userModuleWeather = moduleUser.find((module) => module.id === moduleId);

  // DETERMINA SI VE O NO STATIONS
  const hasStations =
    userModuleWeather && userModuleWeather.process
      ? userModuleWeather.process.some(
          (process) =>
            process.id === '67cf36aa6d6f66ab68043c23' &&
            process.assigned === true
        )
      : false;

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const theme = useTheme();

  console.log(theme, 'theme');

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState] = useState(false);

  const moduleOrchardFilter = 'statusWeather';

  const [filterDataResponseState, setFilterDataResponseState] = useState(
    userOrchard_filter.filter((ele) => ele[moduleOrchardFilter] === true)
  );

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const onSubmit = async (values) => {
    const data = {
      orchards: values.orchard ? alterArrayGetValues(values.orchard) : null,
    };

    if (values.orchard && values.orchard.length > 0) {
      const foundOrchard = userOrchard_filter.find(
        (element) => element.value === alterArrayGetValues(values.orchard)[0]
      );

      addFilter({
        orchard_filter: foundOrchard?.value,
        orchard_id_filter: foundOrchard?.id,
        quarter_filter: null,
        cc_filter: null,
        variety_filter: null,
        rootstock_filter: null,
        idUnitProductive: null,
      });
    } else {
      addFilter({
        orchard_filter: null,
        orchard_id_filter: null,
        quarter_filter: null,
        cc_filter: null,
        variety_filter: null,
        rootstock_filter: null,
        idUnitProductive: null,
      });
    }

    setInfoFilterTab(data);
  };

  // useEffect(() => {
  //   if (orchard_filter) {
  //     // console.log(orchard_filter, '<----orchard_filter');
  //     setValue('orchard', [{ value: orchard_filter, label: orchard_filter }]);
  //   }
  // }, []);

  // console.log(errors, 'z----errors');

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='d-flex flex-column flex-md-row align-items-md-center'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='mb-3 mb-md-0'>
            <SelectMultipleWidgetFilter
              errors={errors.orchard}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Huertos'
              minheight={false}
              // req
              name='orchard'
              display='vertical'
              variant='outlined'
              isMulti={true}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={filterDataResponseState}
              placeholder='Huerto/s'
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <LoadingButton
              variant='contained'
              type='submit'
              color='primary'
              aria-label='filter'
              size='medium'
              loading={false}
            >
              <ArrowForwardIosIcon />
            </LoadingButton>
          </div>
        </form>
      </MobileFilters>
    );
  };

  const [selectedValue, setSelectedValue] = useState('coldHour');

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
    // Aquí puedes agregar cualquier lógica adicional
  };

  const [selectedTableValue, setSelectedTableValue] = useState('coldHour');

  const handleTableChange = (newValue) => {
    setSelectedTableValue(newValue);
    // Aquí puedes agregar cualquier lógica adicional
  };

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <CardPaper
              elevation={0}
              sx={{
                borderTop: 0,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              <CardContent className='p-0'>
                <BsRow class=' align-items-center'>
                  <BsCol class='col-md-6'>
                    <Typography variant='h4' component='h2'>
                      Comparativo Huertos
                    </Typography>
                  </BsCol>
                  <BsCol class='col-lg-6 d-md-flex justify-content-md-end'>
                    <Box
                      sx={{
                        // height: '20px',
                        // width: '40px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderFilters()}
                      <Typography
                        variant='h6'
                        sx={{ textAlign: 'right', mt: 2 }}
                      >
                        {new Date().toLocaleDateString('es-ES', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </Typography>
                    </Box>
                  </BsCol>
                </BsRow>
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>
        <BsRow class='gutter_10 mb-3'>
          <BsCol class='col-md-12'>
            <CardPaper elevation={0} variant='outlined'>
              <CardContent className='p-3'>
                <Typography gutterBottom variant='h4' component='h2'>
                  Acumulados
                </Typography>
                <Typography gutterBottom variant='p' component='p'>
                  Acumulado por temporadas
                </Typography>
                <CustomToggleButton
                  options={options}
                  value={selectedValue}
                  onChange={handleChange}
                  ariaLabel='weather metrics'
                  size='sm'
                  buttonActiveColor={theme.palette.weather.forecast.background}
                  buttonActiveFontColor={theme.palette.secondary.main}
                />
                <MainAccumulatedCharts
                  filter={{
                    orchards: infoFilterTab?.orchards,
                    graph: selectedValue,
                  }}
                />
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        <BsRow class='gutter_10 mb-3'>
          <BsCol class='col-md-12'>
            <CardPaper elevation={0} variant='outlined'>
              <CardContent className='p-3'>
                <Typography gutterBottom variant='h4' component='h2'>
                  Detalle Mensual
                </Typography>
                <Typography gutterBottom variant='p' component='p'>
                  Acumulativo de los agroindicadores mensual
                </Typography>
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', my: 1 }}
                >
                  <CustomToggleButton
                    options={optionsTable}
                    size='sm'
                    value={selectedTableValue}
                    onChange={handleTableChange}
                    buttonActiveColor={
                      theme.palette.weather.forecast.background
                    }
                    buttonActiveFontColor={theme.palette.secondary.main}
                  />
                </Box>

                <DetailOrchardsTable
                  orchards={infoFilterTab?.orchards}
                  type={selectedTableValue}
                  classTable={classes.tableGreyHeader}
                />
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        {hasStations ? (
          <BsRow class='gutter_10 mb-3'>
            <BsCol class='col-md-12'>
              <CardPaper elevation={0} variant='outlined'>
                <CardContent className='p-3'>
                  <Typography gutterBottom variant='h4' component='h2'>
                    Comparativo Estación
                  </Typography>
                  <Typography gutterBottom variant='p' component='p'>
                    Comparativo entre estaciones climáticas
                  </Typography>
                  <table
                    className={clsx(
                      'table table-borderless mt-3',
                      classes.tableGreyHeader
                    )}
                  >
                    <thead>
                      <tr>
                        <th scope='col'>Estación</th>
                        <th scope='col'>P. Frío</th>
                        <th scope='col'>H. Frío</th>
                        <th scope='col'>P.p</th>
                        <th scope='col'>G. Día</th>
                        <th scope='col'>Diferencia</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Estación</td>
                        <td>200</td>
                        <td>200</td>
                        <td>200</td>
                        <td>200</td>
                        <td>200</td>
                      </tr>
                    </tbody>
                  </table>
                </CardContent>
              </CardPaper>
            </BsCol>
          </BsRow>
        ) : null}

        {/* <BsRow class='no-gutters'>
          <BsCol class='col-md-12'>
            <ResumeOrchard filter={infoFilterTab} />
          </BsCol>
        </BsRow> */}
      </BsContainer>
    </div>
  );
};
export default WeatherDetail;
