import { Button, Divider, Menu, MenuItem, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import dialogMessages from '../../common/helpLinks.json';

const HelpOptionsButton = (props) => {
  const { module, process, joyrideFunc, ...others } = props;

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const clickHelpButton = (type) => {
    if (type === 'joyride') {
      joyrideFunc();
      handleMouseLeave();
    } else if (type === 'pdf') {
      console.log('pdf');
      handleMouseLeave();
    } else if (type === 'video') {
      console.log('video');
      handleMouseLeave();
    }
  };

  return (
    <>
      <Button
        size='small'
        sx={{
          color: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          '&:hover': {
            borderColor: theme.palette.grey[300],
          },
        }}
        variant='outlined'
        startIcon={<InfoOutlinedIcon />}
        onMouseEnter={handleMouseEnter}
        aria-owns={open ? 'hover-menu' : undefined}
        aria-haspopup='true'
      >
        Ayuda
      </Button>
      <Menu
        id='hover-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleMouseLeave}
        MenuListProps={{
          onMouseLeave: handleMouseLeave,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => clickHelpButton('joyride')}>
          Paso a paso
        </MenuItem>
        {dialogMessages[module]?.[process]?.pdf ? (
          <>
            <Divider />
            <MenuItem onClick={() => clickHelpButton('pdf')}>PDF</MenuItem>
          </>
        ) : null}
        {dialogMessages[module]?.[process]?.video ? (
          <>
            <Divider />
            <MenuItem onClick={() => clickHelpButton('video')}>Video</MenuItem>
          </>
        ) : null}
      </Menu>
    </>
  );
};

export default HelpOptionsButton;
