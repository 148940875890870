import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsCol, BsContainer, BsRow } from '../../../layouts/components';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1, 3, 3),
    width: '90%',
    height: '87vh',
  },
  scrollAreaModalStyle: {
    overflow: 'auto',
    maxHeight: '95%',
    // backgroundColor: 'yellow',
  },
  divider: {
    background: theme.palette.grey[400],
    marginTop: 10,
  },
  root: {
    color: theme.palette.tertiary.main,
    fontWeight: 500,
  },
  ulListStyle: {
    '& li': {
      listStyle: 'inside',
      marginBottom: 8,
      fontSize: 15,
    },
  },
}));

const ModalDetail = ({ closeAction, dataModal, dataColumn }) => {
  const classes = useStyles();
  const [arrayDataState, setArrayDataState] = useState([]);
  const [dataFirstColState, setDataFirstColState] = useState([]);
  const [dataSecondColState, setDataSecondColState] = useState([]);

  let dataFirstCol = 10;

  console.log(dataModal, '------------', dataColumn);

  const renderDataCol = () => {
    let arrayColumn = [];
    _.mapKeys(dataModal, function (value, key) {
      const found = dataColumn.find((ele) => ele.field === key);
      // se queda con los que existen como columna
      if (found) {
        return arrayColumn.push({ key, value });
      }
    });
    setArrayDataState(arrayColumn);
  };

  useEffect(() => {
    renderDataCol();
  }, []);

  // console.log(arrayDataState, '--------arrayDataState');

  // let half;
  // let firstHalfArray = [];
  // let secondHalfArray = [];

  // console.log(dataSecondColState[7], '----------------');
  // let colfil = dataColumn.filter(
  // 	(ele) => ele.accessorKey === dataSecondColState[7].key
  // );
  // console.log(colfil[0], '<<----colfil');
  // console.log(
  // 	colfil[0].Cell({ renderedCellValue: dataSecondColState[7].value }),
  // 	'<<----cell function'
  // );
  const halfRef = useRef();
  const firstHalfRef = useRef();
  const secondHalfRef = useRef();

  useEffect(() => {
    if (arrayDataState.length) {
      halfRef.current = Math.ceil(arrayDataState.length / 2);
      firstHalfRef.current = arrayDataState.slice(0, halfRef.current);
      secondHalfRef.current = arrayDataState.slice(halfRef.current);

      console.log(firstHalfRef.current, '----', secondHalfRef.current);

      setDataFirstColState(firstHalfRef.current);
      setDataSecondColState(secondHalfRef.current);
    }
  }, [arrayDataState]);

  const rowData = {
    original: dataModal,
  };

  return (
    <div className={classes.paperModal}>
      <div>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12 text-right pr-0'>
              <Button onClick={closeAction}>
                <CloseIcon />
              </Button>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
      <div className={classes.scrollAreaModalStyle}>
        <BsContainer fluid>
          <BsRow>
            <BsCol class='col-md-12'>
              <Typography variant='h5' gutterBottom>
                Detalle Fila
              </Typography>
              {/* <p>{JSON.stringify(dataModal)}</p> */}
              <BsRow class='mt-4'>
                <BsCol class='col-lg-6'>
                  <table className='table table-sm'>
                    <tbody>
                      {dataFirstColState.length > 0 &&
                        dataFirstColState.map((key) => {
                          const col = dataColumn.filter(
                            (ele) => ele.field === key.key
                          );

                          {
                            /* console.log(col, '-------<-<<< COL'); */
                          }

                          if (col[0]) {
                            {
                              /* console.log(col[0], '-------<-<<<'); */
                            }
                            let value = col[0].valueFormatter
                              ? col[0].valueFormatter({
                                  data: rowData,
                                  value: key.value,
                                })
                              : key.value;

                            if (value === '-') {
                              return null;
                            } else {
                              return (
                                <tr key={key.key}>
                                  <td
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {col.length
                                      ? col[0].headerName
                                      : 'No Label'}
                                  </td>
                                  <td>
                                    <b>{value}</b>
                                  </td>
                                </tr>
                              );
                            }
                          } else {
                            return (
                              <tr>
                                <td></td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </BsCol>
                {/* col 2 */}
                <BsCol class='col-lg-6'>
                  <table className='table table-sm'>
                    <tbody>
                      {dataSecondColState.length > 0 &&
                        dataSecondColState.map((key) => {
                          const col = dataColumn.filter(
                            (ele) => ele.field === key.key
                          );
                          if (col[0]) {
                            let value = col[0].valueFormatter
                              ? col[0].valueFormatter({
                                  data: rowData,
                                  value: key.value,
                                })
                              : key.value;

                            if (value === '-') {
                              return null;
                            } else {
                              return (
                                <tr key={key.key}>
                                  <td
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {col.length
                                      ? col[0].headerName
                                      : 'No Label'}
                                  </td>
                                  <td>
                                    <b>{value}</b>
                                  </td>
                                </tr>
                              );
                            }
                          } else {
                            return (
                              <tr>
                                <td></td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </BsCol>
              </BsRow>
            </BsCol>
          </BsRow>
        </BsContainer>
      </div>
    </div>
  );
};

export default ModalDetail;
