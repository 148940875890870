import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { numberFormatDecimals } from 'helpers';

const BalanceIndicator = ({ data }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.irrigation.balanceIndicators.background,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'left',
        borderRadius: `${theme.shape.borderRadius}px`,
        padding: 3,
      }}
    >
      <Typography variant='body2' component='h4' gutterBottom>
        {data.title}
      </Typography>
      <Typography
        variant='h3'
        component='h3'
        gutterBottom
        sx={{ fontWeight: 600, color: theme.palette.grey[500] }}
      >
        {numberFormatDecimals(data.data, data.decimals)} {data.sufix}
      </Typography>
    </Box>
  );
};

export default BalanceIndicator;
