import {
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardPaper, BalanceIndicator } from 'components';

const CardOrchardGeneralBalance = ({
  indicatorsData,
  indicators,
  title = '',
  description = '',
}) => {
  const theme = useTheme();

  console.log(
    indicators,
    '<-----------------agroIndicators---------------',
    indicatorsData
  );

  return (
    <CardPaper elevation={0} variant='outlined'>
      <CardContent className='p-3'>
        <Typography gutterBottom variant='h4' component='h2'>
          {title}
        </Typography>
        <Typography gutterBottom variant='p' component='p'>
          {description}
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          className='my-2'
          columns={{ sm: 4, md: 12 }}
        >
          {indicators.map((indi) => (
            <Grid item sm={2} md={3} key={indi.value}>
              <BalanceIndicator
                data={{
                  title: indi.label,
                  key: indi.value,
                  data: indicatorsData[indi.value],
                  decimals: indi.value === 'lossPercentage' ? 1 : 0,
                  sufix: indi.value === 'lossPercentage' ? '%' : '',
                }}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </CardPaper>
  );
};

export default CardOrchardGeneralBalance;
