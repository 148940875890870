// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useErrorHandler } from 'react-error-boundary';
import { subDays, addDays } from 'date-fns';
import { Box, useTheme } from '@mui/material';

// Internal modules
import { AxiosContext } from '../../../../context/AxiosContext';
import { Context } from '../../../../context/Context';
import { yearGraphColors } from '../../../../theme/palette';

// Components
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../../components';

// Functions, constants, etc.
import {
  alterArrayGetVariety,
  dateFormatIso,
  dateFormatIsoLocale,
  generalOptionsHighCharts,
  numberFormat,
  numberFormatDecimals,
} from '../../../../helpers';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);

const DegreeDays = ({ orchard: orchardProp }) => {
  const chartRef = useRef(null);

  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const actualYear = new Date().getFullYear();

  const minYearDate = new Date(actualYear, 0, 1);
  const maxYearDate = new Date(actualYear, 11, 31);

  const minDegreeDate = `${actualYear}-08-02`;
  const maxDegreeDate = `${actualYear}-12-31`;

  // definir start date evaluando si el día de hoy es mayor a la fecha mínima
  const startDegreeDate =
    new Date() > new Date(minDegreeDate) ? new Date(minDegreeDate) : new Date();
  // definir end date evaluando si el día de hoy es mayor a la fecha máxima
  const endDegreeDate =
    new Date() > new Date(maxDegreeDate)
      ? new Date(maxDegreeDate)
      : addDays(new Date(minDegreeDate), 7);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const [loadingState, setLoadingState] = useState(false);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [minDateState, setminDateState] = useState(null);
  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const highchartsOptions = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 300,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      // pointFormat: '{point.y:.2f}',
      shared: true,
      split: true,
      pointFormatter() {
        return this.series.name + ': ' + numberFormat(this.y) + ' Gdo. día';
      },
    },
    xAxis: {
      categories: graphOptionsState?.date,
      crosshair: true,
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: '{value}',
          style: {
            color: 'rgb(208,194,161)',
          },
        },
        title: {
          text: 'Día Grado Acumulado',
          style: {
            color: 'rgb(208,194,161)',
          },
        },
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Día Grado Diario',
          style: {
            color: 'rgb(61,72,77)',
          },
        },
        opposite: true,
        labels: {
          format: '<br/>{value}',
          style: {
            color: 'rgb(61,72,77)',
          },
        },
      },
    ],
    // legend: {
    //   layout: 'vertical',
    //   align: 'left',
    //   x: 80,
    //   verticalAlign: 'top',
    //   y: -20,
    //   floating: true,
    //   backgroundColor:
    //     Highcharts.defaultOptions.legend.backgroundColor || // theme
    //     'rgba(255,255,255,0.25)',
    // },
    colors: theme.palette.weather.charts.daily.degree,
    // series: performanceReverseDataState ? performanceReverseDataState : [],
    series: [
      {
        name: 'Día Grado Diario',
        type: 'column',
        yAxis: 1,
        data: graphOptionsState?.degreeDays?.data,
        tooltip: {
          valueSuffix: '',
          valueDecimals: 2,
        },
      },
      {
        name: 'Día Grado Acumulado',
        type: 'spline',
        data: graphOptionsState?.degreeDaysAccumulated?.data,
        tooltip: {
          valueSuffix: '',
          valueDecimals: 2,
        },
      },
      {
        name: 'Acumuladas Año Anterior',
        type: 'spline',
        data: graphOptionsState?.degreeDaysAccumulatedLastYear?.data,
        tooltip: {
          valueSuffix: '',
          valueDecimals: 2,
        },
      },
    ],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Día Grado',
    },
  };

  const openFullscreen = () => {
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      //   TODO: ver cuando quiero ver mis huertos
      const { orchard, sinceDate, untilDate } = dataQuery;

      const objQuery = {
        orchard: orchard,
        sinceDate,
        untilDate,
        future: false,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/weather/weather-degreeDays',
        objQuery
      );

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setGraphOptionsState(data);
      }
      setLoadingState(false);
    } catch (err) {
      setGraphOptionsState([]);
      setLoadingState(false);
      errorHandler(err);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = values.since;
    const dateEnd = values.until;

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      orchard: orchardProp,
      sinceDate: dateFormatIsoLocale(dateBegin),
      untilDate: dateFormatIsoLocale(dateEnd),
    };

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    if (orchardProp) {
      handleSubmit(onSubmit)();
    }
  }, [orchardProp]);

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className='mb-2'
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <form
            className='form-inline'
            autoComplete='on'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='since'
                labeltext='Desde'
                placeholder='Desde'
                display='vertical'
                variant='outlined'
                format='dd-MM'
                style={{}}
                minheight={false}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                control={control}
                errors={errors.since}
                defaultValue={startDegreeDate}
                minDate={minYearDate}
                maxDate={maxYearDate}
              />
            </div>
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='until'
                labeltext='Hasta'
                placeholder='Hasta'
                display='vertical'
                variant='outlined'
                format='dd-MM'
                style={{}}
                minheight={false}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                maxDate={maxYearDate}
                minDate={minDateState}
                control={control}
                errors={errors.until}
                defaultValue={endDegreeDate}
              />
            </div>
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='medium'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={false}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </form>
          {errors.incorrectDates && (
            <p
              style={{
                color: theme.palette.error.main,
              }}
            >
              {errors.incorrectDates.message}
            </p>
          )}
        </Box>
      </div>
      {loadingState && <LoadingWidget />}
      {!loadingState && graphOptionsState && (
        <HighchartsWrapper fullScreenHandler={openFullscreen} title=''>
          <HighchartsReact
            highcharts={Highcharts}
            options={highchartsOptions}
            ref={chartRef}
          />
        </HighchartsWrapper>
      )}
    </>
  );
};

export default DegreeDays;
