import React from 'react';
import {
  Avatar,
  Box,
  Paper,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { numberFormatDecimals } from 'helpers';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
}));

const WeatherTableIndicator = ({ data, active = false, noData = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  if (noData) {
    return (
      <Paper elevation={active ? 2 : 0} sx={{ py: 2, px: 1 }}>
        <Typography
          variant='body2'
          component='p'
          className={classes.indicadorText}
        >
          Temp. Aire Máx
        </Typography>
        <Typography
          variant='body2'
          className={classes.indicadorText}
          component='p'
        >
          Temp. Aire Min
        </Typography>
        <Typography
          variant='body2'
          className={classes.indicadorText}
          component='p'
        >
          Pp. Acumulada
        </Typography>
        <Typography
          variant='body2'
          className={classes.indicadorText}
          component='p'
        >
          Radiación Solar
        </Typography>
        <Typography
          variant='body2'
          className={classes.indicadorText}
          component='p'
        >
          Velocidad Viento
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={active ? 2 : 0} sx={{ py: 2, px: 1 }}>
      <Typography
        variant='body2'
        component='p'
        className={classes.indicadorText}
      >
        {numberFormatDecimals(data?.tempMax, 1)}°
      </Typography>
      <Typography
        variant='body2'
        className={classes.indicadorText}
        component='p'
      >
        {numberFormatDecimals(data?.tempMin, 1)}°
      </Typography>
      <Typography
        variant='body2'
        className={classes.indicadorText}
        component='p'
      >
        {data?.precipitation} mm
      </Typography>
      <Typography
        variant='body2'
        className={classes.indicadorText}
        component='p'
      >
        {numberFormatDecimals(data?.radiation, 1)} W/m2
      </Typography>
      <Typography
        variant='body2'
        className={classes.indicadorText}
        component='p'
      >
        {numberFormatDecimals(data?.windspeed, 1)} km/h
      </Typography>
    </Paper>
  );
};

export default WeatherTableIndicator;
