import React, { useState, useRef, useContext, useEffect } from 'react';
import _, { difference } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Icon,
  IconButton,
  Skeleton,
  SvgIcon,
  Table,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import {
  CardPaper,
  HighchartsWrapper,
  SelectMultipleWidgetFilter,
  TextFieldWidgetDateController2,
  TextFieldWidgetDateRangeController2,
} from '../../../components';

import { BsRow, BsCol, BsContainer } from '../../../layouts/components';
import { FiltersContext } from '../../../context';
import { Context } from '../../../context/Context';
import { AxiosContext } from '../../../context/AxiosContext';
import { MobileFilters } from '../../../containers/Library';
import clsx from 'clsx';
import { ColumnChart } from 'components/Charts';
import CardOrchardGeneralBalance from 'containers/Irrigation/CardOrchardGeneralBalance';
import {
  alterArrayGetValues,
  dashDelete,
  dateFormatFromCalendar,
  getUniqueValuesMaster,
  numberFormatDecimals,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
  indicadorText: {
    fontWeight: '600',
    marginBottom: 15,
    color: theme.palette.text.secondary,
  },
  tableGreyHeader: {
    '& thead': {
      backgroundColor: theme.palette.grey[30],
      '& tr th:not(:last-child)': {
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: '20%', // Empieza al 20% desde arriba
          height: '60%', // Ocupa 60% de la altura
          width: 1,
          backgroundColor: theme.palette.grey[250],
        },
      },
    },
    '& tbody tr td': {
      fontSize: 14,
      padding: '.65rem',
    },
  },
  tableIndicators: {
    '& th': {
      fontSize: 14,
      fontWeight: 400,
    },
    '& td': {
      color: theme.palette.text.primary,
    },
  },
}));

const indicatorsValues = [
  {
    label: 'Volumen Aplicado',
    value: 'appliedVolume',
  },
  {
    label: 'Volumen Programado',
    value: 'programmedVolume',
  },
  {
    label: 'Suma de Pérdida',
    value: 'lossVolume',
  },
  {
    label: 'Porcentaje de Fuga',
    value: 'lossPercentage',
  },
];

const lossData = {
  categories: ['05-03', '06-03', '07-03', '08-03', '09-03', '10-03'],
  data: [
    {
      name: 'Volumen Equipo',
      data: [65.5, 50.8, 80.3, 60.5, 70.4, 90.5],
      stack: 'Sep 2024',
    },
    {
      name: 'Volumen de Fuga',
      data: [12, 14, 10, 12, 13, 15],
      stack: 'Sep 2024',
    },
  ],
};

const colors = [
  '#75AEE5',
  '#345EB5',
  '#E1BEE7',
  '#D1C4E9',
  '#C5CAE9',
  '#BBDEFB',
  '#B3E5FC',
  '#B2EBF2',
  '#B2DFDB',
];

const endPointFilters = 'v1/irrigation/filter';

/**
 * Flattens the endpoint data by iterating through the nested structure and creating a flat array of objects.
 * @param {Array} data - The data to be flattened.
 * @returns {Array} - The flattened data.
 */
function flattenEndpointDataHandler(data) {
  const result = [];

  data.forEach((orchard) => {
    orchard.irrigationShed.forEach((irrigationShed) => {
      irrigationShed.machine.forEach((machine) => {
        result.push({
          orchard: {
            label: dashDelete(orchard.orchardValue),
            value: orchard.orchardValue,
          },
          irrigationShed: {
            label: irrigationShed.value,
            value: irrigationShed.value,
          },
          machine: {
            label: machine.value,
            value: machine.value,
          },
        });
      });
    });
  });

  return result;
}

const ByError = (props) => {
  // console.log(userId, userHuerto, userName, "<<<<---context")
  const { state: filterState, addFilter } = useContext(FiltersContext);

  const { orchard_filter, orchard_id_filter, userOrchard_filter } = filterState;

  const axiosContext = useContext(AxiosContext);

  const { userOrchard, userClient } = useContext(Context);

  const chartRef = useRef(null);

  const chartMapRef = useRef(null);

  const errorHandler = useErrorHandler();

  const [infoFilterTab, setInfoFilterTab] = useState(null);

  const [loadingState, setLoadingState] = useState(false);

  const [irrigationDataState, setIrrigationDataState] = useState(null);

  const theme = useTheme();

  const [minDateState, setminDateState] = useState(null);

  const actualYear = new Date().getFullYear();

  const minColdDate = `${actualYear}-04-02`;
  const maxColdDate = `${actualYear}-07-31`;

  // definir start date evaluando si el día de hoy es mayor a la fecha mínima
  const startColdDate =
    new Date() < new Date(minColdDate) ? new Date() : new Date(minColdDate);
  // definir end date evaluando si el día de hoy es mayor a la fecha máxima
  const endColdDate =
    new Date() > new Date(maxColdDate) ? new Date(maxColdDate) : new Date();

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // -------FILTERS NUEVO
  //  loading general
  const [loadingFiltersState, setLoadingFiltersState] = useState(false);

  const [filtersFlattenDataState, setFiltersFlattenDataState] = useState([]);

  const [filterDataResponseState, setFilterDataResponseState] = useState([]);

  const [irrigationShedOptionsState, setIrrigationShedOptionsState] = useState(
    []
  );

  const [machineOptionsState, setMachineOptionsState] = useState([]);

  const useOrchard = useWatch({
    control,
    name: 'orchard',
  });

  const useShed = useWatch({
    control,
    name: 'shed',
  });

  const useMachine = useWatch({
    control,
    name: 'machine',
  });

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const endpointName = 'v1/irrigation/balance';

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      const bodyObj = {
        // clientValue: userClient?.value,
        orchard: dataQuery?.orchards?.length
          ? dataQuery?.orchards
          : userOrchard,
        irrigationShed: dataQuery?.irrigationShed,
        machine: dataQuery?.machine,
        dateBegin: dateFormatFromCalendar(dataQuery.dateBegin),
        dateEnd: dateFormatFromCalendar(dataQuery.dateEnd),
      };

      // bodyObj.irrigationShed === null && delete bodyObj.irrigationShed;
      // bodyObj.machine === null && delete bodyObj.machine;

      const response = await axiosContext.authAxios.post(endpointName, bodyObj);

      // let data = true;

      const { data, status } = response;

      if (status === 200) {
        console.log(data, 'data--------------endpoint');

        setIrrigationDataState({
          indicatorsData: {
            appliedVolume: data?.appliedVolume,
            programmedVolume: data?.programmedVolume,
            lossVolume: data?.lossVolume,
            lossPercentage: data?.lossPercentage,
          },
          chart: data?.chartOrchard,
          orchardTable: data?.table,
        });
      }
    } catch (err) {
      setIrrigationDataState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = new Date(values.since);
    const dateEnd = new Date(values.until);

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      orchards: values.orchard ? alterArrayGetValues(values.orchard) : null,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
      irrigationShed: values.shed ? alterArrayGetValues(values.shed) : null,
      machine: values.machine ? alterArrayGetValues(values.machine) : null,
    };

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    clearErrors('incorrectDates');
  }, [watchUntil]);

  const dateSetterHandler = (type) => {
    const date = new Date();
    let dateSince = new Date();
    let dateUntil = new Date();

    if (type === 'week') {
      dateSince = new Date(date.setDate(date.getDate() - 7));
    } else if (type === 'month') {
      dateSince = new Date(date.setMonth(date.getMonth() - 1));
    }

    setValue('since', dateSince);
    setValue('until', dateUntil);
  };

  const renderFilters = () => {
    return (
      <MobileFilters>
        <form
          className='d-flex flex-column flex-md-row align-items-md-center'
          autoComplete='on'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='mb-3 mb-md-0'>
            <SelectMultipleWidgetFilter
              errors={errors.orchard}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Huerto'
              minheight={false}
              // req
              name='orchard'
              display='vertical'
              variant='outlined'
              isMulti={true}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={filterDataResponseState}
              placeholder='Huerto'
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <SelectMultipleWidgetFilter
              errors={errors.shed}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Caseta'
              minheight={false}
              // req
              name='shed'
              display='vertical'
              variant='outlined'
              isMulti={true}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={irrigationShedOptionsState}
              placeholder='Caseta'
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <SelectMultipleWidgetFilter
              errors={errors.machine}
              loading={loadingFiltersState}
              control={control}
              menuPortalTarget={document.body}
              labeltext='Equipo'
              minheight={false}
              // req
              name='machine'
              display='vertical'
              variant='outlined'
              isMulti={true}
              isClearable={true}
              isSearchable
              onlyOptionValue={false}
              defaultValue=''
              options={machineOptionsState}
              placeholder='Equipo'
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <TextFieldWidgetDateController2
              name='since'
              labeltext='Desde'
              placeholder='Desde'
              display='vertical'
              variant='outlined'
              format='dd-MM-yyyy'
              style={{}}
              minheight={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '150px',
              }}
              // value={sinceSelectedState}
              // onChange={date => setSinceSelectedState(date)}
              control={control}
              errors={errors.since}
              defaultValue={startColdDate}
            />
          </div>
          <div className='mb-3 mb-md-0 ml-md-2'>
            <TextFieldWidgetDateController2
              name='until'
              labeltext='Hasta'
              placeholder='Hasta'
              display='vertical'
              variant='outlined'
              format='dd-MM-yyyy'
              style={{}}
              minheight={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '150px',
              }}
              // value={sinceSelectedState}
              // onChange={date => setSinceSelectedState(date)}
              minDate={minDateState}
              control={control}
              errors={errors.until}
              defaultValue={endColdDate}
            />
          </div>
          <div className='ml-md-2'>
            <LoadingButton
              variant='contained'
              type='submit'
              color='primary'
              aria-label='filter'
              size='medium'
              loading={false}
            >
              <ArrowForwardIosIcon />
            </LoadingButton>
          </div>
        </form>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='text'
            size='small'
            sx={{ mr: 1 }}
            onClick={() => dateSetterHandler('month')}
          >
            Último mes
          </Button>
          <Button
            variant='text'
            size='small'
            onClick={() => dateSetterHandler('week')}
          >
            Última semana
          </Button>
        </Box>
        {errors.incorrectDates && (
          <p
            style={{
              color: theme.palette.error.main,
            }}
          >
            {errors.incorrectDates.message}
          </p>
        )}
      </MobileFilters>
    );
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  // FILTROS
  const getFilterAsync = async () => {
    setLoadingFiltersState(true);
    const params = {};

    try {
      const response = await axiosContext.authAxios.get(
        endPointFilters,
        params
      );

      // console.log(response.data, '<<<<<------responde nuevo end------');

      const { data, status } = response;

      if (status === 200) {
        const flattenEndpointData = flattenEndpointDataHandler(data);

        console.log(flattenEndpointData, 'flattenEndpointData');

        setFiltersFlattenDataState(flattenEndpointData);

        const orchardArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.orchard,
          };
        });

        const uniqueOrchard = getUniqueValuesMaster(
          orchardArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueOrchard.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setFilterDataResponseState(uniqueOrchard);

        // cargar con valores únicos
        const irrigationShedArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.irrigationShed,
          };
        });

        const uniqueIrrigationShed = getUniqueValuesMaster(
          irrigationShedArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueIrrigationShed.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setIrrigationShedOptionsState(uniqueIrrigationShed);

        const machineArray = flattenEndpointData.map((ele) => {
          return {
            ...ele.machine,
          };
        });

        const uniqueMachine = getUniqueValuesMaster(
          machineArray,
          'value',
          ['label', 'value'],
          ['label', 'value']
        );

        uniqueMachine.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        setMachineOptionsState(uniqueMachine);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingFiltersState(false);
    }
    // setDataFilterState(MOCK_FILTER_DATA);
  };

  useEffect(() => {
    getFilterAsync();
  }, []);

  // UseEffect para cambios de filtros
  // useEffect(() => {
  //   console.log(useOrchard, useShed, useMachine, 'filter selections');

  //   const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
  //     // Check if array exists and has elements
  //     const hasOrchardFilter = useOrchard && useOrchard.length > 0;
  //     const hasShedFilter = useShed && useShed.length > 0;
  //     const hasMachineFilter = useMachine && useMachine.length > 0;

  //     // Apply filters based on array inclusion
  //     const orchardFilter = hasOrchardFilter
  //       ? useOrchard.some((selected) => selected.value === ele?.orchard.value)
  //       : true;
  //     const shedFilter = hasShedFilter
  //       ? useShed.some(
  //           (selected) => selected.value === ele?.irrigationShed.value
  //         )
  //       : true;
  //     const machineFilter = hasMachineFilter
  //       ? useMachine.some((selected) => selected.value === ele?.machine.value)
  //       : true;

  //     return orchardFilter && shedFilter && machineFilter;
  //   });

  //   console.log(filterUpdatedData, '-------filterUpdatedData');

  //   // Process orchard options
  //   const orchardArray = filterUpdatedData.map((ele) => {
  //     return {
  //       ...ele.orchard,
  //     };
  //   });

  //   const uniqueOrchard = getUniqueValuesMaster(
  //     orchardArray,
  //     'value',
  //     ['label', 'value'],
  //     ['label', 'value']
  //   );

  //   uniqueOrchard.sort((a, b) => {
  //     if (a.label < b.label) {
  //       return -1;
  //     }
  //     if (a.label > b.label) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  //   setFilterDataResponseState(uniqueOrchard);

  //   // Process irrigation shed options
  //   const irrigationShedArray = filterUpdatedData.map((ele) => {
  //     return {
  //       ...ele.irrigationShed,
  //     };
  //   });

  //   const uniqueIrrigationShed = getUniqueValuesMaster(
  //     irrigationShedArray,
  //     'value',
  //     ['label', 'value'],
  //     ['label', 'value']
  //   );

  //   uniqueIrrigationShed.sort((a, b) => {
  //     if (a.label < b.label) {
  //       return -1;
  //     }
  //     if (a.label > b.label) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  //   setIrrigationShedOptionsState(uniqueIrrigationShed);

  //   // Process machine options
  //   const machineArray = filterUpdatedData.map((ele) => {
  //     return {
  //       ...ele.machine,
  //     };
  //   });

  //   const uniqueMachine = getUniqueValuesMaster(
  //     machineArray,
  //     'value',
  //     ['label', 'value'],
  //     ['label', 'value']
  //   );

  //   uniqueMachine.sort((a, b) => {
  //     if (a.label < b.label) {
  //       return -1;
  //     }
  //     if (a.label > b.label) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  //   setMachineOptionsState(uniqueMachine);
  // }, [useOrchard, useShed, useMachine, filtersFlattenDataState]);

  useEffect(() => {
    console.log(useOrchard, useShed, useMachine, 'filter selections');

    const filterUpdatedData = filtersFlattenDataState.filter((ele) => {
      // Check if array exists and has elements
      const hasOrchardFilter = useOrchard && useOrchard.length > 0;

      // Apply filters based on array inclusion
      const orchardFilter = hasOrchardFilter
        ? useOrchard.some((selected) => selected.value === ele?.orchard.value)
        : true;

      return orchardFilter;
    });

    // Process irrigation shed options
    const irrigationShedArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.irrigationShed,
      };
    });

    const uniqueIrrigationShed = getUniqueValuesMaster(
      irrigationShedArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueIrrigationShed.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setIrrigationShedOptionsState(uniqueIrrigationShed);

    // Process machine options
    const machineArray = filterUpdatedData.map((ele) => {
      return {
        ...ele.machine,
      };
    });

    const uniqueMachine = getUniqueValuesMaster(
      machineArray,
      'value',
      ['label', 'value'],
      ['label', 'value']
    );

    uniqueMachine.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    setMachineOptionsState(uniqueMachine);
  }, [useOrchard, useShed, useMachine, filtersFlattenDataState]);

  // console.log(selectedValueDetailState, '----selectedValueDetailState');

  return (
    <div className='form-style'>
      <BsContainer fluid class='px-3 px-md-3'>
        <BsRow class='no-gutters mb-2 mb-md-3'>
          <BsCol class='col-12 col-md-12'>
            <CardPaper
              elevation={0}
              sx={{
                borderTop: 0,
                // backgroundColor: theme.palette.primary.main,
              }}
            >
              <CardContent className='p-0'>
                <BsRow class=' align-items-center'>
                  <BsCol class='col-lg-6'>
                    <Typography variant='h4' component='h2'>
                      Error de Funcionamiento
                    </Typography>
                  </BsCol>
                  <BsCol class='col-lg-6 d-md-flex justify-content-md-end'>
                    <Box
                      sx={{
                        // height: '20px',
                        // width: '40px',
                        backgroundColor: 'white',
                      }}
                    >
                      {renderFilters()}
                    </Box>
                  </BsCol>
                </BsRow>
              </CardContent>
            </CardPaper>
          </BsCol>
        </BsRow>

        {loadingState && (
          <div className='form-style'>
            <BsContainer fluid class='px-3 px-md-3'>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-12'>
                  <Skeleton variant='rounded' height={180} />
                </BsCol>
              </BsRow>
              <BsRow class='gutter_10 mb-md-3'>
                <BsCol class='col-md-12'>
                  <Skeleton variant='rounded' height={280} />
                </BsCol>
              </BsRow>
            </BsContainer>
          </div>
        )}

        {!loadingState && irrigationDataState && (
          <>
            <BsRow class='gutter_10 mb-3'>
              <BsCol class='col-md-12'>
                <CardOrchardGeneralBalance
                  indicators={indicatorsValues}
                  indicatorsData={irrigationDataState?.indicatorsData}
                  title=''
                  description=''
                />
              </BsCol>
            </BsRow>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12'>
                <CardPaper elevation={0} variant='outlined'>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Detalle Mensual
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Acumulativo de los agroindicadores mensuales hasta la
                      fecha
                    </Typography>
                    <Box
                      className='table-responsive'
                      sx={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                      }}
                    >
                      <table
                        className={clsx(
                          'table table-borderless',
                          classes.tableGreyHeader
                        )}
                      >
                        <thead>
                          <tr>
                            <th>Caseta</th>
                            <th>Huerto</th>
                            <th>Equipo</th>
                            <th>Volumen Equipo</th>
                            <th>Volumen Sector</th>
                            <th>Volumen de Fuga</th>
                            <th>% de Fuga</th>
                          </tr>
                        </thead>
                        <tbody>
                          {irrigationDataState.orchardTable ? (
                            irrigationDataState.orchardTable.map(
                              (row, index) => (
                                <tr key={index}>
                                  <td>{row.irrigationShed}</td>
                                  <td>{dashDelete(row.orchardValue)}</td>
                                  <td>{row.machine}</td>
                                  <td>
                                    {numberFormatDecimals(row.machineVolume, 0)}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(row.sectorVolume, 0)}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(row.lossVolume, 0)}
                                  </td>
                                  <td>
                                    {numberFormatDecimals(
                                      row.lossPercentage,
                                      1
                                    )}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan={5}>No hay datos</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12'>
                <CardPaper elevation={0} variant='outlined' sx={{ mt: 2 }}>
                  <CardContent className='p-3'>
                    <Typography gutterBottom variant='h4' component='h2'>
                      Volumen de agua aplicada y fuga por día
                    </Typography>
                    <Typography gutterBottom variant='p' component='p'>
                      Volumen de agua programado y de fuga diario
                    </Typography>
                    <HighchartsWrapper
                      fullScreenHandler={openFullscreen}
                      title=''
                    >
                      <ColumnChart
                        data={irrigationDataState?.chart.data}
                        // xAxisCategories={
                        //   (yieldChartData && yieldChartData?.categories) || []
                        // }
                        enableDataLabels={true}
                        dataLabelsFormat='{point.y:.0f}'
                        stacking='normal'
                        showLegend={true}
                        xAxisCategories={
                          irrigationDataState?.chart.categories || []
                        }
                        tooltipFormat='{series.name}: {point.y:,.0f} m3'
                        tooltipFormatter={function () {
                          let tooltip = `<b>${this.x}</b><br/>`;
                          this.points.forEach((point) => {
                            tooltip += `${
                              point.series.name
                            }: ${numberFormatDecimals(point.y, 0)} m3<br/>`;
                          });
                          tooltip += `<b>Total: ${numberFormatDecimals(
                            this.points[0].total,
                            0
                          )} m3</b>`;
                          return tooltip;
                        }}
                        tooltipShared={true}
                        chartRef={chartRef}
                        colors={colors}
                        height={270}
                        gridLineWidth={0}
                        spacing={[10, 0, 0, 0]}
                        yAxisTitle='Volumen Programado y Fuga' // Titulo del eje Y
                        type='column' // column o bar
                        legendLayout='vertical'
                        legendAlign='right'
                        legendVerticalAlign='middle'
                      />
                    </HighchartsWrapper>
                  </CardContent>
                </CardPaper>
              </BsCol>
            </BsRow>
          </>
        )}
      </BsContainer>
    </div>
  );
};
export default ByError;
