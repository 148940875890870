import {
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardPaper, WeatherAgroIndicator } from 'components';

const CardAgroindicators = ({ agroIndicatorsData, agroIndicators }) => {
  const theme = useTheme();

  // console.log(
  //   agroIndicators,
  //   '<-----------------agroIndicators---------------',
  //   agroIndicatorsData
  // );

  return (
    <CardPaper elevation={0} variant='outlined'>
      <CardContent className='p-3'>
        <Typography gutterBottom variant='h4' component='h2'>
          Agroindicadores
        </Typography>
        <Typography gutterBottom variant='p' component='p'>
          Agroindicadores climáticos históricos acumulados a la fecha
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={2} className='my-3'>
          {agroIndicators.map((agroIndicator) => (
            <Grid item xs={6} md={6} lg={3} key={agroIndicator.value}>
              <WeatherAgroIndicator
                data={{
                  title: agroIndicator.label,
                  key: agroIndicator.value,
                  data: agroIndicatorsData[agroIndicator.value],
                }}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </CardPaper>
  );
};

export default CardAgroindicators;
