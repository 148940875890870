import { Button } from '@mui/material';

const SimpleIconButton = (props) => {
  const {
    disabled = false,
    actionOnClick,
    labelButton,
    size = 'small',
    variant = 'outlined',
    color = 'primary',
    icon,
    endIcon,
    style,
    ...others
  } = props;

  return (
    <Button
      size={size}
      disabled={disabled}
      variant={variant}
      // classes={{
      //   root: classes.buttonStyle,
      // }}
      {...others}
      style={{ ...style }}
      sx={{
        color: color,
        borderColor: color,
        '&:hover': {
          borderColor: color,
        },
      }}
      startIcon={icon || null}
      endIcon={endIcon || null}
      onClick={actionOnClick}
    >
      {labelButton}
    </Button>
  );
};

export default SimpleIconButton;
