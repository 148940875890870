import {
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardPaper, IndustryIndicator } from 'components';

const CardEstimateIndicators = ({ indicatorsData, indicators }) => {
  const theme = useTheme();

  return (
    <CardPaper elevation={0} variant='outlined'>
      <CardContent className='p-3'>
        <Typography gutterBottom variant='h4' component='h2'>
          Indicadores
        </Typography>
        <Typography gutterBottom variant='p' component='p'>
          Resumen de mis unidades
        </Typography>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          className='my-3'
          columns={{ sm: 4, md: 10 }}
        >
          {indicators.map((indi) => (
            <Grid item sm={2} md={2} lg={2} key={indi.value}>
              <IndustryIndicator
                data={{
                  title: indi.label,
                  key: indi.value,
                  data: indicatorsData[indi.value],
                }}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </CardPaper>
  );
};

export default CardEstimateIndicators;
