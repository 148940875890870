export const localeES = {
  back: 'Atrás',
  close: 'Cerrar',
  last: 'Finalizar',
  next: 'Siguiente',
  nextLabelWithProgress: 'Siguiente',
  skip: 'Omitir',
  open: 'Abrir',
};

export const getJoyrideSteps = (
  moduleJoy = 'counting',
  processJoy = 'dashboard'
) => {
  const countingModule = steps.find((module) => module.module === moduleJoy);
  const dashboardProcess = countingModule?.process.find(
    (process) => process.name === processJoy
  );
  return dashboardProcess?.steps || [];
};

export const steps = [
  {
    module: 'counting',
    process: [
      {
        name: 'dashboard',
        steps: [
          {
            target: '.counting-dashboard-1',
            content:
              'En el filtro de inicio podrás buscar el huerto que desees.',
            disableBeacon: true,
          },
          {
            target: '.counting-dashboard-2',
            content:
              'En seguida tendrás un filtro de etapas de conteo, el cual desplegará una planilla con información general de conteos según la etapa que te interese.',
          },
          {
            target: '.counting-dashboard-3',
            content:
              'A continuación tendrás un detalle general de las plantas contadas en el huerto, porcentaje de plantas contadas, cuarteles contados y cuarteles no contados.',
          },
          {
            target: '.counting-dashboard-4',
            content:
              'También tendrás la opción de exportar la tabla en formato excel. El la tabla puedes ordenar por columnas.',
          },
          {
            target: '.counting-dashboard-5',
            content:
              'Aquí podrás ver de manera gráfica el avance de conteos según etapa realizada.',
          },
        ],
      },
      {
        name: 'quarter',
        steps: [
          {
            target: '.counting-quarter-1',
            content:
              'En este submódulo podrás filtrar información de conteos detalladamente dependiendo de qué cuartel, centro de costo o variedad te interese.',
            disableBeacon: true,
          },
          {
            target: '.counting-quarter-2',
            content:
              'Además, podrás filtrar según etapa de conteo y obtendrás el despliegue de la tabla de avance.',
          },
          {
            target: '.counting-quarter-3',
            content:
              'En el costado superior derecho de la tabla de avances podrás escoger el conjunto de columnas que desees visualizar.',
          },
          {
            target: '.counting-quarter-4',
            content:
              'También tendrás la opción de exportar la tabla en formato excel. El la tabla puedes ordenar por columnas.',
          },
        ],
      },
      {
        name: 'plant',
        steps: [
          {
            target: '.counting-plant-1',
            content:
              'En este submódulo podrás buscar el detalle de los conteos subidos planta a planta.',
            disableBeacon: true,
          },
          {
            target: '.counting-plant-2',
            content:
              'Una vez filtrado el huerto y cuartel que desees, podrás encontrar la siguiente tabla con información del usuario que ingresó el conteo, fecha, número de planta e hilera, cantidad de dardos, ramillas y frutos, entre otros.',
          },
          {
            target: '.counting-plant-3',
            content:
              'En el extremo superior derecho de la tabla de detalle planta a planta podrás encontrar dos botonerías clave: Ocultar/Mostrar columnas de la tabla según preferencia. Exportar datos de la tabla en formato excel',
          },
        ],
      },
      {
        name: 'heatmap',
        steps: [
          {
            target: '.counting-heatmap-1',
            content: (
              <>
                <p>
                  En este submódulo podrás ver un mapa con todos los conteos por
                  etapa distribuidos de manera espacial .Aquí podrás filtrar por
                  cuartel, variedad y etapa de conteo.
                </p>{' '}
                <p className='mb-0'>FILTRAR PARA CONTINUAR</p>
              </>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            hideCloseButton: true,
            spotlightClicks: true,
            placement: 'bottom',
          },
          {
            target: '.counting-heatmap-2',
            placement: 'right',
            content:
              'Una vez que uses el filtro, podrás observar la densidad de estructuras reproductivas por cuartel seleccionado de manera espacial.  Esta herramienta puede ser útil para saber qué sectores se encuentran más o menos sobrecargados de dardos, ramillas o frutos dependiendo de la instancia de conteo.',
          },
          {
            target: '.counting-heatmap-3',
            placement: 'bottom',
            content: 'Aquí puedes recorrer los cuarteles hacia delante y atrás',
          },
          {
            target: '.counting-heatmap-4',
            placement: 'left',
            content:
              'Aquí podrás encontrar información del cuartel y un resumen de los avances de la labor por cada instancia de conteo',
          },
        ],
      },
      {
        name: 'heatmapOrchard',
        steps: [
          {
            target: '.counting-heatmap-orchard-1',
            content: (
              <>
                <p>
                  Mapa calor a nivel de huerto que muestra la densidad de conteo
                  en todos los cuarteles. Al seleccionar un polígono es posible
                  ver detalles del conteo.
                </p>{' '}
                <p className='mb-0'>FILTRAR PARA CONTINUAR</p>
              </>
            ),
            disableBeacon: true,
            disableOverlayClose: true,
            hideFooter: true,
            hideCloseButton: true,
            spotlightClicks: true,
            placement: 'bottom',
          },
          {
            target: '.counting-heatmap-orchard-2',
            placement: 'right',
            content:
              'Una vez que uses el filtro, podrás observar la densidad de estructuras reproductivas por cuartel seleccionado de manera espacial.  Esta herramienta puede ser útil para saber qué sectores se encuentran más o menos sobrecargados de dardos, ramillas o frutos dependiendo de la instancia de conteo.',
          },
          {
            target: '.counting-heatmap-orchard-3',
            placement: 'left',
            content:
              'Aquí podrás encontrar información del cuartel filtrado y un resumen de los avances de la labor por cada instancia de conteo',
          },
        ],
      },
    ],
  },
  {
    module: 'planning_harvest',
    process: [
      {
        name: 'schedule',
        steps: [
          {
            target: 'body', // Apunta al body para centrar el tooltip
            placement: 'center', // Centra el tooltip en la pantalla
            content:
              'En este submódulo podrás realizar la estimación de kilos a cosechar en un periodo de tiempo determinado, según la especie seleccionada en el recuadro superior derecho.',
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '.planning_harvest-schedule-2',
            title: 'Filtro de fechas',
            content: (
              <>
                'En este filtro podrás seleccionar el rango de fechas que
                necesites para realizar tu estimación.{' '}
                <b>
                  NOTA: Por defecto la plataforma establece un rango de veinte
                  días, pero es modificable.
                </b>
                '
              </>
            ),
          },
          {
            target: '.planning_harvest-schedule-3',
            title: 'Buscador',
            content:
              'Aquí podrás buscar cualquier tipo de dato que se encuentre dentro de tu planificación de cosecha (CSG, variedad, Agrícola, etc.).',
          },
          {
            target: '.planning_harvest-schedule-4',
            title: 'Guardado',
            content: (
              <>
                Una vez completada la estimación de kilos en la tabla se debe
                dar click a este botón{' '}
                <b>para guardar la información registrada.</b>
              </>
            ),
          },
          {
            target: '.planning_harvest-schedule-5',
            content:
              'A través de estos iconos podrás descargar tu estimación en la tabla en formatos excel o csv. También podrás llevar la tabla a pantalla completa para una mejor visualización.',
          },
        ],
      },
      {
        name: 'checkday',
        steps: [
          {
            target: 'body', // Apunta al body para centrar el tooltip
            placement: 'center', // Centra el tooltip en la pantalla
            content:
              'En este submódulo podrás chequear la cantidad de kilos y agrícolas sin confirmar, como también el detalle de las horas y fechas de confirmación.',
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '.planning_harvest-checkday-2',
            title: 'Indicadores',
            content: (
              <>
                En esta breve tabla encontrarás un resumen de las estimaciones
                realizadas para un día en concreto.
              </>
            ),
          },
          {
            target: '.planning_harvest-checkday-3',
            title: 'Filtro, Buscador y Botones',
            content: (
              <>
                Al igual que en el submódulo bi semanal, tienes un filtro por
                fecha.{' '}
                <b>IMPORTANTE: sólo se puede seleccionar HOY y MAÑANA.</b>
                También un buscador de datos y botonería para descargar o ver la
                tabla en pantalla completa.
              </>
            ),
          },
          {
            target: '.planning_harvest-checkday-4',
            title: 'Tabla Confirmaciones',
            content: (
              <>
                En esta tabla podrás encontrar el detalle de agrícolas,
                variedades y kilos confirmados (Verde) en conjunto de la fecha y
                hora de confirmación. Además, podrás observar el detalle de los
                kilos no confirmados en Gris.
              </>
            ),
          },
        ],
      },
      {
        name: 'checkbiweekly',
        steps: [
          {
            target: 'body', // Apunta al body para centrar el tooltip
            placement: 'center', // Centra el tooltip en la pantalla
            content:
              'Este submódulo es para el control de la realización del proceso de Bisemanal o Pentasemanal.',
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '.planning_harvest-checkbiweekly-2',
            title: 'Filtro Semanas',
            content: (
              <>Selección de la estimación realizada que se desea confirmar.</>
            ),
          },
          {
            target: '.planning_harvest-checkbiweekly-3',
            title: 'Tabla Editable',
            content: (
              <>
                Para confirmar el registro deberás hacer doble click sobre la
                cruz y dar un último click para <b>dejar el check en verde.</b>
              </>
            ),
          },
          {
            target: '.planning_harvest-checkbiweekly-4',
            title: 'Guardar',
            content: <>Una vez realizado se debe dar click en GUARDAR.</>,
          },
        ],
      },
      {
        name: 'checkdayform',
        steps: [
          {
            target: 'body', // Apunta al body para centrar el tooltip
            placement: 'center', // Centra el tooltip en la pantalla
            content:
              'En este submódulo podrás realizar el proceso de chequeo diario de la App Móvil, pero desde la plataforma.',
            disableBeacon: true,
            isFixed: true,
          },
          {
            target: '.planning_harvest-checkdayform-2',
            title: 'Fecha de Estimación',
            content: (
              <>
                Debe Seleccionar si desea Chequear para HOY, Estimación
                Confirmada o para MAÑANA Estimación. También Puede adelantar la
                estimación de Pasado Mañana.
              </>
            ),
          },
          {
            target: '.planning_harvest-checkdayform-3',
            title: 'Tabla Confirmaciones',
            content: (
              <>
                En esta tabla econtrarás las líneas que tienen kilos para el día
                seleccionado. En las últimas columnas, que son editables, podrá
                Confirmar, Colocar Nota fruta y/o algún otro dato necesario de
                la especie.
              </>
            ),
          },
          {
            target: '.planning_harvest-checkdayform-4',
            title: 'Guardar',
            content: (
              <>Una vez realizado el chequeo se debe dar click en GUARDAR.</>
            ),
          },
        ],
      },
    ],
  },
];
