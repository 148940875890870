import React from 'react';
import { Avatar, Box, SvgIcon, Typography, useTheme } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';
import iconJson from '../../common/iconSVG.json';
import {
  precipitation,
  coldHour,
  coldPortion,
  degreeDays,
} from '../../assets/icons/weather/svg_icons';

const WeatherAgroIndicator = ({ data }) => {
  const theme = useTheme();

  let bgColor = 'white';
  let avatarColor = 'white';

  const actualYearData = data.data.find(
    (item) => item.year === new Date().getFullYear().toString()
  );

  const historyYearsData = data.data.filter(
    (item) => item.year !== new Date().getFullYear().toString()
  );

  console.log(data, '<-----------------data EN BOX---------------');
  let IconIndicator = createSvgIcon(precipitation, 'icon');

  switch (data.key) {
    case 'coldHour':
      bgColor = theme.palette.weather.agroindicators.background1;
      avatarColor = theme.palette.weather.agroindicators.avatar1;
      IconIndicator = createSvgIcon(coldHour, 'icon');
      break;

    case 'coldPortion':
      bgColor = theme.palette.weather.agroindicators.background1;
      avatarColor = theme.palette.weather.agroindicators.avatar1;
      IconIndicator = createSvgIcon(coldPortion, 'icon');
      break;

    case 'degreeDays':
      bgColor = theme.palette.weather.agroindicators.background2;
      avatarColor = theme.palette.weather.agroindicators.avatar2;
      IconIndicator = createSvgIcon(degreeDays, 'icon');
      break;

    case 'precipitation':
      bgColor = theme.palette.weather.agroindicators.background3;
      avatarColor = theme.palette.weather.agroindicators.avatar3;
      IconIndicator = createSvgIcon(precipitation, 'icon');
      break;

    default:
      break;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: bgColor,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'left',
        borderRadius: `${theme.shape.borderRadius}px`,
        padding: 3,
      }}
    >
      <Avatar sx={{ bgcolor: avatarColor, mb: 1 }}>
        <IconIndicator sx={{ width: 24, height: 24 }} />
      </Avatar>
      <IconIndicator sx={{ width: 24, height: 24 }} />
      <Typography
        variant='h3'
        component='h3'
        gutterBottom
        sx={{ fontWeight: 600, color: theme.palette.grey[500] }}
      >
        {actualYearData.value}
      </Typography>
      <Typography variant='body2' component='h4' gutterBottom color='secondary'>
        {data.title}
      </Typography>

      {historyYearsData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'left',
            gap: '20px',
            mb: 0.7,
          }}
        >
          <Typography
            variant='body2'
            component='p'
            sx={{ color: theme.palette.grey[500] }}
          >
            {item.year}
          </Typography>
          <Typography
            variant='body2'
            component='p'
            sx={{
              fontWeight: 600,
              color: theme.palette.grey[500],
            }}
          >
            {item.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default WeatherAgroIndicator;
