import {
  Button,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { alphabeticalSort, numberFormatDecimals } from 'helpers';
import React, { useEffect, useState } from 'react';

const TableDetailSeasons = ({ seasonsData }) => {
  console.log(seasonsData, '<-----------------seasonsData---------------');

  // Si no hay datos, mostramos una fila vacía
  if (!seasonsData || seasonsData.length === 0) {
    return (
      <>
        <thead>
          <tr>
            <th scope='col'>Año</th>
            <th scope='col'>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan='2'>No hay datos disponibles</td>
          </tr>
        </tbody>
      </>
    );
  }

  // Recolectar todos los meses únicos de todas las temporadas y ordenarlos
  const allMonths = new Set();
  seasonsData.forEach((season) => {
    season.months.forEach((month) => {
      allMonths.add(month.month);
    });
  });

  const uniqueMonthsArray = [...allMonths].sort(
    (a, b) => parseInt(a) - parseInt(b)
  );

  // Función para obtener el nombre del mes a partir del número
  const getMonthName = (monthNumber) => {
    const monthNames = {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    };
    return monthNames[monthNumber] || `Mes ${monthNumber}`;
  };

  // Función para obtener el valor de un mes específico para una temporada
  const getMonthValue = (season, monthNumber) => {
    const month = season.months.find((m) => m.month === monthNumber);
    return month ? numberFormatDecimals(month.value, 1) : 0;
  };

  return (
    <>
      <thead>
        <tr>
          <th scope='col'>Año</th>
          {uniqueMonthsArray.map((month) => (
            <th key={`header-${month}`} scope='col'>
              {getMonthName(month)}
            </th>
          ))}
          <th scope='col'>Total</th>
        </tr>
      </thead>
      <tbody>
        {alphabeticalSort(seasonsData, 'year', 'desc').map((season) => (
          <tr key={`year-${season.year}`}>
            <td>{season.year}</td>
            {uniqueMonthsArray.map((month) => (
              <td key={`${season.year}-${month}`}>
                {getMonthValue(season, month)}
              </td>
            ))}
            <td className=''>{numberFormatDecimals(season.total, 1)}</td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default TableDetailSeasons;
