// import _ from "lodash";
import React, { useState, useEffect, useContext, memo } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { BsRow, BsCol } from '../../../layouts/components';
import { OptionsContext } from '../../../context';
import GraphPrecipitation from '../GraphPrecipitation';
import GraphColdHours from '../GraphColdHours';
import GraphColdPortions from '../GraphColdPortions';
import GraphDegreeDays from '../GraphDegreeDays';

const MainAccumulatedCharts = ({ filter }) => {
  const theme = useTheme();

  const { graph, orchards = [] } = filter;

  const {
    state: { weather_detail },
    addOptions,
  } = useContext(OptionsContext);

  return (
    <>
      {orchards ? (
        <Box
          sx={{
            p: 1,
          }}
        >
          {graph === 'coldHour' ? (
            <GraphColdHours orchards={{ orchards: orchards }} />
          ) : null}
          {graph === 'coldPortion' ? (
            <GraphColdPortions orchards={{ orchards: orchards }} />
          ) : null}
          {graph === 'degreeDays' ? (
            <GraphDegreeDays orchards={{ orchards: orchards }} />
          ) : null}
          {graph === 'precipitations' ? (
            <GraphPrecipitation orchards={{ orchards: orchards }} />
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export default memo(MainAccumulatedCharts, (prevProps, nextProps) => {
  // Custom comparison function to determine if component should update
  return JSON.stringify(prevProps.filter) === JSON.stringify(nextProps.filter);
});
