// import _ from "lodash";
import React, { useState, useEffect, useContext, memo } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { BsRow, BsCol } from '../../../layouts/components';
import { CardPaper } from '../../../components';
import { Context } from '../../../context/Context';
import { OptionsContext } from '../../../context';
import ColdHour from '../DetailGraphs/ColdHour';
import ColdPortion from '../DetailGraphs/ColdPortion';
import DegreeDays from '../DetailGraphs/DegreeDays';
import GraphPrecipitation from '../DetailGraphs/GraphPrecipitation';
import GraphEvoTranspiration from '../DetailGraphs/GraphEvoTranspiration';
import GraphTemperature from '../DetailGraphs/GraphTemperature';
import GraphHumidity from '../DetailGraphs/GraphHumidity';
import GraphRadiation from '../DetailGraphs/GraphRadiation';

const MainDailyCharts = ({ filter }) => {
  const theme = useTheme();

  const { graph, orchard = null } = filter;

  const {
    state: { weather_detail },
    addOptions,
  } = useContext(OptionsContext);

  console.log('filter-----------Charts', filter);

  return (
    <>
      <BsRow class='gutter_10'>
        {orchard ? (
          <>
            <BsCol class='col-md-12'>
              <Box
                sx={{
                  p: 1,
                }}
              >
                {graph === 'coldHour' ? <ColdHour orchard={orchard} /> : null}
                {graph === 'coldPortion' ? (
                  <ColdPortion orchard={orchard} />
                ) : null}
                {graph === 'degreeDays' ? (
                  <DegreeDays orchard={orchard} />
                ) : null}
                {graph === 'precipitation' ? (
                  <GraphPrecipitation orchard={orchard} />
                ) : null}
                {graph === 'evapotranspiration' ? (
                  <GraphEvoTranspiration orchard={orchard} />
                ) : null}
                {graph === 'temperature' ? (
                  <GraphTemperature orchard={orchard} />
                ) : null}
                {graph === 'humidity' ? (
                  <GraphHumidity orchard={orchard} />
                ) : null}
                {graph === 'radiation' ? (
                  <GraphRadiation orchard={orchard} />
                ) : null}
              </Box>
            </BsCol>
          </>
        ) : null}
      </BsRow>
    </>
  );
};

export default memo(MainDailyCharts, (prevProps, nextProps) => {
  // Custom comparison function to determine if component should update
  return JSON.stringify(prevProps.filter) === JSON.stringify(nextProps.filter);
});
