// import _ from "lodash";
import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  useTheme,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import clsx from 'clsx';
import { useErrorHandler } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import { BsRow, BsCol } from '../../../layouts/components';
import { FiltersContext, OptionsContext } from '../../../context';
import { ReactDataTable, SimpleIconButton } from '../../../components';
// import { DATATABLE } from 'helpers/Mockups';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  // alterArrayGetIds,
  // numberFormat,
  // dashDelete,
  // numberFormatDecimals,
  numberFormatDecimalsCounting,
  setOrderForApi,
  setOrderForNewApi,
} from '../../../helpers';
import {
  colsNoVisCounterPrePoda,
  colsNoVisCounterPrePoda_Ciruelo,
  colsNoVisCounterPostPoda,
  colsNoVisCounterPostPoda_Ciruelo,
  colsNoVisCounterPreRaleo,
  colsNoVisCounterPostRaleo,
  colsNoVisCounterCharacterization,
  colsNoVisCounterProductiveUnit,
  colsNoVisCounterInformation,
  colsNoVisCounterProgress,
  colsCounterQuarterV2,
  colsCounterQuarterV2_Ciruelo,
  initialSorting,
  colsCounterQuarterV2Fixed,
} from '../../../helpers/tableDefinitions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 5,
    },
  },
  actionCol: {
    display: 'flex',
  },
}));

const endPointName = 'v1/counter/resume-counter-quarter';

const selectTypes = [
  'Avances',
  'Estructura Reproductiva',
  'Caracterización',
  'Análisis de Yema',
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const COLS_COUNTER_QUARTER = [
  {
    specie: 'Ciruelo',
    columns: colsCounterQuarterV2_Ciruelo,
    noVisiblePrePoda: colsNoVisCounterPrePoda_Ciruelo,
    noVisiblePostPoda: colsNoVisCounterPostPoda_Ciruelo,
  },
  {
    specie: 'Cerezo',
    columns: colsCounterQuarterV2,
    noVisiblePrePoda: colsNoVisCounterPrePoda,
    noVisiblePostPoda: colsNoVisCounterPostPoda,
  },
  {
    specie: 'Nectarines',
    columns: colsCounterQuarterV2,
    noVisiblePrePoda: colsNoVisCounterPrePoda,
    noVisiblePostPoda: colsNoVisCounterPostPoda,
  },
  {
    specie: 'Duraznero',
    columns: colsCounterQuarterV2,
    noVisiblePrePoda: colsNoVisCounterPrePoda,
    noVisiblePostPoda: colsNoVisCounterPostPoda,
  },
];

const getColumnBySpecie = (specieFilter) => {
  const columnObject = COLS_COUNTER_QUARTER.find(
    (col) => col.specie === specieFilter
  );
  return columnObject || COLS_COUNTER_QUARTER[0];
};

const Quarter = ({ filter: filterProp }) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const { userOrchard } = useContext(Context);

  const history = useHistory();

  const axiosContext = useContext(AxiosContext);

  const {
    state: { specie_filter },
    addFilter,
  } = useContext(FiltersContext);

  const {
    state: { counting_quarter },
    addOptions,
  } = useContext(OptionsContext);

  const [visibleColumnsState, setVisibleColumnsState] = useState(
    getColumnBySpecie(specie_filter).columns
  );

  // console.log(visibleColumnsState, '-------visibleColumnsState');

  const [groupColsState, setGroupColsState] = useState(
    counting_quarter?.groupCols || {
      prePoda: true,
      postPoda: true,
      preRaleo: true,
      postRaleo: true,
    }
  );

  const redirectOrchard = (row) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir
    const link = '/counting-dashboard';

    const { orchardValue } = row?.original;

    addFilter({
      orchard_filter: orchardValue,
    });

    // // a biblioteca
    history.push({
      pathname: link,
    });
  };

  const redirectQuarter = (row) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir
    const link = '/counting-plants';

    const { orchardValue, ccValue, quarterValue } = row?.original;

    addFilter({
      orchard_filter: orchardValue,
      cc_filter: ccValue,
      quarter_filter: quarterValue,
    });

    // // a biblioteca
    history.push({
      pathname: link,
    });
  };

  const [selectedTypeState, setSelectedTypeState] = useState(
    counting_quarter?.selectedType ||
      selectTypes.filter((item) => item !== 'Análisis de Yema')
  );

  // console.log(isDesktop, "<-----")

  colsCounterQuarterV2.forEach((section) => {
    if (section.id === 'productiveUnit') {
      section.columns.forEach((column) => {
        if (column.accessorKey === 'orchard') {
          // eslint-disable-next-line react/display-name
          column.Cell = ({ renderedCellValue, row }) => (
            <Button variant='text' onClick={() => redirectOrchard(row)}>
              {renderedCellValue}
            </Button>
          );
        }
        if (column.accessorKey === 'quarter') {
          // eslint-disable-next-line react/display-name
          column.Cell = ({ renderedCellValue, row }) => (
            <Button
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
              variant='text'
              onClick={() => redirectQuarter(row)}
            >
              <div>
                <b>{row.original.quarter}</b>
              </div>
              <div>
                {row.original.cc} -{row.original.variety}
              </div>
            </Button>
          );
        }
      });
    }
  });

  const columns = useMemo(
    () => getColumnBySpecie(specie_filter).columns,
    [specie_filter]
  );

  const customHeadStyles = {};

  const headRowStyles = {
    sx: (theme) => ({
      // para fijar cabeceras de varios rows
      position: 'sticky',
      top: 0,
      zIndex: 1,
      '& tr:first-of-type': {
        'th:nth-of-type(odd)': {
          backgroundColor: theme.palette.grey[100],
        },
        'th > div': {
          justifyContent: 'center',
          py: 1,
          '& > div > div': {
            fontWeight: 'bold',
            fontSize: 15,
          },
        },
      },
      '& tr:nth-of-type(2)': {
        'th:nth-of-type(n+6):not(:nth-last-of-type(-n+4))': {
          borderLeft: '1px solid' + theme.palette.grey[200],
        },
        'th > div': {
          justifyContent: 'center',
          py: 1,
        },
      },
    }),
  };

  // table

  const initSort = [
    {
      id: 'quarter',
      desc: true,
    },
  ];

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState(initSort);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const redirect = (path = '/library', row) => {
    // console.log(infoIntelligence, '----infoIntelligence');
    // TODO: tomar de infointelligence, cc, quarter para setear filters antes de ir
    const link = path;

    console.log(row, '----para redirect');

    const {
      ccValue,
      quarterValue,
      orchardValue,
      varietyValue,
      rootStockValue,
    } = row?.original;

    addFilter({
      orchard_filter: orchardValue,
      cc_filter: ccValue,
      quarter_filter: quarterValue,
      variety_filter: varietyValue,
      rootstock_filter: rootStockValue,
    });

    // // a biblioteca
    history.push({
      pathname: link,
    });
  };

  const actionsColumn = (row) => {
    return (
      <div className={clsx('actionColumn', classes.actionCol)}>
        <SimpleIconButton
          labelButton='Biblioteca'
          icon={<KeyboardArrowRightIcon />}
          actionOnClick={() => redirect('/library', row)}
        />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsError(null);

      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const {
        orchard,
        cc,
        quarter,
        variety,
        dateEnd,
        specieValue,
        // porta_injerto,
        // temporada,
      } = filterProp;

      const varieties = variety.length
        ? variety.map((variety) => variety.value)
        : null;

      const objQuery = {
        orchardValue: orchard?.value,
        huerto_user: userOrchard, // orchard del login
        ccValue: cc?.value,
        quarterValue: quarter?.value,
        varietyValue: varieties,
        specieValue: specieValue,
        // rootStock: porta_injerto,
        // season: temporada,
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
        sortColumn: setOrderForNewApi(sorting)?.key,
        sortOrder: setOrderForNewApi(sorting)?.option,
      };

      // console.log(objQuery, '<-----asi está yendo', sorting);

      try {
        const response = await axiosContext.authAxios.post(
          endPointName,
          objQuery
        );

        const { data, status } = response;

        // console.log(data, '----asi llega el first tab');

        setData(data.data);
        setRowCount(data.count);
      } catch (error) {
        console.error(error);
        setIsError(error);
        errorHandler(error);
      }

      setIsLoading(false);
      setIsRefetching(false);
    };

    if (filterProp) {
      fetchData();
    }
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  const handleChangeCols = (event) => {
    setGroupColsState({
      ...groupColsState,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const colsToHide = [];
    if (!groupColsState.prePoda) {
      const colsHidePrePoda = getColumnBySpecie(specie_filter).noVisiblePrePoda;

      colsToHide.push(...colsHidePrePoda);
    }
    if (!groupColsState.postPoda) {
      const colsHidePostPoda =
        getColumnBySpecie(specie_filter).noVisiblePostPoda;

      colsToHide.push(...colsHidePostPoda);
    }
    if (!groupColsState.preRaleo) {
      colsToHide.push(...colsNoVisCounterPreRaleo);
    }
    if (!groupColsState.postRaleo) {
      colsToHide.push(...colsNoVisCounterPostRaleo);
    }

    // si selectedTypeState NO contiene 'Caracterización' agrega colsNoVisCounterProductiveUnit
    if (!selectedTypeState.includes('Caracterización')) {
      colsToHide.push(...colsNoVisCounterCharacterization);
    }

    if (!selectedTypeState.includes('Análisis de Yema')) {
      colsToHide.push(...colsNoVisCounterInformation);
    }

    if (!selectedTypeState.includes('Estructura Reproductiva')) {
      colsToHide.push(...colsNoVisCounterProductiveUnit);
    }

    if (!selectedTypeState.includes('Avances')) {
      colsToHide.push(...colsNoVisCounterProgress);
    }

    // console.log(selectedTypeState, '<----selectedTypeState');

    setVisibleColumnsState(colsToHide);

    addOptions({
      counting_quarter: {
        groupCols: groupColsState,
        selectedType: selectedTypeState,
      },
    });
  }, [groupColsState, selectedTypeState]);

  const handleChangeSelectType = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTypeState(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <BsRow class='justify-content-between' style={{ marginTop: -20 }}>
      <BsCol class='col-12 pt-1'>
        {/* <p>tab3</p> */}

        <BsRow class='justify-content-between'>
          <BsCol class='col-12 col-md-6 align-content-center'>
            <Box className='counting-quarter-2'>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox name='prePoda' size='small' />}
                  checked={groupColsState.prePoda}
                  onChange={handleChangeCols}
                  label='Pre Poda'
                  componentsProps={{
                    typography: { variant: 'body2' },
                  }}
                  sx={{
                    borderColor: theme.palette.primary.main,
                    border: 1,
                    p: 0.5,
                    height: 35,
                    borderRadius: theme.shape.borderRadius + 'px',
                    '& .MuiCheckbox-root': {
                      p: 0,
                    },
                  }}
                />
                <FormControlLabel
                  control={<Checkbox name='postPoda' size='small' />}
                  checked={groupColsState.postPoda}
                  onChange={handleChangeCols}
                  componentsProps={{
                    typography: { variant: 'body2' },
                  }}
                  label='Post Poda'
                  sx={{
                    borderColor: theme.palette.primary.main,
                    border: 1,
                    p: 0.5,
                    height: 35,
                    borderRadius: theme.shape.borderRadius + 'px',
                    '& .MuiCheckbox-root': {
                      p: 0,
                    },
                  }}
                />
                <FormControlLabel
                  control={<Checkbox name='preRaleo' size='small' />}
                  checked={groupColsState.preRaleo}
                  onChange={handleChangeCols}
                  label='Pre Raleo'
                  componentsProps={{
                    typography: { variant: 'body2' },
                  }}
                  sx={{
                    borderColor: theme.palette.primary.main,
                    border: 1,
                    p: 0.5,
                    height: 35,
                    borderRadius: theme.shape.borderRadius + 'px',
                    '& .MuiCheckbox-root': {
                      p: 0,
                    },
                  }}
                />
                <FormControlLabel
                  control={<Checkbox name='postRaleo' size='small' />}
                  checked={groupColsState.postRaleo}
                  onChange={handleChangeCols}
                  label='Post Raleo'
                  componentsProps={{
                    typography: { variant: 'body2' },
                  }}
                  sx={{
                    borderColor: theme.palette.primary.main,
                    border: 1,
                    p: 0.5,
                    height: 35,
                    borderRadius: theme.shape.borderRadius + 'px',
                    '& .MuiCheckbox-root': {
                      p: 0,
                    },
                  }}
                />
              </FormGroup>
            </Box>
          </BsCol>
          <BsCol class='col-12 col-md-6 text-md-right'>
            <FormControl
              sx={{ m: 1, width: 300 }}
              className='counting-quarter-3'
            >
              <InputLabel id='demo-multiple-checkbox-label'>
                Columnas
              </InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={selectedTypeState}
                onChange={handleChangeSelectType}
                input={<OutlinedInput label='Columnas' />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {selectTypes.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedTypeState.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BsCol>
        </BsRow>

        <div
          className={clsx(
            'row counting-quarter-4',
            classes.tableContainerStyle
          )}
        >
          <ReactDataTable
            columns={columns}
            data={data}
            hideColumns={visibleColumnsState}
            fixedColumns={colsCounterQuarterV2Fixed}
            isError={isError}
            isLoading={isLoading}
            isRefetching={isRefetching}
            module='CountingQuarter'
            onSortingChange={setSorting}
            sorting={sorting}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={rowCount}
            onColumnFiltersChange={setColumnFilters}
            columnFilters={columnFilters}
            onGlobalFilterChange={setGlobalFilter}
            globalFilter={globalFilter}
            enableRowActions={true}
            renderRowActions={actionsColumn}
            headRowStyles={headRowStyles}
            headStyles={customHeadStyles}
            enableStickyHeader={true}
            positionPagination={'top'}
          />
        </div>
      </BsCol>
    </BsRow>
  );
};

export default Quarter;
