// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useErrorHandler } from 'react-error-boundary';
import { subDays } from 'date-fns';
import { Box, useTheme } from '@mui/material';

// Internal modules
import { AxiosContext } from '../../../../context/AxiosContext';
import { Context } from '../../../../context/Context';
import { yearGraphColors } from '../../../../theme/palette';

// Components
import {
  HighchartsWrapper,
  LoadingWidget,
  SaveAction,
  TextFieldWidgetDateController2,
} from '../../../../components';

// Functions, constants, etc.
import {
  alterArrayGetVariety,
  dateFormatIso,
  dateFormatIsoLocale,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../../helpers';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);

const GraphHumidity = ({ orchard: orchardProp }) => {
  const chartRef = useRef(null);

  const axiosContext = useContext(AxiosContext);

  const { userOrchard } = useContext(Context);

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchSince = watch('since', '');
  const watchUntil = watch('until', '');

  const [loadingState, setLoadingState] = useState(false);
  const [graphOptionsState, setGraphOptionsState] = useState(null);
  const [minDateState, setminDateState] = useState(null);
  const [sinceSelectedState, setSinceSelectedState] = useState(null);
  const [untilSelectedState, setUntilSelectedState] = useState(null);

  const actualYear = new Date().getFullYear();

  const minYearDate = new Date(actualYear, 0, 1);
  const maxYearDate = new Date(actualYear, 11, 31);

  const highchartsOptions = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      zoomType: 'x',
      type: 'spline',
      height: 340,
      spacingBottom: 8,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      shared: true,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
      },
    },
    yAxis: {
      // Primary yAxis
      labels: {
        format: '{value} %',
        style: {
          color: 'rgb(61,72,77)',
        },
      },
      title: {
        text: 'Humedad Relativa Diaria',
        style: {
          color: 'rgb(61,72,77)',
        },
      },
    },
    colors: theme.palette.weather.charts.daily.humidity,
    plotOptions: {
      spline: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4,
          },
        },
        marker: {
          enabled: false,
        },
        pointInterval: graphOptionsState?.interval * 60000 || 1.8e6, // one hour
        pointStart: graphOptionsState
          ? Date.parse(graphOptionsState.begingDate)
          : Date.UTC(2022, 5, 13, 0, 0, 0),
      },
      series: {
        label: {
          connectorAllowed: false,
        },
        // pointStart: 0,
      },
    },
    series: [
      {
        name: graphOptionsState?.relativeHumidity[0]?.name,
        data: graphOptionsState?.relativeHumidity[0]?.data,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %',
        },
      },
      {
        name: graphOptionsState?.relativeHumidity[1]?.name,
        data: graphOptionsState?.relativeHumidity[1]?.data,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %',
        },
      },
      {
        name: graphOptionsState?.relativeHumidity[2]?.name,
        data: graphOptionsState?.relativeHumidity[2]?.data,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %',
        },
      },
    ],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Humedad Relativa',
    },
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  async function queryData(dataQuery) {
    setLoadingState(true);
    try {
      //   TODO: ver cuando quiero ver mis huertos
      const { orchard, sinceDate, untilDate } = dataQuery;

      const objQuery = {
        orchard: orchard,
        sinceDate,
        untilDate,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/weather/weather-relative-humidity',
        objQuery
      );

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setGraphOptionsState(data);
      }
      setLoadingState(false);
    } catch (err) {
      setGraphOptionsState([]);
      setLoadingState(false);
      errorHandler(err);
    }
  }

  const onSubmit = async (values) => {
    const dateBegin = values.since;
    const dateEnd = values.until;

    if (dateBegin > dateEnd) {
      setError('incorrectDates', {
        type: 'manual',
        message: 'Fecha hasta debe ser mayor a desde',
      });
      return;
    }

    const data = {
      orchard: orchardProp,
      sinceDate: dateFormatIsoLocale(dateBegin),
      untilDate: dateFormatIsoLocale(dateEnd),
    };

    console.log(data, '<--------data');

    queryData(data);
  };

  useEffect(() => {
    setminDateState(watchSince);
    setSinceSelectedState(watchSince);
    clearErrors('incorrectDates');
  }, [watchSince]);

  useEffect(() => {
    setUntilSelectedState(watchUntil);
    clearErrors('incorrectDates');
  }, [watchUntil]);

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    if (orchardProp) {
      console.log(orchardProp, '<----orchardProp');

      handleSubmit(onSubmit)();
    }
  }, [orchardProp]);

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        className='mb-2'
      >
        <Box
          sx={{
            // width: {
            //   sm: '100%',
            //   md: '70%',
            // },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <form
            className='form-inline'
            autoComplete='on'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='since'
                labeltext='Desde'
                placeholder='Desde'
                display='vertical'
                variant='outlined'
                format='dd-MM'
                style={{}}
                minheight={false}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                minDate={minYearDate}
                maxDate={maxYearDate}
                control={control}
                errors={errors.since}
                defaultValue={subDays(new Date(), 14)}
              />
            </div>
            <div className='input-group ml-md-2'>
              <TextFieldWidgetDateController2
                name='until'
                labeltext='Hasta'
                placeholder='Hasta'
                display='vertical'
                variant='outlined'
                format='dd-MM'
                style={{}}
                minheight={false}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                sx={{
                  width: '150px',
                }}
                maxDate={maxYearDate}
                minDate={minDateState}
                control={control}
                errors={errors.until}
                defaultValue={subDays(new Date(), 1)}
              />
            </div>
            <SaveAction
              title='Filtrar'
              color='secondary'
              size='medium'
              className='roundedButton ml-md-2'
              variant='contained'
              disabled={false}
              messageType='add'
              mutationLoading={false}
              success={false}
            />
          </form>
          {errors.incorrectDates && (
            <p
              style={{
                color: theme.palette.error.main,
              }}
            >
              {errors.incorrectDates.message}
            </p>
          )}
        </Box>
      </div>
      {loadingState && <LoadingWidget />}
      {!loadingState && graphOptionsState && (
        <HighchartsWrapper fullScreenHandler={openFullscreen} title=''>
          <HighchartsReact
            highcharts={Highcharts}
            options={highchartsOptions}
            ref={chartRef}
          />
        </HighchartsWrapper>
      )}
    </>
  );
};

export default GraphHumidity;
