import { Grid, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { HighchartsWrapper } from 'components';
import { LineChart } from 'components/Charts';

const processDataChart = (data, type) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return { categories: [], data: { name: '', data: [] } };
  }

  // Extraer los años únicos y ordenarlos de mayor a menor
  const categories = Array.from(
    new Set(data.flatMap((item) => item.data.map((entry) => entry.year)))
  ).sort((a, b) => b - a);

  // Filtrar el objeto correspondiente al tipo especificado
  const selectedItem = data.find((item) => item.value === type);

  // Formatear el objeto 'data' con los valores correspondientes a los años
  const dataObject = {
    name: selectedItem ? selectedItem.label : '',
    data: selectedItem
      ? categories.map(
          (year) =>
            selectedItem.data.find((entry) => entry.year === year)?.value || 0
        )
      : [],
  };

  return { categories, data: dataObject };
};

const calculateAverage = (inputData) => {
  if (
    !inputData ||
    !inputData.data ||
    !Array.isArray(inputData.data) ||
    inputData.data.length === 0
  ) {
    return 0;
  }

  // Calcular la media
  const average =
    inputData.data.reduce((sum, value) => sum + value, 0) /
    inputData.data.length;
  return average;
};

const CountingCharts = ({ chartData, stageSelected = 'prePoda', colors }) => {
  const theme = useTheme();

  const chartDartRef = useRef(null);
  const chartTwigRef = useRef(null);

  const [dataDartChartState, setDataDartChartState] = useState(null);
  const [dataTwigChartState, setDataTwigChartState] = useState(null);

  const openFullscreen = (typeChart) => {
    let chartRef = typeChart === 'dart' ? chartDartRef : chartTwigRef;

    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  useEffect(() => {
    setDataDartChartState(
      processDataChart(
        chartData,
        `dart${stageSelected === 'prePoda' ? 'Pre' : 'Post'}Poda`
      )
    );
    setDataTwigChartState(
      processDataChart(
        chartData,
        `twig${stageSelected === 'prePoda' ? 'Pre' : 'Post'}Poda`
      )
    );
  }, [chartData, stageSelected]);

  console.log(dataDartChartState?.data, '---data dart');

  return (
    <Grid container spacing={2} sx={{ mt: 2 }} columnSpacing={{ xs: 0, md: 3 }}>
      <Grid item xs={12} md={6}>
        <HighchartsWrapper
          fullScreenHandler={() => openFullscreen('dart')}
          title=''
        >
          <LineChart
            title='Dardos'
            subtitle='A la fecha'
            data={dataDartChartState?.data || []}
            colors={colors}
            type='line'
            exportOptions={true}
            allowPointSelect={false}
            enableDataLabels={false}
            borderRadius={2}
            borderWidth={0}
            showLegend={false}
            spacing={[10, 10, 0, 5]}
            shadow={false}
            xAxisCategories={dataDartChartState?.categories || []} // Categorias del eje X para graficos simples
            yAxisTitle='' // Titulo del eje Y
            gridLineWidth={1} // Ancho de la linea de la cuadricula
            valueSuffix='' // Sufijo de los valores
            dashStyle='Solid' // Tipo de linea 'ShortDashDot' , 'ShortDot', 'Dash', 'Solid', 'ShortDash'
            plotlines={[
              {
                value: calculateAverage(dataDartChartState?.data), // Valor de la media
                color: 'red', // Color de la línea
                dashStyle: 'Dash', // Estilo de línea
                width: 2, // Grosor de la línea
                label: {
                  text: `Media (${calculateAverage(dataDartChartState?.data)})`, // Etiqueta con el valor de la media
                  align: 'right',
                  style: {
                    color: 'red',
                  },
                },
              },
            ]} // Lineas de referencia
            chartRef={chartDartRef}
          />
        </HighchartsWrapper>
      </Grid>
      <Grid item xs={12} md={6}>
        <HighchartsWrapper
          fullScreenHandler={() => openFullscreen('twig')}
          title=''
        >
          <LineChart
            title='Ramillas'
            subtitle='A la fecha'
            data={dataTwigChartState?.data || []}
            colors={colors}
            type='line'
            exportOptions={true}
            allowPointSelect={false}
            enableDataLabels={false}
            borderRadius={2}
            borderWidth={0}
            showLegend={false}
            spacing={[10, 10, 0, 5]}
            shadow={false}
            xAxisCategories={dataTwigChartState?.categories || []} // Categorias del eje X para graficos simples
            yAxisTitle='' // Titulo del eje Y
            gridLineWidth={1} // Ancho de la linea de la cuadricula
            valueSuffix='' // Sufijo de los valores
            dashStyle='Solid' // Tipo de linea 'ShortDashDot' , 'ShortDot', 'Dash', 'Solid', 'ShortDash'
            plotlines={[
              {
                value: calculateAverage(dataTwigChartState?.data), // Valor de la media
                color: 'red', // Color de la línea
                dashStyle: 'Dash', // Estilo de línea
                width: 2, // Grosor de la línea
                label: {
                  text: `Media (${calculateAverage(dataTwigChartState?.data)})`, // Etiqueta con el valor de la media
                  align: 'right',
                  style: {
                    color: 'red',
                  },
                },
              },
            ]} // Lineas de referencia
            chartRef={chartTwigRef}
          />
        </HighchartsWrapper>
      </Grid>
    </Grid>
  );
};

export default CountingCharts;
