import React from 'react';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { numberFormatDecimals } from 'helpers';

const WeatherDayForecast = ({ data }) => {
  return (
    <Box
      sx={{
        width: '82px',
        height: '90px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: '#78aee0',
          border: '2px solid #C8DFF5',
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 0.7,
          position: 'relative',
          color: 'white',
        }}
      >
        {/* Temperatura actual y máxima */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            variant='body2'
            component='span'
            // fontWeight='light'
            color='#FAFAFC'
          >
            {numberFormatDecimals(data?.min, 0)}°
          </Typography>
          <Box
            sx={{
              height: '17px',
              width: '1px',
              backgroundColor: 'white',
            }}
          />
          <Typography
            variant='body2'
            component='span'
            // fontWeight='light'
            color='#FAFAFC'
          >
            {numberFormatDecimals(data?.max, 0)}°
          </Typography>
        </Box>

        <img
          src={data?.icon || ''}
          alt='weather'
          className='img-fluid'
          width={30}
        />

        {/* Mes */}
        <Typography
          variant='body2'
          component='div'
          align='center'
          // fontWeight='light'
          color='#FAFAFC'
        >
          {data?.dayName}
        </Typography>
      </Box>
    </Box>
  );
};

export default WeatherDayForecast;
