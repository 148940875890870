import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

// Custom styled ToggleButtonGroup
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme, size }) => ({
    backgroundColor: theme.palette.grey[30],
    borderRadius: '100px',
    gap: '8px',
    padding: size === 'sm' ? '1px' : '4px',
    '& .MuiToggleButtonGroup-grouped': {
      margin: 0,
      border: 0,
      '&:not(:first-of-type)': {
        borderRadius: '100px',
      },
      '&:first-of-type': {
        borderRadius: '100px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      borderRadius: '8px',
      width: '100%',
      padding: '2px',
      gap: '2px',
      '& .MuiToggleButtonGroup-grouped': {
        '&:not(:first-of-type)': {
          borderRadius: '4px',
          marginTop: '2px',
        },
        '&:first-of-type': {
          borderRadius: '4px',
        },
      },
    },
  })
);

// Custom styled ToggleButton
const StyledToggleButton = styled(ToggleButton)(
  ({ theme, size, buttonactivecolor, buttonactivefontcolor }) => ({
    backgroundColor: 'transparent',
    color: '#4A5568',
    fontSize: size === 'sm' ? '14px' : size === 'lg' ? '18px' : '16px',
    padding:
      size === 'sm' ? '4px 12px' : size === 'lg' ? '12px 24px' : '8px 16px',
    textTransform: 'none',
    '&.Mui-selected': {
      backgroundColor: buttonactivecolor + '!important',
      color: buttonactivefontcolor,
      '&:hover': {
        backgroundColor: '#EBF5FF',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      padding: '3px',
      borderRadius: '4px !important',
    },
  })
);

const CustomToggleButton = ({
  options,
  value,
  onChange,
  size = 'md',
  ariaLabel = 'toggle options',
  buttonActiveColor = '#3182CE',
  buttonActiveFontColor = '#FFFFFF',
}) => {
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label={ariaLabel}
      size={size}
    >
      {options.map((option) => (
        <StyledToggleButton
          key={option.value}
          value={option.value}
          size={size}
          buttonactivecolor={buttonActiveColor}
          buttonactivefontcolor={buttonActiveFontColor}
        >
          {option.label}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default CustomToggleButton;
