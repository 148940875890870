// import _ from "lodash";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  RadioGroup,
  Skeleton,
  Tooltip,
  Radio,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

// AG GRID
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { AG_GRID_LOCALE_ES } from '@ag-grid-community/locale';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadIcon from '@mui/icons-material/Download';
import SyncIcon from '@mui/icons-material/Sync';

import { BsRow, BsCol } from '../../../layouts/components';
import {
  ButtonAction,
  IconActionTableDetail,
  IconActionTableEdit,
  LoadingAgTable,
  ReactDataTable,
} from '../../../components';
// import { DATATABLE } from 'helpers/Mockups';
import { FiltersContext, OptionsContext } from '../../../context';
import { AxiosContext } from '../../../context/AxiosContext';
import { Context } from '../../../context/Context';
import {
  flowersDartForm,
  flowersHaForm,
  setOrderForApi,
  setOrderForNewApi,
  yieldForm,
} from '../../../helpers';
import ModalDetail from '../ModalDetail';
import { useErrorHandler } from 'react-error-boundary';
import {
  colorColsAgYieldCalculator,
  colsAgYieldCalculator,
  colsNoVisYieldCalculatorRaleo,
  colsNoVisYieldCalculatorWinter,
  colsNoVisYieldCalculatorYield,
  pinnedAgYieldCalculator,
  editableColsAgYieldCalculator,
  CustomHeaderComponent,
  excludeSpecieAgYieldCalculator,
} from '../../../helpers/tableDefinitions';
import ModalDetailPrint from '../ModalDetailPrint';
import ModalEdit from '../ModalEdit';
import { FormPrompt } from 'helpers/FormPrompt';

ModuleRegistry.registerModules([
  FiltersToolPanelModule,
  MenuModule,
  MultiFilterModule,
  ColumnsToolPanelModule,
  CsvExportModule,
  ExcelExportModule,
  SetFilterModule,
  RowGroupingModule,
]);

const useStyles = makeStyles((theme) => ({
  tableContainerStyle: {
    // [theme.breakpoints.up('sm')]: {
    //   paddingRight: 27,
    // },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCol: {
    display: 'flex',
  },
  dialogTextStyle: {
    fontWeight: '500',
    color: theme.palette.text.primary,
    marginTop: 15,
  },
}));

const endPointName = 'v1/library/yield-calculator';
const endPointNamePost = 'v1/library/yield-calculator';

const FirstTab2025 = ({
  filter: filterProp,
  fromPrune = false,
  scenarySaved = false,
}) => {
  const theme = useTheme();

  // console.log(theme);
  const errorHandler = useErrorHandler();

  const classes = useStyles();

  const history = useHistory();

  const { userOrchard, userClient } = useContext(Context);

  const {
    state: { specie_filter },
  } = useContext(FiltersContext);

  const {
    state: { library_table_yieldcalculator },
    addOptions,
  } = useContext(OptionsContext);

  // console.log(scenarySaved, '<---scenarySaved');

  const axiosContext = useContext(AxiosContext);

  // modal
  const [openModalState, setOpenModalState] = useState(false);
  const [dataModalState, setDataModalState] = useState(null);

  // modal Print
  const [openModalPrintState, setOpenModalPrintState] = useState(false);
  const [dataModalPrintState, setDataModalPrintState] = useState(null);

  // edition
  const [loadingSubmitState, setLoadingSubmitState] = useState(false);
  const [successSubmitState, setSuccessSubmitState] = useState(false);
  const [confirmPromptState, setConfirmPromptState] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [visibleColumnsState, setVisibleColumnsState] = useState([]);

  // AG GRID

  const [styleState, setStyleState] = useState({
    height: '80vh',
    width: '100%',
    minHeight: 500,
    // marginBottom: 45,
  });

  const [customGroupColsState, setCustomGroupColsState] = useState('0');
  const [fullScreenState, setFullScreenState] = useState(false);
  const paginationPageSizeSelector = [20, 50, 100, 500, 1000];
  const gridApiRef = useRef(null);
  const customGroupColsRef = useRef('0');

  // crear un ref de alert
  const alertRef = useRef(false);

  // table
  const [dataTableState, setDataTableState] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [reloadState, setReloadState] = useState(false);

  // const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState(
    scenarySaved ? [{ id: 'season', desc: false }] : []
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100000,
  });

  // const [isEditedState, setIsEditedState] = useState(false);
  const [copyButtonState, setCopyButtonState] = useState(false);
  const [openDialogState, setOpenDialogState] = useState(false);

  const ActionCellRenderer = (props) => {
    console.log('Abriendo modal para:', props.data);

    return (
      // <p>HOLA</p>
      <div className={clsx('actionColumn', classes.actionCol)}>
        <IconActionTableDetail
          handleActionDetail={() => {
            const html = props.data;
            handleOpenModal(html);
            console.info('estado modal: ', props.data);
          }}
        />
        {props.data?.yieldObjective && props.data?.season === actualYear ? (
          <Tooltip title='Imprimir Escenario'>
            <IconButton
              aria-label='imprimir'
              onClick={() => {
                const html = props.data;
                handleOpenModalPrint(html);
                console.info('estado modal: ', props.data);
              }}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const processDataColumns = (cols) => {
    const colsAgYieldCalculator = cols;

    // recorrer las columnas y agregar la propiedad pinned
    const colsWithPinned = colsAgYieldCalculator.map((col) => {
      const updatedCol = { ...col };

      // Si la columna debe estar fija a la izquierda
      if (pinnedAgYieldCalculator.includes(col.field)) {
        updatedCol.pinned = 'left';
      }

      // Si la columna debe tener color especial en el header
      if (colorColsAgYieldCalculator.includes(col.field)) {
        updatedCol.headerClass = 'bg-column-header';
      }

      // Si la columna es editable
      if (editableColsAgYieldCalculator.includes(col.field)) {
        updatedCol.editable = true;
        updatedCol.cellClassRules = {
          'edited-cell': (params) => {
            const field = `edited_${params.colDef.field}`;
            return Boolean(params.data && params.data[field]);
          },
        };
      }

      // Si la columna tiene tooltip personalizado en el header
      if (col.headerTooltip) {
        updatedCol.headerComponent = 'customHeaderComponent';
        updatedCol.headerComponentParams = {
          tooltip: col.headerTooltip,
        };
      }

      return updatedCol;
    });

    // TODO: seteo columnas en base a specie
    // Filtering columns based on species
    let filteredColumns = colsWithPinned;

    // Exclude columns especie
    if (
      excludeSpecieAgYieldCalculator &&
      excludeSpecieAgYieldCalculator[specie_filter] &&
      excludeSpecieAgYieldCalculator[specie_filter].length > 0
    ) {
      filteredColumns = colsWithPinned.filter(
        (col) =>
          !excludeSpecieAgYieldCalculator[specie_filter].includes(col.field)
      );
    }

    const columnsDef = [
      ...filteredColumns,
      {
        headerName: 'Acciones',
        field: 'actions',
        cellRenderer: ActionCellRenderer,
        // Puedes personalizar el ancho
        width: 120,
        // Para que la columna no sea sorteable ni filtrable
        sortable: false,
        filter: false,
        // Centra el contenido de la celda
        cellStyle: { textAlign: 'center' },
      },
    ];

    console.log('PASA POR COL DEFS');

    // agregar esta columna al final

    return columnsDef;
  };

  const fetchData = async () => {
    setIsError(null);

    setIsLoading(true);

    let objQuery = {
      // orchardValue: huerto,
      // huerto_user: userOrchard, //orchard del login
      orchardValue: filterProp?.huerto || '',
      huerto_user: filterProp?.huerto ? null : userOrchard, //orchard del login
      ccValue: filterProp?.centro_costo || '',
      quarterValue: filterProp?.cuartel || '',
      varietyValue: filterProp?.variedad || '',
      rootStockValue: filterProp?.porta_injerto || '',
      season: filterProp?.temporada || '',
      specieValue: specie_filter,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      sortColumn: setOrderForNewApi(sorting)?.key,
      sortOrder: setOrderForNewApi(sorting)?.option,
    };

    console.log(objQuery, '<-----asi está yendo', sorting);

    try {
      const response = await axiosContext.authAxios.post(
        endPointName,
        objQuery
      );

      const { data, status } = response;

      console.log(data, '----asi llega el first tab');

      setDataTableState(data.data);
      // setRowCount(data.count);
      setVisibleColumnsState(processDataColumns(colsAgYieldCalculator));
    } catch (error) {
      console.error(error);
      setIsError(error);
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setReloadState(false);
    }
  };

  useEffect(() => {
    if (alertRef.current) {
      handleClickOpenDialog();
      return;
    }

    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, filterProp]);

  useEffect(() => {
    if (reloadState) {
      fetchData();
    }
  }, [reloadState]);

  // modal
  const handleCloseModal = () => {
    setOpenModalState(false);
  };

  const handleCloseModalPrint = () => {
    setOpenModalPrintState(false);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEditState(false);
  };

  const handleOpenModal = (row) => {
    setDataModalState(row);
    setOpenModalState(true);
  };

  const handleOpenModalPrint = (row) => {
    setDataModalPrintState(row);
    setOpenModalPrintState(true);
  };

  // const handleOpenModalEdit = (row) => {
  //   setDataModalEditState(row);
  //   setOpenModalEditState(true);
  // };

  // console.log(new Date().getFullYear().toString(), '------actual year');
  const actualYear = new Date().getFullYear().toString();
  // TODO:
  // const actualYear = '2024';

  const handleBack = () => {
    history.push({
      pathname: '/intelligence',
    });
  };

  // const handleIsEdited = useCallback(() => {
  //   setIsEditedState((prev) => !prev);
  // }, []);

  // Funcionamiento tabla

  // función para setear el fullScreen
  const setFullScreen = (fullScreenState) => {
    if (fullScreenState) {
      document.body.style.overflow = 'hidden';
      setStyleState({
        height: 'calc(100vh - 80px)',
        width: '100%',
        minHeight: 500,
        zIndex: 1250,
        top: 0,
        left: 0,
        position: 'fixed',
      });
    } else {
      document.body.style.overflow = '';
      setStyleState({
        height: '80vh',
        width: '100%',
        minHeight: 500,
        position: 'relative',
      });
    }
    setFullScreenState((prevState) => !prevState);
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
  };

  const localeText = AG_GRID_LOCALE_ES;

  const handleChangeCols = (event) => {
    if (gridApiRef.current) {
      let colsToHide = [];

      const allColumns = gridApiRef.current.getColumns();
      allColumns.forEach((column) => {
        column.setVisible(true);
      });

      console.log(allColumns, '<---allColumns');

      if (event.target.value === '0') {
        // console.log('entra en 0, todas visibles');
      }

      if (event.target.value === '1') {
        // INVERNAL
        let colsToHide1 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => colsNoVisYieldCalculatorWinter.includes(col.colId));

        console.log(colsToHide1, '------colsToHide1');

        gridApiRef.current.setColumnsVisible(colsToHide1, false);
      }

      if (event.target.value === '2') {
        // RALEO;
        let colsToHide1 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => colsNoVisYieldCalculatorRaleo.includes(col.colId));

        console.log(colsToHide1, '------colsToHide1');

        gridApiRef.current.setColumnsVisible(colsToHide1, false);
      }

      if (event.target.value === '3') {
        // RENDIMIENTOS;
        let colsToHide1 = [];
        colsToHide1 = gridApiRef.current
          .getColumns()
          .filter((col) => colsNoVisYieldCalculatorYield.includes(col.colId));

        console.log(colsToHide1, '------colsToHide1');

        gridApiRef.current.setColumnsVisible(colsToHide1, false);
      }

      setCustomGroupColsState(event.target.value);

      customGroupColsRef.current = event.target.value;
    }
  };

  // genera el panel custom
  const customPanel = () => {
    return (
      <div className='p-2'>
        <FormControl>
          <RadioGroup value={customGroupColsState} onChange={handleChangeCols}>
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='0'
              label='Todas'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='1'
              label='Poda'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='2'
              label='Conteo Frutos'
            />
            <FormControlLabel
              control={<Radio />}
              name='caracter'
              value='3'
              label='Rendimientos'
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressPivotMode: true, // desactivar el modo pivot
          suppressRowGroups: true, // desactivar la agrupación de filas
          suppressValues: true, // desactivar los valores
          suppressColumnFilter: true, // desactivar el filtro de busqueda en las columnas
          contractColumnSelection: true, // contraer la selección de columnas al incio
          suppressColumnSelectAll: true, // desactivar el check de selección de todas las columnas
        },
        minWidth: 225,
        maxWidth: 225,
        width: 225,
      },
      {
        id: 'custom',
        labelDefault: 'Grupo Columnas',
        labelKey: 'customStats',
        iconKey: 'columns',
        toolPanel: customPanel,
        toolPanelParams: {},
      },
    ],
    position: 'right',
    defaultToolPanel: 'customPanel',
  };

  const AgComponents = {
    customHeaderComponent: CustomHeaderComponent,
  };

  const clearFocusSelection = () => {
    if (gridApiRef.current && gridApiRef.current) {
      gridApiRef.current.clearFocusedCell();
    }
    setConfirmPromptState(true);
  };

  // funcion para limpiar las celdas editadas
  const clearEditedCells = () => {
    const transaction = {
      update: dataTableState.map((row) => {
        const updatedRow = { ...row };
        Object.keys(updatedRow).forEach((key) => {
          if (key.startsWith('edited_')) {
            delete updatedRow[key];
          }
        });
        return updatedRow;
      }),
    };

    gridApiRef.current.applyTransaction(transaction);
  };

  // funcion para edición de celdas
  const onCellValueChanged = (params) => {
    const { data, colDef, newValue, oldValue } = params;
    const { field } = colDef;

    console.log(params, '-----------------------');

    if (newValue === null || newValue === '') {
      data[`edited_${colDef.field}`] = false; // Marca la celda como editada
      params.node.setDataValue(colDef.field, oldValue); // Restaura el valor anterior
    } else {
      if (newValue !== oldValue && newValue !== '') {
        data[`edited_${colDef.field}`] = true; // Marca la celda como editada
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [colDef.field],
          force: true,
        });
      }

      alertRef.current = true;
      setCopyButtonState(true);

      // Actualizar el estado de rowData
      const updatedRowData = [...dataTableState];

      const rowIndex = updatedRowData.findIndex((row) => row.id === data.id);

      // TODO: aquí puedo aplicar ajustes de datos
      // TODO: a cada vaor que toca debería agregarle la propiedad edited

      // TODO: SACAR A UNA FUNCIÓN
      const dartBasal =
        data?.dartPrePoda > 0
          ? data?.dartPrePoda < data?.dartPostPoda
            ? data?.dartPostPoda
            : data?.dartPrePoda
          : data?.dartPostPoda;

      const twigBasal =
        data?.twigPrePoda > 0
          ? data?.twigPrePoda < data?.twigPostPoda
            ? data?.twigPostPoda
            : data?.twigPrePoda
          : data?.twigPostPoda;

      // valores actuales:
      const dartCalc = data.dartObjective || dartBasal;

      const twigCalc = data.twigObjective || twigBasal;

      const flowerCalc = data.budDartObjective || data.budDart;

      const flowersTwigCalc = data.flowersTwig ? data.flowersTwig : 0;

      const fruitSetCalc = data.fruitSetObjective || data.fruitsSetReal;

      const caliberCalc =
        data.caliberObjective || data.caliberReal || data.caliberEstimate;

      const flowersHa = flowersHaForm(
        parseFloat(dartCalc),
        parseFloat(flowerCalc),
        parseFloat(data.flowersBudDart),
        parseFloat(twigCalc),
        parseFloat(flowersTwigCalc),
        parseFloat(data.plantsHa),
        // no mas pollinator
        // parseFloat(
        //   infoIntelligence.information.pollinator
        //     ? infoIntelligence.information.pollinator
        //     : 0
        // )
        0
      );

      console.log(flowersHa, '------A');

      const newYield = yieldForm(
        flowersHa,
        parseFloat(fruitSetCalc),
        parseFloat(caliberCalc)
      );

      console.log(newYield, '------B');

      // calculo del flowersDart
      const flowersDartFinal = data.budDartObjective
        ? flowersDartForm(
            data.budDartObjective,
            parseFloat(data.flowersBudDart)
          )
        : data.flowersDart;

      console.log(flowersDartFinal, '------C');

      // bodyExpected = {
      //   dart: dartCalc,
      //   twig: twigCalc,
      //   flower: flowerCalc,
      //   fruitSet: fruitSetCalc,
      //   caliber: caliberCalc,
      //   yield: newYield,
      //   flowersHa: flowersHa,
      //   flowersDart: flowersDartFinal,
      // };

      // nueva fórmula - 5-4
      const fruitsDartEst = (flowersDartFinal * fruitSetCalc) / 100;

      console.log(
        'VALORES CALCULADOS: ------------->',
        dartCalc,
        twigCalc,
        flowerCalc,
        fruitSetCalc,
        caliberCalc,
        newYield,
        flowersHa,
        flowersDartFinal,
        fruitsDartEst
      );

      //------------------

      updatedRowData[rowIndex] = data;

      setDataTableState(updatedRowData);
    }
  };

  const onAcceptPrompt = async () => {
    setLoadingSubmitState(true);

    const newArray = dataTableState;

    if (newArray.length === 0) {
      setLoadingSubmitState(false);
      handleClosePrompt();
      return;
    }

    const dataObj = {
      userId: userId,
      client: userClient?.label || '',
      clientValue: userClient?.value || '',
      days: newArray,
    };

    try {
      const response = await axiosContext.authAxios.post(
        endPointNamePost,
        dataObj
      );

      const { status } = response;

      if (status === 200) {
        setSuccessSubmitState(true);
        alertRef.current = false;
        setCopyButtonState(false);

        clearEditedCells();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingSubmitState(false);
      handleClosePrompt();
    }
  };

  const onSaveData = async () => {
    clearFocusSelection();
  };

  const handleClosePrompt = () => {
    setConfirmPromptState(false);
  };

  const onCloseSnack = () => {
    handleClosePrompt();
  };

  const closeHandler = () => {
    setSuccessSubmitState(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialogState(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogState(false);
  };

  const handleCloseAndConfirmDialog = () => {
    setCopyButtonState(false);
    alertRef.current = false;
    // handleSubmit(onSubmit)();

    setOpenDialogState(false);
  };

  function saveGridState() {
    const columnState = gridApiRef.current.getColumnState();
    const filterModel = gridApiRef.current.getFilterModel();

    const gridState = {
      columnState,
      filterModel,
    };

    addOptions({
      library_table_yieldcalculator: {
        grid: gridState,
      },
    });
    // localStorage.setItem('ag-grid-state', JSON.stringify(gridState));
  }

  function loadGridStorage() {
    console.log(library_table_yieldcalculator, '<<<<<------schedule_form');

    if (library_table_yieldcalculator) {
      console.log(library_table_yieldcalculator, '<<<<<------schedule_form');
      if (library_table_yieldcalculator.grid.columnState) {
        gridApiRef.current.applyColumnState({
          state: library_table_yieldcalculator.grid.columnState,
        });
      }

      if (library_table_yieldcalculator.grid.filterModel) {
        gridApiRef.current.setFilterModel(
          library_table_yieldcalculator.grid.filterModel
        );
      }
    }
  }

  const handleReload = () => {
    setReloadState(true);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /*Funcionamiento del boton de exportar Csv*/
  const onBtnExportCsv = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const fileName = `Biblioteca_Calculadora_${formattedDate}_${formattedTime}.csv`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  const onBtnExportExcel = () => {
    if (gridApiRef.current) {
      const date = new Date();
      const formattedDate = `${String(date.getDate()).padStart(
        2,
        '0'
      )}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`; // DD-MM-YYYY
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS

      const fileName = `Biblioteca_Calculadora_${formattedDate}_${formattedTime}.xlsx`;

      gridApiRef.current.exportDataAsExcel({
        fileName: fileName,
        processCellCallback: (params) => {
          const value = params.value;
          return typeof value === 'string' ? value.toUpperCase() : value;
        },
      });
    }
    handleMenuClose();
  };

  //  TODO: MODELO PARA APLICAR FILTROS A LA TABLA
  // const applyOrchardFilter = async () => {
  //   // Configurar el modelo del filtro para la columna "orchard"
  //   await gridApiRef.current.setColumnFilterModel('orchard', {
  //     filterType: 'multi',
  //     filterModels: [
  //       {
  //         filterType: 'text',
  //         type: 'contains',
  //         filter: 'Santa', // Texto a filtrar
  //       },
  //       null, // No configuramos el filtro de conjunto (Set Filter)
  //     ],
  //   });

  //   await gridApiRef.current.setColumnFilterModel('variety', {
  //     filterType: 'multi',
  //     filterModels: [
  //       {
  //         filterType: 'text',
  //         type: 'contains',
  //         filter: 'Royal', // Texto a filtrar
  //       },
  //       null, // No configuramos el filtro de conjunto (Set Filter)
  //     ],
  //   });

  //   // Actualizar los datos en la tabla
  //   gridApiRef.current.onFilterChanged();
  // };

  return (
    <BsRow class='justify-content-between'>
      <BsCol class='col-12'>
        {fromPrune ? (
          <Button onClick={handleBack} className={classes.backButton}>
            <i className='fas fa-chevron-left mr-2'></i> Volver Recomendación
            Poda
          </Button>
        ) : null}
        <FormPrompt hasUnsavedChanges={copyButtonState} />
        <Dialog
          open={openDialogState}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Tiene datos editados sin guardar.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={handleCloseAndConfirmDialog}>
              Aceptar y Filtrar
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.tableContainerStyle}>
          <div
            className='p-2 d-flex flex-column flex-md-row justify-content-md-end justify-content-center'
            style={{ backgroundColor: 'white' }}
          >
            {/* <Box>
              <ButtonAction
                labelButton='GUARDAR'
                color='secondary'
                size='large'
                actionOnClick={onSaveData}
                className='roundedButton'
                variant='contained'
                messageType='add'
                actionClose={closeHandler}
                fullWidth
                disabled={
                  isLoading || !dataTableState || !dataTableState.length
                }
                mutationLoading={loadingSubmitState}
                success={successSubmitState}
              />
            </Box> */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button variant='text' onClick={saveGridState}>
                Guardar Filtrado
              </Button>

              <Button
                variant='text'
                onClick={loadGridStorage}
                disabled={!library_table_yieldcalculator}
              >
                Aplicar Filtrado
              </Button>

              <Tooltip title='Actualizar' placement='bottom'>
                <IconButton
                  color='primary'
                  aria-label='export'
                  onClick={handleReload}
                  disabled={isLoading}
                >
                  <SyncIcon />
                </IconButton>
              </Tooltip>
              <IconButton
                color='primary'
                aria-label='export'
                onClick={handleMenuOpen}
                disabled={isLoading}
              >
                <DownloadIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={onBtnExportExcel}>Exportar a Excel</MenuItem>
                <MenuItem onClick={onBtnExportCsv}>Exportar a CSV</MenuItem>
              </Menu>
              <Tooltip title='Pantalla Completa' placement='bottom'>
                <IconButton
                  color='primary'
                  aria-label='fullscreen-toggle'
                  onClick={() => setFullScreen(!fullScreenState)}
                  disabled={isLoading}
                >
                  {fullScreenState ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </div>
          {confirmPromptState ? (
            <Dialog
              open={confirmPromptState}
              onClose={handleClosePrompt}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle
                id='alert-dialog-title'
                sx={{
                  lineHeight: 0.5,
                }}
              >
                Edición Biblioteca
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id='alert-dialog-description'
                  className={classes.dialogTextStyle}
                >
                  ¿Confirma que está modificando datos?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button
                  onClick={handleClosePrompt}
                  disabled={loadingSubmitState}
                  sx={{
                    color: theme.palette.grey[500],
                  }}
                >
                  Cancelar
                </Button>
                <ButtonAction
                  labelButton='GUARDAR'
                  color='secondary'
                  // size='large'
                  actionClose={onCloseSnack}
                  variant='contained'
                  fullWidth={true}
                  actionOnClick={onAcceptPrompt}
                  disabled={false}
                  messageType='edit'
                  mutationLoading={loadingSubmitState}
                  success={false}
                />
              </DialogActions>
            </Dialog>
          ) : null}
          {!isLoading ? (
            <div className='ag-theme-quartz' style={styleState}>
              {fullScreenState && (
                <div className='p-1' style={{ backgroundColor: 'white' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      color='primary'
                      aria-label='fullscreen-toggle'
                      onClick={() => setFullScreen(!fullScreenState)}
                      disabled={isLoading}
                    >
                      <FullscreenExitIcon />
                    </IconButton>
                  </Box>
                </div>
              )}
              <AgGridReact
                rowData={dataTableState}
                columnDefs={visibleColumnsState}
                onCellValueChanged={onCellValueChanged}
                pagination
                paginationPageSize={50}
                suppressMovableColumns={true}
                suppressDragLeaveHidesColumns={true}
                paginationPageSizeSelector={paginationPageSizeSelector}
                groupDisplayType={'singleColumn'}
                rowGroupPanelShow={'always'}
                localeText={localeText}
                tooltipShowDelay={400}
                components={AgComponents}
                defaultColDef={{
                  flex: 1,
                  minWidth: 150,
                  filter: false,
                  floatingFilter: true, // habilita el filtro dentro de la celda de la columna
                  wrapHeaderText: true, // Permite que el texto se ajuste
                  autoHeaderHeight: true, // Ajusta la altura de la cabecera automáticamente
                  resizable: true, // Opcional: permite redimensionar columnas
                }}
                // quickFilterText={quickFilterText}
                onGridReady={onGridReady}
                sideBar={sideBar}
                embedFullWidthRows={true}
                // getRowId={(params) => params.data.id}
                grandTotalRow={'bottom'}
              />
            </div>
          ) : (
            <LoadingAgTable />
          )}
        </div>
        <Modal
          open={openModalState}
          onClose={handleCloseModal}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalDetail
              closeAction={handleCloseModal}
              dataModal={dataModalState}
              dataColumn={colsAgYieldCalculator}
            />
          </>
        </Modal>
        <Modal
          open={openModalPrintState}
          onClose={handleCloseModalPrint}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalDetailPrint
              closeAction={handleCloseModalPrint}
              dataModal={dataModalPrintState}
            />
          </>
        </Modal>
        {/* <Modal
          open={openModalEditState}
          onClose={handleCloseModalEdit}
          className={classes.modal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <>
            <ModalEdit
              closeAction={handleCloseModalEdit}
              dataModal={dataModalEditState}
              isEditedFunc={handleIsEdited}
            />
          </>
        </Modal> */}
      </BsCol>
    </BsRow>
  );
};

export default FirstTab2025;
