// External libraries
import { useContext, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useErrorHandler } from 'react-error-boundary';
import { addDays, subDays } from 'date-fns';
import { Box, useTheme } from '@mui/material';

// Internal modules
import { AxiosContext } from '../../../../context/AxiosContext';
import { yearGraphColors } from '../../../../theme/palette';

// Components
import { HighchartsWrapper, LoadingWidget } from '../../../../components';

// Functions, constants, etc.
import {
  dateFormatFromCalendar,
  generalOptionsHighCharts,
  numberFormatDecimals,
} from '../../../../helpers';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts);

const ActualDegreeDays = ({ orchardValue }) => {
  const chartRef = useRef(null);

  const axiosContext = useContext(AxiosContext);

  const theme = useTheme();

  const errorHandler = useErrorHandler();

  const sinceDate = subDays(new Date(), 4);
  const untilDate = new Date();
  // const untilDate = addDays(new Date(), 4);

  const [loadingState, setLoadingState] = useState(false);
  const [graphOptionsState, setGraphOptionsState] = useState(null);

  const highchartsOptions = {
    ...generalOptionsHighCharts,
    title: {
      text: '',
    },
    chart: {
      type: 'column',
      height: 210,
      spacingBottom: 4,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 10,
    },
    tooltip: {
      formatter: function () {
        return (
          '<b>' +
          numberFormatDecimals(this.y, 2) +
          ' Gdo. día</b><br/>' +
          this.x
        );
      },
    },
    xAxis: {
      categories: graphOptionsState?.date,
      crosshair: true,
    },
    yAxis: [
      {
        labels: {
          format: '{value}',
          style: {
            color: 'rgb(208,194,161)',
          },
        },
        title: {
          text: 'Día Grado Acumulado',
          style: {
            color: theme.palette.weather.charts.splineColors[1],
          },
        },
      },
      {
        gridLineWidth: 0,
        title: {
          text: 'Día Grado Diario',
          style: {
            color: theme.palette.weather.charts.barColors.degree.secondary,
          },
        },
        opposite: true,
        labels: {
          format: '{value}',
          style: {
            color: 'rgb(61,72,77)',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    // colors: theme.palette.weather.charts.barColors,
    series: [
      {
        name: 'Día Grado Diario',
        type: 'column',
        yAxis: 1,
        data: graphOptionsState?.degreeDays?.data?.map(
          (value, index, array) => ({
            y: value,
            color:
              index >= array.length - 3
                ? theme.palette.weather.charts.barColors.degree.primary // Orange color for last 3 days
                : theme.palette.weather.charts.barColors.degree.secondary, // Blue color for last 3 days
          })
        ),
        tooltip: {
          valueSuffix: '',
          valueDecimals: 2,
        },
      },
      {
        name: 'Día Grado Acumulado',
        type: 'spline',
        data: graphOptionsState?.degreeDaysAccumulated?.data,
        color: theme.palette.weather.charts.splineColors[1],
        zoneAxis: 'x',
        zones: [
          {
            value: graphOptionsState?.degreeDaysAccumulated?.data?.length - 3,
          },
          {
            dashStyle: 'Dot',
            color: theme.palette.secondary.main,
          },
        ],
        tooltip: {
          valueSuffix: '',
          valueDecimals: 2,
        },
      },
    ],
    exporting: {
      ...generalOptionsHighCharts.exporting,
      filename: 'Día Grado',
    },
  };

  const openFullscreen = () => {
    console.log(chartRef.current, 'chartRef.current');
    if (chartRef.current) {
      chartRef.current.chart.fullscreen.open();
    } else {
      console.warn(
        'El modo de pantalla completa no está soportado por este gráfico.'
      );
    }
  };

  async function queryData(orchardValue) {
    setLoadingState(true);
    try {
      const objQuery = {
        orchard: orchardValue,
        sinceDate: dateFormatFromCalendar(sinceDate),
        untilDate: dateFormatFromCalendar(untilDate),
        future: true,
      };

      // console.log(objQuery, '-----objQuery');

      const response = await axiosContext.authAxios.post(
        'v1/weather/weather-degreeDays',
        objQuery
      );

      // let data = true;

      const { data, status } = response;

      // setCountState(data.totalPassengers);
      if (status === 200) {
        setGraphOptionsState(data);
      }
    } catch (err) {
      setGraphOptionsState([]);
      errorHandler(err);
    } finally {
      setLoadingState(false);
    }
  }

  // un useEffect que al entrar ejecute el submit del form
  useEffect(() => {
    if (orchardValue) {
      queryData(orchardValue);
    }
  }, [orchardValue]);

  return (
    <HighchartsWrapper fullScreenHandler={openFullscreen} title=''>
      {loadingState && <LoadingWidget />}
      {!loadingState && graphOptionsState && (
        <HighchartsReact
          highcharts={Highcharts}
          options={highchartsOptions}
          ref={chartRef}
        />
      )}
    </HighchartsWrapper>
  );
};

export default ActualDegreeDays;
