// Importaciones de librerías
import _, { add, min } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { createSvgIcon } from '@mui/material/utils';
import { useHistory } from 'react-router-dom';

// Importaciones absolutas
// (No hay ninguna en tu ejemplo, pero las colocarías aquí)

// Importaciones relativas
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import { Context } from '../../context/Context';
import { FiltersContext, OptionsContext } from '../../context';
import { ButtonAction, CardPaper } from '../../components';
import iconJson from '../../common/iconSVG.json';
import {
  mapIcon,
  libraryIcon,
  estimationIcon,
  countIcon,
  weatherIcon,
  usabilityIcon,
  harvestIcon,
  samplingIcon,
  prunningIcon,
  analysisIcon,
  yieldIcon,
  industryIcon,
  irrigationIcon,
  nutritionIcon,
} from '../../assets/icons/menu/color_icons';
import { MODULE_CAROZO, MODULE_CAROZO_CAROZO } from 'helpers/Mockups';

const useStyles = makeStyles((theme) => ({
  theadLightSmall: {
    '& thead tr:nth-child(2) th': {
      backgroundColor: 'white',
    },
    '& th': {
      // color: '#495057',
      fontSize: 14,
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[300],
      padding: theme.spacing(1),
    },
    '& td': {
      textAlign: 'center',
    },
  },
}));

const DesktopDashboard = () => {
  const theme = useTheme();

  const classes = useStyles();

  let history = useHistory();

  const { moduleUser, userOrchard, userClient, userFullname } =
    useContext(Context);

  const chartRef = useRef(null);

  const {
    state: {
      userOrchard_filter,
      orchard_filter,
      orchard_id_filter,
      specie_filter,
    },
    addFilter,
  } = useContext(FiltersContext);

  const {
    state: { counting_dashboard },
    addOptions,
  } = useContext(OptionsContext);

  const [loadingDashDataState, setLoadingDashDataState] = useState(false);

  const handleButtonClick = (module) => {
    if (module.href) {
      history.push({
        pathname: module.href,
      });
    }
  };

  let modulesActive = moduleUser;

  if (specie_filter !== 'Cerezo' && specie_filter !== 'Manzano') {
    if (specie_filter === 'Carozo') {
      modulesActive = moduleUser.filter((module) =>
        MODULE_CAROZO_CAROZO.includes(module.id)
      );
    } else {
      modulesActive = moduleUser.filter((module) =>
        MODULE_CAROZO.includes(module.id)
      );
    }
  }

  return (
    <div style={{ marginTop: theme.spacing(1) }}>
      <BsContainer fluid class=''>
        <BsRow class=''>
          <BsCol class='col-md-12'>
            <Typography variant='h4' sx={{ fontWeight: 600 }}>
              Bienvenido, {userFullname}
            </Typography>
            <BsRow class='gutter_10'>
              <BsCol class='col-md-12 mt-3'>
                <Grid
                  container
                  columnSpacing={{ xs: 1.4 }}
                  columns={{ xs: 12, lg: 12 }}
                >
                  {modulesActive
                    .filter(
                      (module) => module.id !== '000000000000000000000000'
                    )
                    .sort((a, b) => a.menuOrder - b.menuOrder)
                    .map((module) => {
                      let iconName;

                      console.log(module.name, '-------------------');

                      switch (module.name) {
                        case 'Counter':
                          iconName = countIcon;
                          break;
                        case 'Orchards':
                          iconName = mapIcon;
                          break;
                        case 'DashboardManagement':
                          iconName = estimationIcon;
                          break;
                        case 'Library':
                          iconName = libraryIcon;
                          break;
                        case 'Weather':
                          iconName = weatherIcon;
                          break;
                        case 'Usability':
                          iconName = usabilityIcon;
                          break;
                        case 'HarvestPlanning':
                          iconName = harvestIcon;
                          break;
                        case 'Sampling':
                          iconName = samplingIcon;
                          break;
                        case 'Pruning':
                          iconName = prunningIcon;
                          break;
                        case 'LaboratoryAnalysis':
                          iconName = analysisIcon;
                          break;
                        case 'Industry':
                          iconName = industryIcon;
                          break;
                        case 'DashboardPerformance':
                          iconName = yieldIcon;
                          break;
                        case 'Irrigation':
                          iconName = irrigationIcon;
                          break;
                        case 'Nutrition':
                          iconName = nutritionIcon;
                          break;
                        default:
                          iconName = libraryIcon;
                          break;
                      }

                      const MapaIcon = createSvgIcon(iconName, 'Mapa');

                      return (
                        <Grid
                          key={module.id}
                          className='mb-3'
                          item
                          xs={6}
                          md={4}
                          lg={3}
                        >
                          <CardPaper
                            elevation={0}
                            topColor=''
                            variant={module.assigned ? 'outlined' : 'filled'}
                            borderTop={0}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              height: '100%',
                              minHeight: 150,
                              backgroundColor: module.assigned
                                ? 'white'
                                : '#E9EBED',
                              borderColor: module.assigned
                                ? theme.palette.grey[300]
                                : '#A6AEB4',
                              cursor: module.assigned && 'pointer',
                            }}
                            onClick={() =>
                              module.assigned && handleButtonClick(module)
                            }
                          >
                            <MapaIcon
                              sx={{
                                width: 48,
                                height: 48,
                                filter: !module.assigned
                                  ? 'grayscale(100%) contrast(0.8) brightness(1.2) opacity(0.7)'
                                  : '',
                              }}
                            />
                            <Typography
                              variant='body2'
                              sx={{
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                color: module.assigned
                                  ? theme.palette.secondary.main
                                  : '#4D5D69',
                                mt: 1.4,
                              }}
                            >
                              {module.namePrint}
                            </Typography>
                          </CardPaper>
                        </Grid>
                      );
                    })}
                </Grid>
              </BsCol>
            </BsRow>
          </BsCol>
          {/* <BsCol class='col-md-4 col-lg-3'>
            <Typography variant='h4' sx={{ fontWeight: 600 }}>
              Notificaciones
            </Typography>
          </BsCol> */}
        </BsRow>
      </BsContainer>
    </div>
  );
};

export default DesktopDashboard;
