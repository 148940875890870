import { Box, Skeleton } from '@mui/material';
import React from 'react';

const LoadingAgTable = (props) => {
  return (
    <Box className='mt-3'>
      {[...Array(10)].map((_, index) => (
        <Skeleton
          key={index}
          animation='wave'
          variant='rounded'
          height={40}
          className='mb-1'
        />
      ))}
    </Box>
  );
};

export default LoadingAgTable;
