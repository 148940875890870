import React, { memo, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsOfflineExporting(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: '.',
    decimalPoint: ',',
  },
});

const generalOptionsHighCharts = {
  credits: {
    enabled: false,
  },
  accessibility: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 1380,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const MapChart = ({
  data = [], // Datos del mapa
  height = '', // Altura del gráfico
  spacing = [0, 0, 0, 0], // Espaciado entre los bordes del gráfico y el contenido
  chartRef = null, // Referencia al gráfico
  isRegionView = true, // Vista actual (regiones o provincias)
  inactiveColor = '#f7f7f7', // Color de las regiones/provincias inactivas
  colors = [], // Color por defecto
  handleClick,
  regionName = '',
  exportOptions = false,
  title = '',
  showLegend = false,
  tooltipFormat = '{series.name}: {point.y}', // Formato del tooltip
}) => {
  const options = {
    ...generalOptionsHighCharts,
    exporting: {
      enabled: exportOptions,
      filename: title ? title : 'downloaded-chart',
      buttons: {
        contextButton: {
          align: 'right',
          verticalAlign: 'top',
          x: 0,
          y: 0,
          symbolFill: '#A8BF77',
          symbolStroke: '#666',
          symbolStrokeWidth: 1,
          // theme: {
          //   fill: '#f7f7f7',
          //   stroke: '#666',
          //   states: {
          //     hover: {
          //       fill: '#e6e6e6',
          //       stroke: '#666',
          //     },
          //     select: {
          //       fill: '#e6e6e6',
          //       stroke: '#666',
          //     },
          //   },
          // },
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadJPEG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            ...(exportOptions ? ['downloadCSV'] : []),
          ],
        },
        // filename: 'DATOS',
      },
      menuItemDefinitions: {
        viewFullscreen: {
          text: 'Ver en pantalla completa',
        },
        printChart: {
          text: 'Imprimir',
        },
        downloadPDF: {
          text: 'Descargar PDF',
        },
        downloadCSV: {
          text: 'Descargar CSV',
        },
        downloadJPEG: {
          text: 'Descargar JPEG',
        },
        downloadSVG: {
          text: 'Descargar SVG',
        },
      },
      csv: {
        dateFormat: '%Y-%m-%d',
        itemDelimiter: ';',
        lineDelimiter: '\n',
        decimalPoint: ',',
      },
    },
    chart: {
      map: data,
      height: height,
      spacingBottom: spacing[2],
      spacingLeft: spacing[3],
      spacingRight: spacing[1],
      spacingTop: spacing[0],
    },
    title: {
      text: isRegionView ? '' : `Provincias de: ${regionName}`,
    },
    tooltip: {
      // Personalización del tooltip para mostrar name, value y y
      pointFormat: tooltipFormat,
    },
    legend: {
      enabled: showLegend,
    },
    series: [
      {
        data: data.objects[
          isRegionView ? 'simp_reg' : 'simp_prov'
        ].geometries.map((feature) => {
          // Encuentra el color y valor para la región actual
          const regionData = isRegionView
            ? colors.find((region) => region.key === feature.properties.CUT_REG)
            : colors.find(
                (province) => province.key === feature.properties.CUT_PROV
              );

          return {
            key: isRegionView
              ? feature.properties.CUT_REG
              : feature.properties.CUT_PROV, // ID único de la región
            name: isRegionView
              ? feature.properties.REGION
              : feature.properties.PROVINCIA, // Nombre de la región/provincia
            color: regionData ? regionData.color : inactiveColor, // Color asociado
            value: regionData ? regionData.value : 0, // Valor asociado
            value2: regionData ? regionData.value2 : 0, // Valor asociado
            value3: regionData ? regionData.value3 : 0, // Valor asociado
            value4: regionData ? regionData.value4 : 0, // Valor asociado
            value5: regionData ? regionData.value5 : 0, // Valor asociado
          };
        }),
        mapData: data,
        joinBy: isRegionView ? ['CUT_REG', 'key'] : ['CUT_PROV', 'key'], // Relación entre el GeoJSON y la data
        name: isRegionView ? 'Regiones de Chile' : 'Provincias',
        borderColor: '#000000', // Color de los bordes
        borderWidth: 1, // Grosor de los bordes
        states: {
          hover: {
            color: '#a4edba', // Color al pasar el mouse
          },
        },
        point: {
          events: {
            click: function () {
              if (isRegionView) {
                console.log(
                  `Región seleccionada: ${this.name} (ID: ${this.key})`
                );
                handleClick(this.key, this.name);
              }
            },
          },
        },
      },
    ],
  };

  // console.log(JSON.stringify(options, 0, 2), 'options---------------');
  console.log(data, regionName, isRegionView, '----------------mapa');

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'mapChart'}
        options={options}
        ref={chartRef}
        key={isRegionView ? 'region' : 'provincia'}
      />
    </div>
  );
};

// Custom comparison function to determine when to re-render
const arePropsEqual = (prevProps, nextProps) => {
  // Compare essential props that affect rendering
  return (
    prevProps.isRegionView === nextProps.isRegionView &&
    prevProps.regionName === nextProps.regionName &&
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    JSON.stringify(prevProps.colors) === JSON.stringify(nextProps.colors) &&
    prevProps.height === nextProps.height &&
    prevProps.inactiveColor === nextProps.inactiveColor &&
    prevProps.title === nextProps.title &&
    prevProps.showLegend === nextProps.showLegend &&
    prevProps.tooltipFormat === nextProps.tooltipFormat &&
    JSON.stringify(prevProps.spacing) === JSON.stringify(nextProps.spacing) &&
    prevProps.exportOptions === nextProps.exportOptions
    // Note: We don't compare handleClick function as it would likely cause re-renders
    // If handleClick changes frequently, consider useCallback in the parent component
  );
};

// Export the memoized component
export default memo(MapChart, arePropsEqual);
